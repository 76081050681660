import React, { useEffect, useState } from 'react'
import { CommonNotify } from '../common/CommonNotify'
import facebook from '../assets/images/facebook.png'
import twitter from '../assets/images/twitter.png'
import linkedin from '../assets/images/linkedin.png'
import email from '../assets/images/email.svg'
import { IconButton } from '@mui/material'
import axios from 'axios'
import { useSelector } from 'react-redux'

const ReferModal = (props) => {
  const [copeMessage, setCopyMesage] = useState('')
  const [text, setText] = useState(
    ''
  )
  const subscription = useSelector((state) => state.home.subscription?.data)

  const getReferral = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/referrals?date_from=2022/12/28&date_to=2023/06/15`
    const res = await axios.get(url)

  }

  useEffect(() => {
    if (subscription && Object.keys(subscription)?.length > 0 && text !== subscription?.signup_referral_url) {
      setText(subscription?.signup_referral_url)
    }

  }, [subscription])

  const onCopyText = async jsCode => {
    try {
      await navigator.clipboard.writeText(jsCode)
      CommonNotify('Copied!', 'success')
    } catch (err) {
      CommonNotify('Failed to copy!')
    }
  }
  // useEffect(()=>{
  //   getReferral()
  // },[])

  useEffect(() => {
    if (copeMessage)
      CommonNotify(copeMessage, 'success')
  }, [copeMessage])

  return (
    <div className="call-forwarding-wrapper call_rorwarding_main">
      <h3 className="title-label mt-3">
        Referrals
      </h3>

      <div className="business_hours_main">
        <div className="working-hours-wrapper availability-block referral-policy">

          <h6>
            Gift $20, earn $20
          </h6>
          <p>
            Invite your friends, family members and business connections to join limecall, and we'll give you both $20 in cerdit.
          </p>
          <form onClick={(e)=>e.preventDefault()}>
            <div className="emailWrap">
              <label>Your unique referral link</label>
            </div>
            <div className="ui input shareInput">
              <input
                type="text"
                placeholder="dfgdfgdfgdfgdf..."
                disabled="true"
                tabIndex="-1"
                id="text"
                value={text}
              />
              <button className="ui button" onClick={() => onCopyText(text)} style={{ marginLeft: '5px' }}>Copy Link</button>
              {/* <span className="copyIcon">

                {copeMessage}
              </span> */}
            </div>
            {/* <div>Referrals Stats</div> */}
            {/* <div className="emailWrap">
              <label>Share your social media</label>
            </div>
            <div >
              <IconButton ><img style={{width:'30px', height:'30px'}} src={facebook} alt="facebook"/></IconButton>
              <IconButton ><img style={{width:'30px', height:'30px'}} src={twitter} alt="twitter"/></IconButton>
              <IconButton  style={{width:'45px', height:'45px'}}><img style={{width:'50px', height:'50px'}} src={linkedin} alt="linkedin"/></IconButton>
              <IconButton ><img style={{width:'30px', height:'30px',transform: 'rotateZ(20deg)'}} src={email} alt="email"/></IconButton>
            </div> */}
            {/* <div className="emailWrap">
              <label>Invite By Email</label>
            </div>
            <div className="ui input shareInput">
              <input
                type="email"
                placeholder="Add email to invite,comma separate"
              />
               <div className="btnInvite">
              <button className="ui button" style={{marginLeft:'5px'}}>Send Invites</button>
            </div>
            </div> */}

          </form>

        </div></div>

    </div>
  )
}



export default ReferModal
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import CommonInput from '../../common/CommonInput'
import { CommonNotify } from '../../common/CommonNotify'

export default function WebHooksURL() {
  const [webhookURL, setWebhookURL] = useState('')
  const [outgoing_webhook, setOutgoing_webhook] = useState({})

  useEffect(() => {
    getWidget()
  }, [])

  const getWidget = async () => {
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
    await Axios.get(url)
      .then(async res => {
        setOutgoing_webhook(res?.data?.data?.[0]?.outgoing_webhook || {})
        setWebhookURL(res?.data?.data?.[0]?.outgoing_webhook?.url || '')
        localStorage.setItem('widget_active', res.data.data[0].active)
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Something went to wronng!'
        )
      })
  }
  const handleAddURl = async () => {
    if (webhookURL) {
      let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/outgoing-webhook`
      if (outgoing_webhook?.id) {
        url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/outgoing-webhook/${outgoing_webhook?.id}`
        const data = { url: webhookURL }
        await Axios.put(url, data)
          .then(async res => {
            CommonNotify('URL Updated successfully.')
          })
          .catch(err => {
            CommonNotify(
              err?.response?.data?.errors?.[0] || 'Something went to wronng!'
            )
          })
      } else {
        const data = { url: webhookURL }
        await Axios.post(url, data)
          .then(async res => {
            CommonNotify('URL added successfully.')
          })
          .catch(err => {
            CommonNotify(
              err?.response?.data?.errors?.[0] || 'Something went to wronng!'
            )
          })
      }
    } else {
      CommonNotify('Please enter URL first')
    }
  }

  return (
    <div className="web-hooks-wrapper">
      <section className="card-manage-number">
        <div
          className="campaign-sec"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '36px'
          }}
        >
          <div className="assing_opt" style={{ flex: 3 }}>
            <h4 className="bold-text accordion-title">Webhook URL</h4>
            <p className="card-desc subtext accordion-desc">Add webhook URL.</p>
          </div>

          <div
            className="assing_opt webhook-url mt-4"
            style={{ width: '100%' }}
          >
            <CommonInput
              style={{ width: '100%' }}
              value={webhookURL}
              placeholder="https://"
              name="url"
              onChange={e => {
                setWebhookURL(e.target.value)
              }}
              type="text"
            />
            <div className="ml-3">
              <button
                onClick={handleAddURl}
                className="ui button btn-grey rounded"
                style={{
                  backgroundColor: '#0071eb',
                  color: '#ffffff'
                }}
              >
                {' '}
                <p style={{ color: '#fff', fontSize: 12 }}>
                  {outgoing_webhook?.id ? 'Update' : 'Add'}
                </p>{' '}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

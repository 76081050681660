import BookmarkIcon from '@mui/icons-material/Bookmark'
import CallIcon from '@mui/icons-material/Call'
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed'
import ScheduleIcon from '@mui/icons-material/Schedule'
import axios from 'axios'
import moment from 'moment'
import Pusher from 'pusher-js'
import React, { useEffect, useState } from 'react'
import {
  Dimmer,
  Loader,
  Popup,
  Segment,
  Tab,
  TextArea
} from 'semantic-ui-react'
import { ReactComponent as Closed } from '../../../assets/images/closed.svg'
// import { getDialer } from '../../../actions/dialer'
import { MarkChatRead } from '@mui/icons-material'
import SnoozeIcon from '@mui/icons-material/Snooze'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography
} from '@mui/material'
import { Mention, MentionsInput } from 'react-mentions'
import { connect, useDispatch } from 'react-redux'
import { getDialer, setPhoneNumber } from '../../../actions/dialer'
import schedule from '../../../assets/images/schedule.png'
import CallbackModal from '../../../common/CallbackModal'
import CommonButtons from '../../../common/CommonButtons'
import { CommonNotify } from '../../../common/CommonNotify'
import SavedRepliesModal from '../../../common/SavedRepliesModal'
import { sendMessageUser } from '../../../config/leadAPI'
import {
  apiSlice
} from '../../../features/api/apiSlice'
import {
  compareNotes,
  getMessageClass,
  getMessageStatus,
  getMessageSymbole
} from '../utils'

const Chat = ({
  id,
  getConversations,
  agentCode,
  activeTab,
  membersList,
  newMessage,
  allConversations,
  res,
  getConvo
}) => {
  // const res = useGetConversationQuery(id)
  const dispatch = useDispatch()
  const [activeConversation, setActiveConversation] = useState(null)
  const [activeMsg, setActiveMsg] = useState('')
  const [activeNote, setActiveNote] = useState('')
  const [messages, setMessages] = useState([])
  const [notes, setNotes] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openReplayModal, setOpenReplayModal] = useState(false)
  const [openReplayScheduleModal, setOpenReplayScheduleModal] = useState(false)
  const [templateList, setTemplateList] = useState([])
  const [scheduleInfo, setScheduleInfo] = useState(null)
  const [eventList, setEventList] = useState([])
  const [note, setNote] = useState(``)
  const [openSchedule, setOpenSchedule] = useState(false)

  const logs = ['Outgoing call', 'Incoming call', 'LIVE_CALL', 'LEAVE_MESSAGE', 'DIGITAL_CALL', 'FORWARDED_CALL']
  const plusStatus = ['completed', 'Received Message']

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    setMessages([])
    getLeadTemplate()
    getRelatedLeads()
  }, [id])
  const open = Boolean(anchorEl)

  useEffect(() => {
    const widgetId = localStorage.getItem('widget_id')
    const pusher = new Pusher('7b262bf6c81fabf9536b', {
      cluster: 'eu',
      encrypted: true
    })
    // const pusher = new Pusher({
    //   appId: "1041863",
    //   key: "7b262bf6c81fabf9536b",
    //   secret: "dcb5f826130aeda44713",
    //   cluster: "eu",
    // });

    const channel = pusher.subscribe(
      `conversation-channel-` + widgetId,
      eventList
    )
    channel.bind_global(function (event, data) {
      dispatch(
        apiSlice.util.updateQueryData(
          'getConversation',
          data.conversation_id,
          draftConversation => {
            getConversations()
            draftConversation.data.conversation.messages = [
              ...draftConversation.data.conversation.messages,
              data
            ]
            draftConversation.data.conversation.unread_messages =
              draftConversation.data.conversation.unread_messages + 1
          }
        )
      )
    })

    return () => {
      pusher.unsubscribe(`conversation-channel-${widgetId}`)
      channel.unbind_all()
      // channel.unsubscribe();
      pusher.disconnect()
    }
  }, [eventList])

  useEffect(() => {
    setEventList(allConversations?.conversations?.map(obj => obj?.id))
  }, [allConversations])

  const handleSave = value => {
    setActiveMsg(value?.description || '')
  }

  const timeValue = value => {
    setScheduleInfo(value)
  }

  useEffect(() => {
    if (document.querySelector('.height-message-content')) {
      document
        .querySelector('.height-message-content')
        .scrollTo(
          0,
          document.querySelector('.height-message-content').scrollHeight
        )
    }
    if (activeConversation?.messages !== messages && activeConversation) {
      dispatch(
        apiSlice.util.updateQueryData(
          'getConversation',
          id,
          draftConversation => {
            draftConversation.data.conversation.messages = messages
            draftConversation.data.conversation.unread_messages =
              draftConversation.data.conversation.unread_messages + 1
          }
        )
      )
    }
    if (activeConversation?.notes !== notes && activeConversation) {
      dispatch(
        apiSlice.util.updateQueryData(
          'getConversation',
          id,
          draftConversation => {
            draftConversation.data.conversation.notes = notes
          }
        )
      )
    }
  }, [messages, notes])

  useEffect(() => {
    if (res?.status === 200) {
      setActiveConversation(res.data.data.conversation)
      const data = [
        ...res.data.data.conversation.messages,
        ...res.data.data.logs
      ]?.sort((a, b) => new Date(a?.created_at) - new Date(b?.created_at))
      setMessages((current) => ([...current, ...data]))
      setNotes(res.data.data.conversation.notes)
      if (res.data.data.conversation?.unread_messages > 0) {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/mark-read`
        const ids = []
        ids[0] = id
        axios
          .put(url, {
            ids
          })
          .then(res => {
            getConversations()
          })
          .catch(error => { })
      }
    }
  }, [res])




  const getRelatedLeads = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${id}/related-leads`
    await axios
      .get(url)
      .then(res => {
        setMessages((current) => ([...current, ...res.data.data.data]))
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Somethign went to wrong!'
        )
      })
  }

  const getLeadTemplate = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-responses?target=Lead`
    await axios
      .get(url)
      .then(res => {
        setTemplateList(res?.data?.data || [])
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Somethign went to wrong!'
        )
      })
  }

  useEffect(() => {
    if (newMessage) {
      setActiveMsg(newMessage, sendMessage)
    }
  }, [newMessage])

  const sendMessage = async () => {
    if (activeMsg) {
      const data = {
        body: activeMsg,
        sid: activeConversation.sid,
        agent_code: agentCode,
        whatsapp: 0
      }

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/agent-push-message`
      axios
        .post(url, data)
        .then(res => {
          setActiveMsg('')
          setMessages(current => ([{...res.data.data }, ...(current || [])]))
          getConversations()
          getConvo(res.data.data.conversation_id)

        })
        .catch(error => {
          CommonNotify('Cant Send message')
        })
    } else {
      CommonNotify("Can't send empty message")
    }
  }

  const addNote = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${id}/note`
    setNotes(current => [
      ...current,
      { created_at: new Date(), note: activeNote }
    ])
    axios
      .post(url, { note: note })
      .then(res => {
        setActiveNote('')
      })
      .catch(err => {
        CommonNotify('Cant Add Note')
      })
  }

  const updateStatus = status => {
    const data = {
      state: status
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${id}/update`
    axios
      .put(url, data)
      .then(res => {
        getConversations()
      })
      .catch(error => {
        CommonNotify('Cant Update Status')
      })
  }

  const mentionHandle = (data, mentions) => {
    setNote(data)
    const dat1a = mentions?.map(obj => {
      const replaceWith = membersList?.find(object => object?.text === obj?.id)
      setNote((note || activeNote).replace(obj?.id, replaceWith?.id))
    })
  }
  const ChatSendContent = [
    {
      menuItem: 'Reply',
      render: () => (
        <>
          <div className="replyTab">
            <TextArea
              onChange={e => setActiveMsg(e.target.value)}
              value={activeMsg}
              placeholder="Joe Dude, this one is for you! A bug within the team settings."
            />
            <div className="message-save">
              <div className="message-footer-items">
                <div className="content-icons"> </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: '7%' }}
                >
                  <Popup
                    trigger={
                      <BookmarkIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpenReplayModal(true)}
                      />
                    }
                    content="Message Templates"
                    position="bottom center"
                  />
                  <Popup
                    trigger={
                      <ScheduleIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpenReplayScheduleModal(true)}
                      />
                    }
                    content="Schedule a message"
                    position="bottom center"
                  />
                </div>
                <CommonButtons
                  onClick={sendMessage}
                  content="Reply"
                  background="green"
                />
              </div>
              {openReplayModal && (
                <SavedRepliesModal
                  {...{
                    handleSave,
                    templateList,
                    getLeadTemplate,
                    timeValue,
                    scheduleInfo
                  }}
                  setOpenReplayModal={() => setOpenReplayModal(false)}
                />
              )}
              {openReplayScheduleModal && (
                <SavedRepliesModal
                  {...{
                    handleSave,
                    templateList,
                    getLeadTemplate,
                    timeValue,
                    scheduleInfo
                  }}
                  schedule={true}
                  setOpenReplayModal={() => {
                    setOpenReplayScheduleModal(false)
                    if (scheduleInfo) {
                      const msgData = {
                        from: activeConversation?.source,
                        to: activeConversation?.contact?.phone_number,
                        message: activeMsg
                      }
                      sendMessageUser(
                        msgData,
                        () => {
                          setActiveMsg('')
                          setOpenReplayScheduleModal(false)
                        },
                        scheduleInfo
                      )
                    }
                  }}
                />
              )}
            </div>
          </div>
        </>
      )
    },
    {
      menuItem: 'Note',
      render: () => (
        <>
          <div className="replyTab">
            <MentionsInput
              placeholder="Write a comment and @mention teammates. This is only visible to your team."
              onChange={(e, new1, newplain, mentions) => {
                setActiveNote(e.target.value)
                mentionHandle(e.target.value, mentions)
              }}
              // onChange={e => setActiveNote(e.target.value)}
              value={activeNote}
              className="note-style"
            >
              <Mention
                trigger="@"
                data={membersList}
                // markup='<:__id__>'
                displayTransform={name => `@${name}`}
                // displayTransform={(id, display) => display}
                markup="<:__display__>"
              />
            </MentionsInput>
            <div className="message-save">
              <div
                className="message-footer-items"
                style={{ background: '#fef7ee' }}
              >
                <div className="content-icons"></div>
                <CommonButtons
                  onClick={addNote}
                  content="Add Note"
                  background="green"
                />
              </div>
            </div>
          </div>
        </>
      )
    }
  ]
  let date1 = moment().format('YYYY-MM-DD')

  const setCreatedDate = data => {
    if (moment(data?.created_at).format('YYYY-MM-DD') !== date1) {
      date1 = moment(data?.created_at).format('YYYY-MM-DD')
      return true
    } else {
      return false
    }
  }

  const getFormat = date => {
    if (
      moment(date).format('dddd, MMMM Do YYYY') ===
      moment().format('dddd, MMMM Do YYYY')
    )
      return 'Today'
    else if (
      moment(date).format('dddd, MMMM Do YYYY') ===
      moment()
        .subtract(1, 'days')
        .format('dddd, MMMM Do YYYY')
    ) {
      return 'Yesterday'
    } else {
      return moment(date).format('LL')
    }
  }

  const leadData = [
    { label: 'Name', key: 'customer_name' },
    { label: 'Company', key: 'company_name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: '' },
    { label: 'Message', key: 'lead_message' },
    { label: 'To team', key: '' },
    { label: 'Status', key: 'final_status' },

  ]

  const typeConvert={
    LEAVE_MESSAGE: 'Message',
    LIVE_CALL :'Callback',
    DIGITAL_CALL :'Webcall',
  }
  
  return (
    <Segment basic className="message-segment">
      {!res || !messages ? (
        <Dimmer
          style={{ width: '800px', backgroundColor: '#C8C8CA' }}
          active={!!res || !messages}
        >
          <Loader />
        </Dimmer>
      ) : (
        <div className="conversation-content-messages">
          <div className="conversation-content-messages-holder">
            <div className="content-text" style={{ marginBottom: '17px' }}>
              <Typography variant="h6">
                {activeConversation?.contact?.name || null}
                <br></br>
                {activeConversation?.participant?.[0]?.number}
              </Typography>

              <div className="content-icons">
                <Popup
                  trigger={
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenSchedule(true)
                      }}
                      className="snoozebutton"
                    >
                      <IconButton
                        sx={{
                          marginRight: '10px',
                          color: 'black',
                          height: '20px',
                          width: '20px'
                        }}

                        component="span"
                      >
                        <img

                          style={{
                            height: '30px',
                            width: '30px',
                            color: 'black',
                            marginRight: '5px'
                          }}
                          src={schedule}
                          alt="schedule"
                        />
                      </IconButton>
                    </Button>
                  }
                  content="Schedule Call"
                  position="bottom center"
                />
                <Popup
                  trigger={
                    <Button
                      variant="contained"
                      onClick={() => {
                        dispatch(getDialer(true))
                        dispatch(
                          setPhoneNumber(
                            activeConversation?.participant?.[0]?.number
                          )
                        )
                      }}
                      className="snoozebutton"
                    >
                      <IconButton
                        sx={{
                          marginRight: '10px',
                          color: 'black',
                          height: '20px',
                          width: '20px'
                        }}

                        component="span"
                      >
                        <CallIcon />
                      </IconButton>
                    </Button>
                  }
                  content="Make a call"
                  position="bottom center"
                />
                {activeTab === 'inbox' && (
                  <>
                    <Popup
                      trigger={
                        <Button
                          variant="contained"
                          onClick={() => updateStatus(2)}
                          className="snoozebutton"

                        >
                          <IconButton
                            sx={{
                              marginRight: '5px',
                              color: 'black',
                              height: '20px',
                              width: '20px'
                            }}
                            aria-label="upload picture"
                            component="span"
                          >
                            <SnoozeIcon />
                          </IconButton>
                        </Button>
                      }
                      content="Snooze the Message"
                      position="bottom center"
                    />

                    <Popup
                      trigger={
                        <Button
                          variant="contained"
                          onClick={() => updateStatus(3)}
                          sx={{
                            marginLeft: '4px',
                            marginRight: '4px',
                            fontSize: '12px !important',
                            background: 'black'
                          }}
                        >
                          <span style={{ marginRight: '5px' }}>
                            <Closed height={20} width={20} />
                          </span>
                          Close
                        </Button>
                      }
                      content="Close the Message"
                      position="bottom center"
                    />
                  </>
                )}

                {activeTab === 'snooze' && (
                  <>
                    <Popup
                      trigger={
                        <Button
                          variant="contained"
                          onClick={() => updateStatus(1)}
                          className="snoozebutton"
                        >
                          <IconButton
                            style={{
                              color: 'black',
                              padding: 0
                            }}
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <MarkChatRead />
                          </IconButton>
                        </Button>
                      }
                      content="Activate Conversation"
                      position="bottom center"
                    />
                    <div>
                      <Popup
                        trigger={
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => updateStatus(3)}
                            sx={{
                              marginLeft: '4px',
                              marginRight: '4px',
                              fontSize: '12px !important',
                              background: 'black'
                            }}
                          >
                            <span style={{ marginRight: '5px' }}>
                              <Closed height={20} width={20} />
                            </span>
                            Close
                          </Button>
                        }
                        content="Close the Message"
                        position="bottom center"
                      />
                    </div>
                  </>
                )}

                {activeTab === 'closed' && (
                  <>
                    <Popup
                      trigger={
                        <Button
                          variant="contained"
                          onClick={() => updateStatus(1)}
                          className="snoozebutton"
                          style={{ marginRight: '5px' }}
                        >
                          <IconButton
                            style={{
                              height: '30px',
                              width: '30px',
                              color: 'black',
                              marginRight: '5px',
                              padding: 0
                            }}
                            aria-label="upload picture"
                            component="span"
                          >
                            <MarkChatRead />
                          </IconButton>
                        </Button>
                      }
                      content="Activate Conversation"
                      position="bottom center"
                    />

                    <Popup
                      trigger={
                        <Button
                          variant="contained"
                          onClick={() => updateStatus(2)}
                          className="snoozebutton"
                        >
                          <IconButton
                            sx={{
                              marginRight: '5px',
                              color: 'black',
                              height: '20px',
                              width: '20px'
                            }}
                            aria-label="upload picture"
                            component="span"
                          >
                            <SnoozeIcon />
                          </IconButton>
                        </Button>
                      }
                      content="Snooze the Message"
                      position="bottom center"
                    />
                  </>
                )}
              </div>
            </div>
            <Divider />
            <div className="content-message-body">
              <div className="message-content height-message-content">
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none'
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography sx={{ p: 1 }}>
                    Source :{' '}
                    {activeConversation?.participant
                      ? activeConversation?.source
                      : ''}
                  </Typography>
                </Popover>
                {messages
                  ?.concat(notes)
                  ?.sort(compareNotes)
                  ?.map((data, x) => data ?

                    <div key={x}>
                      <Typography m={2} sx={{ textAlign: 'center' }}>
                        {data?.body && setCreatedDate(data)
                          ? getFormat(data?.created_at)
                          : ' '}
                      </Typography>
                      {data?.direction === 'outgoing' ? (
                        <div className="message-text message-send">
                          <div className="chatMessages">
                            <div className="chatTime">
                              <div
                                className="message-text-holder"
                              // style={{
                              //   backgroundColor: '#dce1f9'
                              // }}
                              >
                                <p key={x}>{data.body}</p>
                                <div className="d-flex" style={{justifyContent: 'space-between'}}>
                                  <Typography className="time" mr={2}>
                                    {data?.created_at
                                      ? moment(
                                        data?.created_at,
                                        'HH:mm:ss'
                                        ).format('hh:mm A')
                                        : moment(data?.created_at).format('LT')}
                                  </Typography>
                                        {getMessageStatus(data.status)}
                                </div>
                              </div>
                            </div>
                            <div className="messageProfile">
                              <label>A</label>
                            </div>
                          </div>
                        </div>
                      ) : data.direction === 'incoming' ? (
                        <div className="message-text message-receive">
                          <div className="chatMessages">
                            <div
                              className={getMessageClass(
                                activeConversation.participant
                              )}
                            >
                              {getMessageSymbole(
                                activeConversation.participant
                              )}
                            </div>
                            <div className="chatTime">
                              <div
                                className="message-text-holder"
                              // style={{
                              //   backgroundColor: '#f1f1f1'
                              // }}
                              >
                                <p
                                  onMouseEnter={handlePopoverOpen}
                                  onMouseLeave={handlePopoverClose}
                                >
                                  {data.body}
                                </p>
                                    <Typography className="time" mr={2}>
                                      {data?.created_at
                                        ? moment(
                                          data?.created_at,
                                          'HH:mm:ss'
                                        ).format('hh:mm A')
                                        : moment(data?.created_at).format('LT')}
                                    </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : data.note ? (
                        <div className="message-text message-send">
                          <div className="chatMessages">
                            <div className="chatTime">
                              <div
                                className="message-text-holder"
                                style={{
                                  backgroundColor: '#fef7ee'
                                }}
                              >
                                <p>
                                  <span style={{ fontWeight: 500 }}>
                                    {data?.from_user_name}
                                  </span>

                                  <span> left a note</span>
                                  <span
                                    style={{
                                      verticalAlign: 'text-bottom',
                                      fontWeight: 500
                                    }}
                                  >
                                    {' '}
                                    .
                                  </span>
                                  <span>
                                    {' '}
                                    {moment(data?.created_at).format('LT')}
                                  </span>
                                </p>
                                <p>
                                  {data.note
                                    .replace('<:', '@')
                                    .replace('>', '')}
                                </p>
                              </div>
                            </div>
                            <Popup
                              trigger={
                                <div className="messageProfile">
                                  <label>N</label>
                                </div>
                              }
                              content={data?.from_user_name}
                              position="bottom center"
                            />
                          </div>
                        </div>
                      ) : null}
                      {/* {(data.log && data.message_type==="Log" && logs.includes(data?.type)) ? 

data?.direction==="incoming" ?
                          
                          <Typography m={2} sx={{ textAlign:'center' }}>
                            {
                             `${( moment(data.log.time + 'Z').diff(moment.now(),"days")  < -30)  ?  moment(data.log.time).format('DD-MM-YY, h:mm:ss a')  : moment(data.log.time + 'Z').fromNow()}` +
                              ' ' +
                              data.log.event_name}
                          </Typography> 
                          
                          :
                          <>
                             <Typography m={2} sx={{ textAlign:'center' }}>
                            {
                             `${( moment(data.log.time + 'Z').diff(moment.now(),"days")  < -30)  ?  moment(data.log.time).format('DD-MM-YY, h:mm:ss a')  : moment(data.log.time + 'Z').fromNow()}` +
                              ' ' +
                              data.log.event_name}
                          </Typography> 
                          
                          <Typography m={2} sx={{ textAlign:'center' }}>
                            {
                             `${( moment(data.log.time + 'Z').diff(moment.now(),"days")  < -30)  ?  moment(data.log.time).format('DD-MM-YY, h:mm:ss a')  : moment(data.log.time + 'Z').fromNow()}` +
                              ' ' +
                              data.log.event_name}
                          </Typography>
                          </>
                          : null
                        } */}
                      {/* {data.log && data.message_type==="Log" && logs.includes(data?.type) && (
                          <Typography m={2} sx={{ textAlign:'center' }}>
                            {
                             `${( moment(data.log.time + 'Z').diff(moment.now(),"days")  < -30)  ?  moment(data.log.time).format('DD-MM-YY, h:mm:ss a')  : moment(data.log.time + 'Z').fromNow()}` +
                              ' ' +
                              data.log.event_name}
                          </Typography>
                        )} */}
                      <>
                        {logs.includes(data?.type) ? (
                          data?.direction === 'Inbound' ||
                            data?.type === 'Incoming call' ? (
                            plusStatus?.includes(data?.final_status) ? (
                              <>
                                <div className="message-text message-send " style={{ justifyContent: 'left' }}>
                                  <div className="chatMessages">
                                    <div className="chatTime">
                                      <div
                                        className="message-text-holder"
                                        style={{
                                          backgroundColor: '#fef7ee'
                                        }}
                                      >
                                          <p className="font-bold" style={{textAlign:'left',marginBottom: '15px' }} key={x}>{typeConvert?.[data?.type] || data?.type} Via {data.source}</p>
                                        {leadData?.map((obj) =>
                                          data?.[obj?.key] ?
                                            <p className='d-flex w-100' style={{  marginBottom: '5px' }}><span className="font-bold d-inline-block mr-1">{obj?.label}: </span> {data?.[obj?.key]}</p> : ''
                                        )}
                                        <Typography className="time" mr={2}>
                                          {data?.createdAtTime
                                            ? moment(
                                              data?.createdAtTime,
                                              'HH:mm:ss'
                                            ).format('hh:mm A')
                                            : moment(data?.created_at).format('LT')}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </>
                            ) : (
                              <>
                                <div className="mainChat">
                                  <div className="callSection">
                                    <Box className="missCall">
                                      <Box className="call-miss-icon">
                                        <PhoneMissedIcon
                                          sx={{ color: '#fff' }}
                                        />
                                      </Box>
                                      <Box ml={2}>
                                        <Typography>Missed call</Typography>
                                        <Typography>
                                          <Typography>
                                            Missed by{' '}
                                            {data?.agent_name ||
                                              data?.agent?.first_name}
                                          </Typography>
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </div>
                                </div>
                                <Typography className="time" mr={2}>
                                  {data?.createdAtTime
                                    ? moment(
                                      data?.createdAtTime,
                                      'HH:mm:ss'
                                    ).format('hh:mm A')
                                    : moment(data?.created_at).format('LT')}
                                </Typography>
                              </>
                            )
                          ) : data?.direction === 'Outbound' ||
                            data?.type === 'Outgoing call' ? (
                            <Stack direction="column" alignItems="end">
                                  {plusStatus?.includes(data?.final_status) ? (
                                <>
                                      <div className="message-text message-send " style={{ justifyContent: 'right' }}>
                                        <div className="chatMessages">
                                          <div className="chatTime">
                                            <div
                                              className="message-text-holder"
                                              style={{
                                                backgroundColor: '#fef7ee'
                                              }}
                                            >
                                              <p className="font-bold" style={{ marginBottom: '15px',textAlign:'left' }} key={x}>{typeConvert?.[data?.type] || data?.type} Via {data.source}</p>
                                              {leadData?.map((obj) =>
                                                data?.[obj?.key] ?
                                                  <p className='d-flex w-100' style={{marginBottom: '5px' }}><span className="font-bold d-inline-block mr-1">{obj?.label}: </span> {data?.[obj?.key]}</p> : ''
                                              )}
                                              <Typography className="time" mr={2}>
                                                {data?.createdAtTime
                                                  ? moment(
                                                    data?.createdAtTime,
                                                    'HH:mm:ss'
                                                  ).format('hh:mm A')
                                                  : moment(data?.created_at).format('LT')}
                                              </Typography>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                </>
                              ) : (
                                <>
                                  <div className="mainChat">
                                    <div className="callSection">
                                      <Box className="missCall">
                                        <Box className="call-miss-icon">
                                          <PhoneMissedIcon
                                            sx={{ color: '#fff' }}
                                          />
                                        </Box>
                                        <Box style={{ margin: 'auto 16px' }}>
                                          <Typography>Missed call</Typography>
                                          <Typography>
                                            Dialed by{' '}
                                            {data?.agent_name ||
                                              data?.agent?.first_name}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </div>
                                  </div>{' '}
                                  <Typography className="time" mr={2}>
                                    {data?.createdAtTime
                                      ? moment(
                                        data?.createdAtTime,
                                        'HH:mm:ss'
                                      ).format('hh:mm A')
                                      : moment(data?.created_at).format('LT')}
                                  </Typography>
                                </>
                              )}
                            </Stack>
                          ) : null
                        ) : null}
                      </>
                    </div> : ''

                  )}
              </div>
              <div className="message-footer">
                <Tab
                  menu={{ secondary: true, pointing: true }}
                  panes={ChatSendContent}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {openSchedule && (
        <CallbackModal
          isModalClose={() => {
            setTimeout(() => {
              setOpenSchedule(false)
            }, 300)
          }}
          isModalOpen={() => console.log(`open`)}
          open={openSchedule}
          // dropDownData={dropDownData || []}
          // leadData={leadAbout}
          phone_number={
            activeConversation?.contact?.phone_number ||
            activeConversation?.friendly_name
          }
        />
      )}
    </Segment>
  )
}

const mapStateToProps = state => ({
  allConversations: state.conversation.allConversations
})

export default connect(mapStateToProps)(Chat)

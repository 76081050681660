import React, { Fragment, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import DialerImage from '../assets/images/flaticons/dialer.png'
import callbackIcon from '../assets/images/callback-icon.png'
import scheduleIcon from '../assets/images/schedule-meeting.png'
import SendMessageModal from '../common/SendMessageHeader'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'
import { connect, useDispatch } from 'react-redux'
import { getDialer } from '../actions/dialer'
import {  getSubscription, getWidget } from '../actions/home'
import { CommonNotify } from './CommonNotify'
import CommonSelect from './CommonSelect'
import InfoIcon from '@mui/icons-material/Info';
import './ToggleSwitch.scss'
import NotificationModal from './NotificationModal'
import ToggleSwitch from './ToggleSwitch'
import { Link } from 'react-router-dom'
import CallbackModal from './CallbackModal'
import EmailSend from './EmailSend'
import ScheduleModal from './ScheduleModal'
import ProfileModal from './ProfileModal'
import { analytics } from '../Segmant'
import callDialer from '../assets/images/callDialer.png'
import { Button, Tooltip, Popover } from '@mui/material'
import WifiCallingIcon from '@mui/icons-material/WifiCalling';
import { GET_PIPELINES } from '../actions/types'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CommonAddCardModal from './CommonAddCardModal'
import moment from 'moment'
const role_name = localStorage.getItem('role_name')

export const isAdmin = () => {
  const adminRoles = ['Admin', 'Operation Manager', 'Widget Manager', 'Manager']
  const role_name = localStorage.getItem('role_name')
  return adminRoles.includes(role_name)
}

const Title = props => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userStatus, setUserStatus] = useState(false)
  const [location, setlocation] = useState('')
  const [p_link, setPLink] = useState(null)
  const [upgradeNeeded, setUpgradeNeeded] = useState(false)
  const [upgradeButton, setUpgradeButton] = useState(false)
  const [upgradeTitle, setUpgradeTitle] = useState('')
  const [freeTitle, setFreeTitle] = useState('')
  const [openSms, setOpenSms] = useState(false)
  const [script, setScript] = useState('')
  const [openCallback, setOpenCallback] = useState(false)
  const [showInstallationBanner, setShowInstallationBanner] = useState(false)
  const adminVerification = localStorage.getItem('admin_verification_status')
  const titleRedirect = ['Your account is currently pending verification']
  const [model, setModel] = useState(false)
  const [textshow, setTextshow] = useState(false)
  const [openSchedule, setOpenSchedule] = useState(false)
  const [rightSectionContent, setRightSectionContent] = useState('')
  const [open, setOpen] = useState(false)
  const [addCardModalOpen, setAddCardModalOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [addCreditCardLoader, setAddCreditCardLoader] = useState(false)
  const [updateCard, setUpdateCard] = useState(false)
  const [dataModal, setDataModal] = useState({
    dataPlan: {
      planName: 'Pro',
      planPrice: '$119'
    },
    addCredits: '',
    promoCode: '',
    creditCardDetails: {
      cardName: '',
      cardNumber: '',
      validMonth: '',
      validYear: '',
      cvv: ''
    },
    creditCardDetailsErrors: {
      cardName: '',
      cardNumber: '',
      validMonth: '',
      validYear: '',
      cvv: ''
    }
  })
  const handleCloseModal = async () => {
    props.closeSubscriptionModal()
  }

  const handleOpenModal = async () => {
    props.openSubscriptionModal()
  }

  useEffect(() => {
    if (!props.widget) {
      return
    }
    fetchWidget()
    if (!props.widget?.data[0]?.installed) {
      setTextshow(true)
    }
    if (props.widget?.data[0]?.script_id) {


      setScript(`<script data-api-url="${process.env.REACT_APP_BASE_APP_URL}" data-api-key="${props.widget?.data[0].script_id}" src="https://widget.limecall.com/widget.js"> </script>`)
    }
  }, [props.widget])

 const handleCreditCardInfo = e => {
    const { name, value } = e.target
   let dataModal1=dataModal
   dataModal1.creditCardDetails[name] = value

   setDataModal({ ...dataModal1 })
    const ERROR_CODE = {
      cardName: 'Card Name',
      cardNumber: 'Card Number',
      validMonth: 'Month',
      validYear: 'Year',
      cvv: 'CVV'
    }

    if (!value) {
      dataModal1.creditCardDetailsErrors[
        name
      ] = `${ERROR_CODE[name]} is required`
      setDataModal({ ...dataModal1 })

      return
    } else {
      dataModal1.creditCardDetailsErrors[name] = ''
      setDataModal({ ...dataModal1 })

    }

    if (name === 'cardNumber') {
      if (value < 0) {
        dataModal1.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        setDataModal({ ...dataModal1 })

      } else {
        dataModal1.creditCardDetailsErrors[name] = ''
        setDataModal({ ...dataModal1 })
        
      }
    }

    if (name === 'validMonth') {
      if (value.toString()?.length > 2) {
        dataModal1.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 2 digit long.`
        setDataModal({ ...dataModal1 })
        
      } else if (value < 0) {
        dataModal1.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        setDataModal({ ...dataModal1 })
        
      } else {
        dataModal1.creditCardDetailsErrors[name] = ''
        setDataModal({ ...dataModal1 })
        
      }
    }

    if (name === 'validYear') {
      if (value.toString()?.length > 4) {
        dataModal1.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 4 digit long.`
        setDataModal({ ...dataModal1 })
        
      } else if (value < 0) {
        dataModal1.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        setDataModal({ ...dataModal1 })
        
      } else {
        dataModal1.creditCardDetailsErrors[name] = ''
  setDataModal({...dataModal1})
        
      }
    }

    if (name === 'cvv') {
      if (value.toString()?.length > 4) {
        dataModal1.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 4 digit long.`
  setDataModal({...dataModal1})
        
      } else if (value < 0) {
        dataModal1.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        setDataModal({ ...dataModal1 })
        
      } else {
        dataModal1.creditCardDetailsErrors[name] = ''
        setDataModal({ ...dataModal1 })
        
      }
    }
  }
  
 const  onAddCreditCard = () => {
    if (
      !dataModal.creditCardDetails.cardNumber.match(/^[-_ 0-9]+$/)
    ) {
      CommonNotify('Invalid Card No', 'warning')
      return
    }
setAddCreditCardLoader(true)


    if (
      !dataModal.creditCardDetails.cardName ||
      !dataModal.creditCardDetails.cardNumber ||
      !dataModal.creditCardDetails.validMonth ||
      !dataModal.creditCardDetails.validYear ||
      !dataModal.creditCardDetails.cvv
    ) {
      CommonNotify('Please add all felids', 'warning')
      setAddCreditCardLoader(false)
      return
    }
    const date = new Date()
    const newYear = moment(date).format('yyyy')
    if (dataModal.creditCardDetails.validMonth > 12) {
      setAddCreditCardLoader(false)

      CommonNotify('Please enter valid expire date', 'warning')
      return
    } else {
      if (dataModal.creditCardDetails.validYear < newYear) {
        setAddCreditCardLoader(false)

        CommonNotify(
          'Expiry date should not be lesser then current date',
          'warning'
        )
        return
      }
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/create-customer-credit-card`

    const data = {
      name: dataModal.creditCardDetails.cardName,
      card_number: dataModal.creditCardDetails.cardNumber,
      expiry: `${dataModal.creditCardDetails.validMonth}/${dataModal.creditCardDetails.validYear}`,
      cvv: dataModal.creditCardDetails.cvv
    }
    axios
      .post(url, data)
      .then(res => {
        setAddCreditCardLoader(false)

        CommonNotify(
          `Card successfully ${updateCard ? 'updated' : 'created'}`,
          'success'
        )
        setAddCardModalOpen(false)
fetchCard()
      })
      .catch(err => {
        const errors = { ...err }
setAddCreditCardLoader(false)


        if (errors.response.data.errors?.length) {
          //CommonNotify(errors.response.data.errors[0])
          const errorMsg = errors.response.data.errors[0]

        } else {
          // this.setState({ errorMsg: 'Cant create payment methed.' })
          //CommonNotify("Cant create payment methed.")
        }
      })
  }
  const fetchCard = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-customer-payment-methods`
    axios
      .get(url)
      .then(res => {
        if (res) {
          if (res.data.data?.length === 1) {
            // subscriptionData['cardId'] = res.data.data[0].id
            setUpdateCard(true)

            // this.setState({ updateCardInfo: res.data.data[0] })
          }
          const updatePayCard = res.data.data.map(item => {
            return {
              key: `${item.card.expiry_month} / ${item.card.expiry_month}`,
              text: `**** ${item.card.last_four_digits}`,
              value: item.id
            }
          })
          // this.setState({
          //   payCard: res.data.data,
          //   payCardDropdown: updatePayCard
          // })
        }
      })
      .catch(function (error) { })
  }
  useEffect(() => {

    dispatch(getWidget())
    upgradePlan()
    if (!props.subscription) {
      getSubscription()
    }
    fetchRightSectionContent()
  }, [])

  useEffect(() => {
    setLocation()
  }, [location, userStatus])

  const setLocation = async () => {
    const status = await localStorage.getItem('status')
    setUserStatus(status === '1' ? true : false)
    const location = window.location.href
    setlocation(location.split('/')[3])
  }

  const isModalClose = () => {
    setOpenSms(false)
  }

  const openDialer = () => {
    props.getDialer(!props.dialerStatus)
  }
  const callbackDialer = () => {
    setOpenCallback(!openCallback)
  }
  const scheduleModel = () => {
    setOpenSchedule(!openSchedule)
  }

  const fetchChecklistStatus = () => {
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/checklist`
    axios
      .get(url)
      .then(res => {
        let dt = res.data.data
        if (dt?.widget_installed === 0) {
          setShowInstallationBanner(true)
        } else {
          setShowInstallationBanner(false)
        }
      })
      .catch(err => { })
  }

  const fetchWidget = async () => {
    let script_id = await localStorage.getItem('data-api-key')
    if (!script_id) script_id = props.widget.data[0].script_id
    let preview_link_url = `${fetchUrl()}/widget-new-preview/${script_id}`

    setPLink(preview_link_url)
    sessionStorage.setItem('preview_link', preview_link_url)
    localStorage.setItem('preview_link', preview_link_url)
  }

  const upgradePlan = async () => {
    const result = await localStorage.getItem('plan_name')
    const upgrade = await localStorage.getItem('upgrade_status')
    const upgradeTtl = await localStorage.getItem('upgrade_title')
    const upgradeButton = await localStorage.getItem('upgrade_button')
    const freeTitles = await localStorage.getItem('freeplan')
    if (result === 'app-sumo-p05') return false
    await setUpgradeTitle(upgradeTtl)


    // if(props.upgradePlanData){
    //   setUpgradeNeeded(false)
    // }else{
    //   const upgrade = await localStorage.getItem('upgrade_status')
    //   if(upgrade) {
    //     setUpgradeNeeded(true)
    //   }
    // }

    if (freeTitles) {
      setFreeTitle(freeTitles)
    } else {
      setFreeTitle('')
    }
    if (upgrade === '1') {
      setUpgradeNeeded(true)
    } else {
      setUpgradeNeeded(false)
    }

    if (upgradeButton == '1') {
      setUpgradeButton(true)
    } else {
      setUpgradeButton(false)
    }
  }

  const fetchUrl = () => {
    const host = window.location.hostname
    let url = ''
    if (host === 'localhost') {
      const port = window.location.port
      url = `//${host}:${port}`
    } else {
      url = `//${host}`
    }
    return url
  }

  const setAvailability = async (e, val) => {

    setUserStatus(val)
    localStorage.setItem('status', val ? 1 : 0)
    setAvailabilityStatus(val)
  }

  const setAvailabilityStatus = async status => {
    const id = await localStorage.getItem('id')
    const data = {}
    const url = `${process.env.REACT_APP_BASE_APP_URL
      }/api/v1/team/update-member-availability/${id}/${status ? 1 : 0}`

    axios
      .post(url, data)
      .then(res => { })
      .catch(err => {
        CommonNotify('Some error occured')
      })
  }
  const fetchRightSectionContent = async () => {
    try {
      const response = await fetch('https://app.leanbe.ai/embed/leanbe.js?v=1.0.5'); // Replace with the actual script URL
      const scriptContent = await response.text();
      setRightSectionContent(scriptContent);
    } catch (error) {
      console.error('Error fetching script:', error);
    }
  };
  
  
  function xyz(l, e, n, b, a, i, s) {
    s = l[n] = l[n] || {};
    s['push'] = function (m) {
      (l[n].q = l[n].q || []).push([].concat([].slice.call(arguments, 0)))
    }
    s['c'] = ['core', 'widget'];
    s['l'] = 1 * new Date();
    s['ak'] = b;
    let v = new Date().getMonth() + '.' + new Date().getDate();
    a = e.createElement('script')
      i = e.getElementsByTagName('script')[0];
    a.async = 1;
    a.src = 'https://app.leanbe.ai/embed/leanbe.js?v=' + v;
    i.parentNode.insertBefore(a, i);
  }

  const showWarn = ['get-started', 'home', 'leads', 'manageleads', 'widgets']
  const handleLeanbeClick = () => {
    // Push an event to Leanbe to open the UI
    window.leanbe.push(['openUiEvent']);

    // You may need to check Leanbe documentation for the actual event to trigger
  };
  useEffect(() => {
    getFunnels()
//     const box = document.getElementsByClassName('SGBF-widget-container')?.[0];
//  box.style.visibility = 'hidden';
    // document.getElementsByClassName('SGBF-close')?.addEventListener('click',) 
  }, [])
  const getFunnels = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/funnels`
    await axios
      .get(url)
      .then(res => {


        dispatch({
          type: GET_PIPELINES,
          payload: res?.data?.data
        })
      })
      .catch(error => { })
  }
  

  return (
    <div
      className={
        props.meetingLocation
          ? 'title-container header_fixed headerMeeting'
          : textshow && (!parseInt(localStorage?.getItem('admin_verification_status')) || props?.subscription?.data?.plan_name === 'Free' || props?.subscription?.data?.status !== "Active" || props?.subscription?.data?.status === "trial_expired")
            ? 'title-container title-container-updated header_fixed'
            : 'title-container header_fixed'
      }
    >
      <ReactTooltip />
      <style>
        {`
        #hn-changelog-widget {
          inset: 50px 50px auto auto !important;
        }
        `}
      </style>
      {props.data.type === 'image' ? (
        <Fragment>
          <div className="title-wrapper">
            <img src={props.data.titleOne} className="img-icon" alt="logo" />
            <div className="title-two">{props.data.titleTwo}</div>
          </div>
          {props.dataInvoice === 'Invoices' ? (
            <div
              className="invoice-select-wrapper filter_section"
              style={{ marginRight: 100 }}
            >
              <p className="saved-filter-text">Saved Filters</p>
              <CommonSelect name="invoice" options={['Basic', 'Premium']} />
            </div>
          ) : null}
        </Fragment>
      ) : (
        <Fragment>
          <div className="welcome-title">
            <div className="title-one main_title">{props.data.titleOne}</div>
            <div className="title-two">{props.data.titleTwo}</div>
          </div>
        </Fragment>
      )}
      {/* {location === 'home' ? ( */}
     
      {  (textshow && ( !parseInt(localStorage?.getItem('admin_verification_status')) || props?.subscription?.data?.plan_name === 'Free' || props?.subscription?.data?.status !== "Active" || props?.subscription?.data?.status === "trial_expired") && showWarn?.includes(location) )&& <>
        <div
          className="upgrade-bar"
          style={{
            width: '100%',
            color: '#fff',
            height: '30px',
            backgroundColor: '#0AC455',
            padding: '5px 0px 0 30px',
            position: 'absolute',
            top: 0,
            display: 'flex',
            justifyContent: 'space-between',
            textAlign:'center'
          }}
        >
          <div style={{ width: "100%" }}>
            { !parseInt(localStorage?.getItem('admin_verification_status')) ?
         
            <p>
                Your account is currently pending activation
                {'  '}
                <span
                  style={{
                    backgroundColor: 'red',
                    padding: '5px',
                    borderRadius: '5px',
                    marginLeft: '10px'
                  }}
                  className="cursor-pointer"
                  onClick={() => {
                    setAddCardModalOpen(!addCardModalOpen)
                  }
                  }
                >
                  Add a card to activate account 
                </span>

                <span style={{ float: 'right', cursor: 'pointer' }} onClick={() => setTextshow(false)}><CloseIcon /></span>
              </p>
            
              :
              props?.subscription?.data?.status === 'in_trial' ? 
           
             <p>
                  Your trial ends in {moment(props?.subscription?.data?.trial_end)?.diff(moment(), 'days')} days
              {'  '}
              <span
                style={{
                  backgroundColor: 'rgb(0, 113, 235)',
                  padding: '5px',
                  borderRadius: '5px',
                  marginLeft: '10px'
                }}
                className="cursor-pointer"
                onClick={() => {
                  window.open(`${process.env.REACT_APP_ROOT_URL}/settings/Billing/Subscription`, 'blank')
                }
                }
              >
                See subscription plans
              </span>
           
              <span style={{ float: 'right', cursor: 'pointer' }} onClick={() => setTextshow(false)}><CloseIcon /></span>
                </p>
               
                 : (props?.subscription?.data?.status !== "Active" || props?.subscription?.data?.status === "trial_expired") ? 
          
                 <p>
                  Your trial has expired
                  {'  '}
                  <span
                    style={{
                      backgroundColor: 'red',
                      padding: '5px',
                      borderRadius: '5px',
                      marginLeft: '10px'
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(`${process.env.REACT_APP_ROOT_URL}/settings/Billing/Subscription`, 'blank')
                    }
                    }
                  >
                    Pick a plan
                  </span>

                  <span style={{ float: 'right', cursor: 'pointer' }} onClick={() => setTextshow(false)}><CloseIcon /></span>
                  </p>    :''}
            {/* <p>
              Install LimeCall Script to start receiving leads and calls from your website visitors
              {'  '}
              <span
                style={{
                  backgroundColor: 'rgb(0, 113, 235)',
                  padding: '5px',
                  borderRadius: '5px',
                  marginLeft: '10px'
                }}
                className="cursor-pointer"
                onClick={() => {

                  analytics.track('Widget Installed')
                  window.open(`${process.env.REACT_APP_ROOT_URL}/settings/installation`, 'blank')
                }
                }
              >
                Install Now
              </span>
              <span
                style={{
                  backgroundColor: 'rgb(0, 113, 235)',
                  padding: '5px',
                  borderRadius: '5px',
                  marginLeft: '10px'
                }}
                className="cursor-pointer"
                onClick={() =>
                  setModel(!model)
                }
              >
                Send Code to Developers
              </span>
              <span style={{ float: 'right', cursor: 'pointer' }} onClick={() => setTextshow(false)}><CloseIcon /></span>
            </p> */}


          <div>
            {' '}
            {upgradeButton && (
              <a
                href="javascript:void(0)"
                style={{ color: '#fff', textDecoration: 'none' }}
                onClick={handleOpenModal}
              >
                Upgrade Now
              </a>
            )}{' '}
      </div>
      
          </div>
        </div>
      </>}
      <div className="home-toggle">
        {showInstallationBanner && (
          <Link
            to={`/settings/installation`}
            data-tip="Redirect to the integration page"
            style={{
              marginRight: '20px',
              verticalAlign: 'top',
              color: 'red',
              fontSize: '16px'
            }}
          >
            Tracking script not installed. Show instructions
          </Link>
        )}
        {freeTitle === 'Your account is currently on Free plan' && (
          <a>
            <span
              style={{
                backgroundColor: 'rgb(0, 113, 235)',
                padding: '5px',
                borderRadius: '5px',
                marginLeft: '5px',
                marginRight: '5px'
              }}
              className="cursor-pointer"
              onClick={() => props?.openModal()}
            >
              Upgrade
            </span>
          </a>
        )}

        <a
          href={`/settings/billing/subscription`}
          target="_blank"
          data-tip="Credits are used for outbound calls & sms"
          style={{
            marginRight: '20px',
            verticalAlign: 'top',
            color: 'green',
            fontSize: '17px'
          }}
        >
          $
          {props?.subscription?.data?.credits_in_dollars
            ? props.subscription.data.credits_in_dollars.toFixed(2)
            : 0}
        </a>

        {p_link && (
          <a
            href={`${p_link}`}
            target="_blank"
            data-tip="Test a live preview of your Limecall widget"
            style={{ marginRight: 20, verticalAlign: 'top' }}
          >
            Preview Widget
          </a>
        )}

        <ToggleSwitch
          onChange={setAvailability}
          checked={userStatus}
          Name="newsletter"
        />
      </div>


      {openCallback && (
        <CallbackModal
          isModalClose={() => {
            setTimeout(() => {
              setOpenCallback(false)
            }, 300)
          }}
          isModalOpen={() => console.log(`open`)}
          open={openCallback}
        // dropDownData={dropDownData || []}
        // leadData={leadAbout}
        // phone_numbber={leadAbout?.phone_number}
        />
      )}
      {/* {openSchedule && (
        <ScheduleModal
          isModalClose={() => {
            setTimeout(() => {
              setOpenSchedule(false)
            }, 300)
          }}
          open={openSchedule}
        // dropDownData={dropDownData || []}
        // leadData={leadAbout}
        // phone_numbber={leadAbout?.phone_number}
        />
      )} */}

      <div style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
        {/* {upgradeNeeded && (
              <div
              onClick={handleOpenModal}
              style={{ display: 'block', position: 'relative' }}
            >
              <Link className="home-card-button sky-blue" to="#">
                Upgrade
              </Link>
            </div>

          ) }          */}
        
        <Button onMouseLeave={() => setOpen(null)}  onMouseEnter={(e) => setOpen(e.currentTarget)}
            style={{marginLeft:"10px", marginRight:'30px'}} variant="contained" onClick={() => openDialer()} startIcon={<WifiCallingIcon />}>
                Dialer
              </Button>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none'
            }}
            open={!!open}
            anchorEl={open}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={() => setOpen(null)}
            disableRestoreFocus
          >
            <div style={{ marginTop:'5px',padding: '15px', width: '300px', background:'#0071eb',color:'#fff'}}>
              <div style={{display:'flex', justifyContent:'space-between'}}>
              <h5 style={{fontWeight:600}}>Get ready for the call</h5>
            </div>
              <p> Select and test your microphone and speakers before making a call</p>
           
            </div>
          </Popover>
        <a
          onClick={() => callbackDialer()}
          data-tip="Open Callback"
          style={{
            verticalAlign: 'top'
          }}
        >
          <img
            style={{ width: '30px', marginTop: '-5px', marginRight: 10,marginLeft:10  }}
            src={callbackIcon}
          />
        </a>
        {/* <a
                  onClick={() => scheduleModel()}
                  data-tip="Schedule Meeting"
                  style={{
                    marginLeft: 5,
                    marginRight: 15,
                    verticalAlign: 'top'
                  }}
                >
                  <img
                    style={{ width: '35px', marginTop: '-5px' }}
                    src={scheduleIcon}
                  />
                </a> */}
        <a
          href="javascript:void(0)"
          data-tip="Send SMS & MMS"
          onClick={() => setOpenSms(true)}
        >
          <i
            className="fa fa-envelope"
            aria-setsize="20"
            style={{ color: '#2185d0', fontSize: 21, marginRight: 10 ,marginLeft:10}}
            aria-hidden="true"
          ></i>{' '}
       
        </a>
        <a
          href="javascript:void(0)"
          data-tip="Product update"
          onClick={() => window?.open('https://portal.leanbe.ai/limecall')}
        >
            <i
className="fa fa-comment"
            aria-setsize="20"
            style={{ color: '#2185d0', fontSize: 21, marginRight: 10 ,marginLeft:10}}
            aria-hidden="true"
          ></i>{' '}
         
        </a>

        {/* <div onClick={()=> xyz(window, document, 'leanbe', '63e0917d-1682-4c04-8f2a-4585c0b46297')}>
        </div> */}

   
        {/* <a onClick={() => 
        {
          const box = document.getElementsByClassName('SGBF-widget-container')?.[0];
          box.style.visibility = 'visible';
}
          } class="SGBF-open-657c42404c8577aecf871b1f">
        
        <InfoIcon />
          </a> */}
        {/* <div>
            <i
              id="changelogWidgetID"
              data-tip="Update logs"
              className="fa fa-info-circle"
              aria-setsize="20"
              style={{ color: '#2185d0', fontSize: 21, marginLeft: 10 }}
              aria-hidden="true"
            ></i>
          </div> */}
        <div style={{marginRight:'10px'}}>
          <NotificationModal name="Notification"  />

          <SendMessageModal isModalClose={isModalClose} open={openSms} />
        </div>
        <div className="menu-link">
          <div className="icon-wrapper">
            <ProfileModal name="Profile" />
          </div>
          {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Profile</p>
            </div> */}
        </div>
      </div>

      {model &&
        <EmailSend script={script} open={model} onClose={() => setModel(!model)} />

      }
      <CommonAddCardModal
        open={addCardModalOpen}
        onCloseAddCardModal={() => setAddCardModalOpen(!addCardModalOpen)}
        handleCreditCardInfo={handleCreditCardInfo}
        creditCardDetailsErrors={
          dataModal.creditCardDetailsErrors
        }
        errorMsg={errorMsg}
        addCreditCardLoader={addCreditCardLoader}
        onAddCreditCard={onAddCreditCard}
        updateCard={updateCard}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  widget: state.home.widget,
  subscription: state.home.subscription,
  notification: state.home.notification?.data,
  dialerStatus: state.dialer.currentStatus
})

export default connect(mapStateToProps, {
  getWidget,
  getSubscription,
  getDialer
})(Title)

/* eslint-disable no-unused-expressions */
import React, { Component, useEffect, useState } from 'react'
import { Button, Image, Label, Modal } from 'semantic-ui-react'
import {
  Menu,
  Tab,
  Icon,
  Dropdown,
  Input,
  TextArea,
  Sidebar,
  Segment,
  Popup,
  Accordion,
  Progress
} from 'semantic-ui-react'
import Pusher from 'pusher-js'
import moment from 'moment'
import CallIcon from '@mui/icons-material/Call';

import Title from '../../../common/Title'

import CommonButtons from '../../../common/CommonButtons'
import CommonSelect from '../../../common/CommonSelect'
import CommonInput from '../../../common/CommonInput'

import axios from 'axios'

import { CommonNotify } from '../../../common/CommonNotify'

import leadLogo from '../../../assets/images/lead-logo.svg'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  IconButton,
  ListItem,
  Popover,
  Rating,
  TablePagination,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import DraftsIcon from '@mui/icons-material/Drafts'
import SendIcon from '@mui/icons-material/Send'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarBorder from '@mui/icons-material/StarBorder'
import {
  AccountCircle,
  AlternateEmail,
  Delete,
  Group,
  MarkChatRead,
  MoreHoriz,
  NoAccounts,
  NotificationsPaused
} from '@mui/icons-material'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import BasicMenu from '../../../common/BasicMenu'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getProfile } from '../../../actions/settings'
import { getConversations } from '../../../actions/conversation'
import Chat from './chat'
import { getMessageClass, getMessageSymbole, isToday } from '../utils'
import Contact from './contact'
import SendMessageModal from './SendMessageModal'
import {
  endpoints,
  useGetConversationsQuery
} from '../../../features/api/apiSlice'
import { getNumbers } from '../../../actions/numbers'
import NoConversations from './NoConversations'
import relax from '../../../assets/images/relax.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const emptyObg = {
  first_name: '',
  last_name: '',
  company: '',
  type: '',
  email: '',
  phone_number: '',
  country: '',
  city: ''
}

const searchDetail = {
  name: '',
  id: 0
}

const Content = ({
  allConversations,
  getConversations,
  profile,
  getProfile,
  members,
  membersTeam,
  numbers,
  getNumbers,
  subScription

}) => {
  const res = useGetConversationsQuery(page)
  const state = useSelector(state => state)
  const [allActiveConversations, setAllActiveConversations] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [conversations, setConversations] = useState([])
  const [sActiveConversations, setSActiveConversations] = useState([])
  const [dActiveConversations, setDActiveConversations] = useState([])
  const [activeConvoId, setActiveConvoId] = useState(null)
  const [message, setMessage] = useState([])
  const [sort, setSort] = useState("Newest")
  const [isModalMessageOpen, setIsModalMessageOpen] = useState(false)
  const [agentCode, setAgentCode] = useState(null)
  const [activeTab, setActiveTab] = useState('inbox')
  const [filter, setFilter] = useState('all')
  const [selectedValueNumber, setSelectedValueNumber] = useState('All')
  const [selectedTeam, setSelectedTeam] = useState('All')
  const [selectedMember, setSelectedMember] = useState('All')
  const [membersList, setMemberList] = useState([])
  const [numList, setNumList] = useState([])
  const [selectedIndex, setSelectedIndex] = useState([])
  const [collapse, setCollapse] = useState({
    openAssigned: false,
    openChanells: true,
    openMembers: false
  })
  const [page, setPage] = React.useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [response, setResponse] = useState({})
  const [activeChannel, setActiveChannel] = useState('')
  const dispatch = useDispatch()
  const [moreOpen, setMoreOpen] = useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  useEffect(() => {
    setMoreOpen('')
  }, [activeChannel])
  useEffect(() => {
    getConversations(page + 1)
    dispatch(endpoints.getConversations.initiate(page + 1))
    setActiveConvoId(null)
  }, [page])

  /*  useEffect(() => {
    const result = endpoints.getConversations.select(page + 1)(state)
    if (result.isSuccess) {
    }
  }, [state]) */

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onCopyText = async jsCode => {
    try {
      await navigator.clipboard.writeText(jsCode)
      CommonNotify('Copied!', 'success')
    } catch (err) {
      CommonNotify('Failed to copy!')
    }
  }

  useEffect(() => {
    if (numbers) {
      setNumList(
        numbers.data.map((item, index) => {
          return {
            id: item?.id,
            text: item.phone_number,
            symbole: item.symbole,
            value: item.phone_number,
            key: index,
            name: item.name,
            friendlyName: item?.friendly_name
          }
        })
      )
    } else {
      getNumbers()
    }
  }, [numbers])

  useEffect(() => {
    if (allConversations) {
      handleFilters()
    } else {
      getConversations(page + 1)
    }
  }, [allConversations])

  useEffect(() => {
    // setActiveIndex(0)
  }, [])

  const getConvo = async (id) => {


    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${id}`
    const res = await axios.get(url)
    setResponse((prev) => ({ ...prev, [id]: res }))
    return true
  }

  useEffect(() => {

    if (!activeConvoId) {
      const data1 = conversations?.map((data) => {
        return getConvo(data?.id)


      })

      if (conversations?.length === data1?.length) {
        setActiveConvoId(conversations[0]?.id)
      }
    }
    setAllActiveConversations(
      conversations?.filter(item => {
        if (item.state === 'active') {
          return item
        }
      })?.sort((a, b) => sort === "Newest" ? new Date(b.last_message?.created_at || b?.created_at) - new Date(a.last_message?.created_at || a?.created_at) : new Date(a.last_message?.created_at || a?.created_at) - new Date(b.last_message?.created_at || b?.created_at))
    )
    setSActiveConversations(
      conversations?.filter(item => {
        if (item.state === 'snoozed') {
          return item
        }
      })?.sort((a, b) => sort === "Newest" ? new Date(b.last_message?.created_at || b?.created_at) - new Date(a.last_message?.created_at || a?.created_at) : new Date(a.last_message?.created_at || a?.created_at) - new Date(b.last_message?.created_at || b?.created_at))
    )
    setDActiveConversations(
      conversations?.filter(item => {
        if (item.state === 'closed') {
          return item
        }
      })?.sort((a, b) => sort === "Newest" ? new Date(b.last_message?.created_at || b?.created_at) - new Date(a.last_message?.created_at || a?.created_at) : new Date(a.last_message?.created_at || a?.created_at) - new Date(b.last_message?.created_at || b?.created_at))
    )
  }, [conversations, sort])

  useEffect(() => {
    if (profile) {
      setAgentCode(profile?.data?.personal_link?.split('me/').pop())
    } else {
      getProfile()
    }
  }, [profile])

  useEffect(() => {
    handleFilters()
    setActiveConvoId(null)
  }, [filter, selectedValueNumber])

  useEffect(() => {
    processList()
  }, [members, membersTeam])

  const title = {
    type: 'image',
    titleOne: leadLogo,
    titleTwo: 'Conversations'
  }

  const handleClickAssigned = type => {
    setCollapse(current => ({ ...current, [type]: !collapse[type] }))
  }

  const processList = () => {
    const allList = [...members]

    const processList = allList.map(item => {
      return {
        key: item.first_name?.length ? item.id : item.id + '-team',
        id: item.first_name?.length ? item.id : item.id + '-team',
        value: item.id,
        display: item.first_name?.length
          ? item.first_name
          : item.name + ' team',
        text: item.first_name?.length ? item.first_name : item.name + ' team'
      }
    })
    const loggedInUser = localStorage.getItem('id')
    const newList = processList?.filter(item => Number(item.id))
    setMemberList(newList)
    // setMemberList(processList)
  }

  const handleTabClick = activeIndex => {
    setActiveIndex(activeIndex)
    switch (activeIndex) {
      case 0:
        setActiveTab('inbox')
        setActiveConvoId(allActiveConversations[0]?.id)
        break
      case 1:
        setActiveTab('snooze')
        setActiveConvoId(sActiveConversations[0]?.id)
        break
      case 2:
        setActiveConvoId(dActiveConversations[0]?.id)
        setActiveTab('closed')
        break
      default:
        setActiveConvoId(allActiveConversations[0]?.id)
        setActiveTab('inbox')
        break
    }
  }






  const handleFilterAssign = data => {
    if (data === 'All') {
      setSelectedMember('All')
      setSelectedTeam('All')
      setConversations(
        allConversations.conversations?.filter(
          el =>
            el?.source === selectedValueNumber || selectedValueNumber === 'All'
        )
      )
    } else {
      setSelectedMember(data.id)
      setSelectedTeam(data.id)
      setConversations(
        allConversations.conversations?.filter(
          el =>
            el.owner_id === data.id &&
            (el?.source === selectedValueNumber ||
              selectedValueNumber === 'All')
        )
      )
    }
  }

  const handleFilters = () => {
    const agentId = parseInt(localStorage.getItem('id'))
    switch (filter) {
      case 'all':
        setConversations(
          allConversations.conversations?.filter(
            el =>
              (el?.source === selectedValueNumber ||
                selectedValueNumber === 'All') &&
              (el.owner_id === selectedTeam || selectedTeam === 'All')
          )
        )
        setSelectedIndex(2)
        break
      case 'inbox':
        setConversations(
          allConversations.conversations?.filter(
            el =>
              el.owner_id === agentId &&
              (el?.source === selectedValueNumber ||
                selectedValueNumber === 'All') &&
              (el.owner_id === selectedTeam || selectedTeam === 'All')
          )
        )
        setSelectedIndex(0)
        break
      case 'unassigned':
        setConversations(
          allConversations.conversations?.filter(
            el =>
              !el.owner_id &&
              (el?.source === selectedValueNumber ||
                selectedValueNumber === 'All') &&
              (el.owner_id === selectedTeam || selectedTeam === 'All')
          )
        )
        setSelectedIndex(3)
        break
      case 'mentions':
        setConversations(
          allConversations.conversations?.filter(
            el =>
              el?.mentions?.length > 0 &&
              (el?.source === selectedValueNumber ||
                selectedValueNumber === 'All') &&
              (el.owner_id === selectedTeam || selectedTeam === 'All')
          )
        )
        setSelectedIndex(1)
        break

      default:
        setConversations(
          allConversations.conversations?.filter(
            el =>
              (el?.source === selectedValueNumber ||
                selectedValueNumber === 'All') &&
              (el.owner_id === selectedTeam || selectedTeam === 'All')
          )
        )
        setSelectedIndex(2)
        break
    }
  }


  useEffect(() => {

    const data = allActiveConversations?.filter((obj) => obj?.unread_messages > 0)
    if (data?.length > 0) {
      localStorage?.setItem('unread_message', data?.length)
    }

  }, [allActiveConversations])
  const messages = [
    {
      menuItem: `Open (${allConversations?.open})`,
      render: () => (
        <div className="hover-item">
          <Menu fluid vertical tabular>
            {allActiveConversations?.length ? (
              allActiveConversations?.map((item, i) => {
                return (
                  <Menu.Item
                    active={activeConvoId === item.id}
                    onClick={() => setActiveConvoId(item.id)}
                    key={`${item.id}-${i}`}
                    id={item.id}
                  >
                    <div className="message-holder">
                      <div className={getMessageClass(item.participant)}>
                        {getMessageSymbole(item.participant)}
                      </div>
                      <div className="message-content">
                        <div
                          className="message-numdate"
                          style={{ fontSize: 14, fontWeight: 900 }}
                        >
                          <p style={{ fontSize: 14 }}>
                            {item?.contact?.name || item?.participant?.[0]?.number}
                          </p>
                          <p className="message-date">
                            {item.last_message?.created_at ? isToday(new Date(item.last_message?.created_at))
                              ? moment(item?.last_message?.created_at).format(
                                'LT'
                              )
                              : moment(item?.last_message?.created_at).format(
                                'MMM DD'
                              ) :
                              isToday(new Date(item?.created_at))
                                ? moment(item?.created_at).format(
                                  'LT'
                                )
                                : moment(item?.created_at).format(
                                  'MMM DD'
                                )
                            }
                          </p>
                        </div>
                        <Box display="flex" justifyContent="space-between">
                          <span style={{
                            overflow: 'hidden',
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}>{item.last_message?.message_type === 'Log' ? (item.last_message?.type === "Outgoing call" ? 'You missed call' : item.last_message?.type === "Incoming call") ? 'Missed your call' : 'Call ended' : item.last_message?.body}</span>
                          {item.unread_messages > 0 && (
                            <span
                              style={{
                                backgroundColor: 'rgb(33, 133, 208)',
                                color: 'white',
                                padding: '5px',
                                borderRadius: '20%',
                                height: '10px',
                                overflow: 'hidden',
                              }}
                            >
                              {item.unread_messages}
                            </span>
                          )}
                        </Box>
                      </div>
                    </div>
                  </Menu.Item>
                )
              })
            ) : (
              <NoConversations />
            )}
          </Menu>
        </div>
      )
    },
    {
      menuItem: `Snooze (${allConversations?.snoozed})`,
      render: () => (
        <div>
          <Menu fluid vertical tabular>
            {sActiveConversations?.length ? (
              sActiveConversations?.map((item, i) => {
                return (
                  <Menu.Item
                    active={activeConvoId === item.id}
                    onClick={() => setActiveConvoId(item.id)}
                    key={`${item.id}-${i}`}
                    id={`${item.id}-${i}`}
                  >
                    <div className="message-holder">
                      <div className={getMessageClass(item.participant)}>
                        {getMessageSymbole(item.participant)}
                      </div>
                      <div className="message-content">
                        <div
                          className="message-numdate"
                          style={{ fontSize: 14 }}
                        >
                          <p style={{ fontSize: 14 }}>
                            {item?.contact?.name || item?.participant?.[0]?.number}
                          </p>

                          <p className="message-date">
                            {isToday(new Date(item.last_message?.created_at))
                              ? moment(item.last_message?.created_at).format(
                                'LT'
                              )
                              : moment(item.last_message?.created_at).format(
                                'MMM DD'
                              )}
                          </p>
                        </div>
                        <Box display="flex" justifyContent="space-between">
                          <span style={{
                            overflow: 'hidden',
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}>{item.last_message?.message_type === 'Log' ? (item.last_message?.type === "Outgoing call" ? 'You missed call' : item.last_message?.type === "Incoming call") ? 'Missed your call' : 'Call ended' : item.last_message?.body}</span>
                          {item.unread_messages > 0 && (
                            <span
                              style={{
                                backgroundColor: 'rgb(33, 133, 208)',
                                color: 'white',
                                padding: '5px',
                                borderRadius: '20%'
                              }}
                            >
                              {item.unread_messages}
                            </span>
                          )}
                        </Box>
                      </div>
                    </div>
                  </Menu.Item>
                )
              })
            ) : (
              <NoConversations />
            )}
          </Menu>
        </div>
      )
    },
    {
      menuItem: `Closed (${allConversations?.closed})`,
      render: () => (
        <div>
          <Menu fluid vertical tabular>
            {dActiveConversations?.length ? (
              dActiveConversations?.map((item, i) => {
                return (
                  <Menu.Item
                    active={activeConvoId === item.id}
                    onClick={() => setActiveConvoId(item.id)}
                    key={`${item.id}-${i}`}
                    id={`${item.id}-${i}`}
                  >
                    <div className="message-holder">
                      <div className={getMessageClass(item.participant)}>
                        {getMessageSymbole(item.participant)}
                      </div>
                      <div className="message-content">
                        <div
                          className="message-numdate"
                          style={{ fontSize: 14 }}
                        >
                          <p style={{ fontSize: 14 }}>
                            {item?.contact?.name || item?.participant?.[0]?.number}
                          </p>
                          <p className="message-date">
                            {isToday(new Date(item.last_message?.created_at))
                              ? moment(item.last_message?.created_at).format(
                                'LT'
                              )
                              : moment(item.last_message?.created_at).format(
                                'MMM DD'
                              )}
                          </p>
                        </div>
                        <Box display="flex" justifyContent="space-between">
                          <span style={{
                            overflow: 'hidden',
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}>{item.last_message?.message_type === 'Log' ? (item.last_message?.type === "Outgoing call" ? 'You missed call' : item.last_message?.type === "Incoming call") ? 'Missed your call' : 'Call ended' : item.last_message?.body}</span>
                          {item.unread_messages > 0 && (
                            <span
                              style={{
                                backgroundColor: 'rgb(33, 133, 208)',
                                color: 'white',
                                padding: '5px',
                                borderRadius: '20%'
                              }}
                            >
                              {item.unread_messages}
                            </span>
                          )}
                        </Box>
                      </div>
                    </div>
                  </Menu.Item>
                )
              })
            ) : (
              <NoConversations />
            )}
          </Menu>
        </div>
      )
    }
  ]


  const sendMessage = async (message, sid) => {

    const data = {
      body: message,
      sid: sid,
      agent_code: agentCode,
      whatsapp: 0
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/agent-push-message`
    axios
      .post(url, data)
      .then(res => {
        getConvo(res.data.data.conversation_id)
        setActiveConvoId(res.data.data.conversation_id)
        getConversations(page + 1)
      })
      .catch(error => {
        CommonNotify('Cant Send message')
      })

  }
  const setupConversation = async sendMsgData => {
    setIsModalMessageOpen(false)
    const widgetScript = localStorage.getItem('widgetScript')

    if (!sendMsgData.to) {
      return CommonNotify('Number not added')
    }

    if (widgetScript === '') {
      return CommonNotify('Widget Not installed / not available')
    }

    const data = {
      friendly_name: sendMsgData.to,
      script_id: widgetScript,
      source: `${sendMsgData?.from}`?.includes('+')
        ? sendMsgData.from
        : `+${sendMsgData.from}`,
      number: sendMsgData?.to?.includes('+')
        ? sendMsgData.to
        : `+${sendMsgData.to}`
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation`
    const urlParticipant = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/participant`
    axios
      .post(url, data)
      .then(res => {
        const dataParticipant = {
          sid: res.data.data.sid,
          number: data.number,
          name: res.data.data.friendly_name,
        }
        sendMessage(sendMsgData?.message, res.data.data.sid)

        axios
          .post(urlParticipant, dataParticipant)
          .then(response => {


          })
          .catch(error => {
            CommonNotify(
              'Cant Add participants, Participant Already exist in another conversation'
            )
          })
      })
      .catch(error => {
        try {
          const convId = error.response.data.errors[0].split('#')[1]
          if (convId) {
            setActiveConvoId(convId, () => setNewMessage(sendMsgData.message))
          }
        } catch (e) {
          CommonNotify('Number Invalid')
        }
      })
  }

const dataLength={
  0:allActiveConversations?.length,
  1:sActiveConversations?.length,
  2:dActiveConversations?.length
}
  const countTable = {
    0: 'open',
    1: 'snoozed',
    2: 'closed'
  }
  return (
    <div className="conversation-container">
      <Title data={title} />

      <div className="widget-banner-container">
        <div className="conversation-content-holder content-left">
          <List
            sx={{ width: '100%', maxWidth: 260, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              onClick={() => {
                setSelectedIndex(0)
                setFilter('inbox')
              }}
              selected={selectedIndex === 0}

            >
              <ListItemIcon style={{ minWidth: "40px" }}>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between">
                    <Typography>Your Inbox</Typography>
                    <Typography>{allConversations?.[`${countTable?.[0]}inbox`]}</Typography>
                  </Box>
                }
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                setSelectedIndex(1)
                setFilter('mentions')
              }}
              selected={selectedIndex === 1}
            >
              <ListItemIcon style={{ minWidth: "40px" }}>
                <AlternateEmail />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between">
                    <Typography>Mentions</Typography>
                    <Typography>{allConversations?.[`${countTable?.[0]}mentions`]}</Typography>

                  </Box>
                }
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                setSelectedIndex(2)
                setFilter('all')
              }}
              selected={selectedIndex === 2}
            >
              <ListItemIcon style={{ minWidth: "40px" }}>
                <Group />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between">
                    <Typography>All</Typography>
                                        <Typography>{allConversations?.[`${countTable?.[0]}all`]}</Typography>

                  </Box>
                }
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                setSelectedIndex(3)
                setFilter('unassigned')
              }}
              selected={selectedIndex === 3}
            >
              <ListItemIcon style={{ minWidth: "40px" }}>
                <NoAccounts />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between">
                    <Typography>Unassigned</Typography>
                    <Typography>{allConversations?.[`${countTable?.[0]}unassigned`]}</Typography>
                  </Box>
                }
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                handleClickAssigned('openMembers')
              }}
            >
              <ListItemText primary="Members" />
              {collapse.openMembers ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={collapse.openMembers} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleFilterAssign('All')}
                  selected={selectedMember === 'All'}
                >
                  <ListItemText
                    primary={
                      <Box display="flex" justifyContent="space-between">
                        <Typography>All Members</Typography>
                        <Typography>{allConversations?.members?.all_members}</Typography>
                      </Box>
                    }
                  />
                </ListItemButton>
                {members?.filter(item => item?.verified === 1)?.map((item, i) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleFilterAssign(item)}
                    selected={selectedMember === item.id}
                  >
                    <ListItemText
                      primary={
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            {item.first_name}{" "}{item.last_name}
                          </Typography>
                          <Typography>
                            {
                              allConversations?.members?.[`${item.first_name} ${item.last_name}`]
                            }
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            <ListItemButton
              onClick={() => {
                handleClickAssigned('openAssigned')
              }}
            >
              <ListItemText primary="Teams" />
              {collapse.openAssigned ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={collapse.openAssigned} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleFilterAssign('All')}
                  selected={selectedTeam === 'All'}
                >
                  <ListItemText
                    primary={
                      <Box display="flex" justifyContent="space-between">
                        <Typography>All Teams</Typography>
                        <Typography>{allConversations?.teams?.all_teams}</Typography>
                      </Box>
                    }
                  />
                </ListItemButton>
                {membersTeam?.map((item, i) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleFilterAssign(item)}
                    selected={selectedTeam === item.id}
                  >
                    <ListItemText
                      primary={
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            {item.name?.replace('Team', '')}
                          </Typography>
                          <Typography>
                            {
                              allConversations?.teams?.[item.name]
                            }
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            <ListItemButton onClick={() => handleClickAssigned('openChanells')}>
              <ListItemText primary="Channels" />
              {collapse.openChanells ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={collapse.openChanells} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  selected={selectedValueNumber === 'All'}
                  onClick={e => setSelectedValueNumber('All')}
                >
                  <ListItemText
                    primary={
                      <Box display="flex" justifyContent="space-between">
                        <Typography>All Channels</Typography>
                        <Typography>{typeof(allConversations?.sources)==='object' && Object?.values(allConversations?.sources)?.reduce((accumulator, value) => {
                          return accumulator + value;
                        }, 0)}</Typography>
                      </Box>
                    }
                  />
                </ListItemButton>
                {numList?.map((item, i) => (
                  <ListItemButton
                    sx={{ pl: 4, alignItems: 'start' }}
                    selected={selectedValueNumber === item.value}
                    onClick={e => setSelectedValueNumber(item.value)}
                  >
                    <div className="d-flex align-items-center">
                      <ListItemIcon style={{ minWidth: "40px" }}>
                        <CallIcon />
                      </ListItemIcon>

                      <ListItemText

                        primary={
                          <Box display="flex" justifyContent="space-between">
                            {item.symbole && (
                              <Typography>{item.symbole}</Typography>
                            )}
                            <Typography>{item?.friendlyName}</Typography>

                          </Box>
                        }

                        secondary={
                          <Box display="flex" justifyContent="space-between">
                            <Typography>{`${item.value} (${allConversations?.sources?.[item?.value
                            ]})`}

                            </Typography>
                          </Box>
                        }
                      />
                    </div>
                    {

                      activeChannel === item.value ?

                        <Typography style={{
                          textAlign: 'center',
                          marginLeft: 'auto',
                          marginRight: '0px',
                          display: 'flex',
                          position: 'relative'
                        }}>


                          <IconButton className='p-0' >
                            <Icon
                              name="copy"
                              onClick={() =>
                                onCopyText(item.value)
                              }
                            />
                          </IconButton>
                          <MoreHorizIcon onClick={(e) => setMoreOpen(e.currentTarget)} />
                          {moreOpen &&
                            <Menu
                              onClose={() => setMoreOpen('')}
                              style={{ position: 'absolute', top: 18 }}
                              anchorEl={moreOpen}
                            >

                              <Menu.Item
                                active={moreOpen}

                              >
                                <a href={`/numbers/${item?.id}`}>Manage</a>
                              </Menu.Item>

                            </Menu>
                          }
                        </Typography>

                        :
                        /*   allConversations.conversations?.filter(
                            el => el?.source === item.value && item?.unread_messages > 0
                          )?.length > 0 &&  */

                        <Typography


                          {...allConversations.conversations?.filter(
                            el => el?.source === item.value && item?.unread_messages > 0
                          )?.length > 0 ? ({
                            style: {
                              width: '22px',
                              textAlign: 'center',
                              height: '22px',
                              borderRadius: '50%',
                              backgroundColor: '#f00',
                              marginLeft: 'auto',
                              color: '#ffffff',
                              marginRight: '0px'
                            }
                          })


                            : ({
                              style: {
                                width: '22px',
                                marginLeft: 'auto',
                                marginRight: '0px',
                                textAlign: 'center',
                                height: '22px',
                                borderRadius: '50%',
                              }
                            })
                          }

                          onMouseEnter={() => setActiveChannel(item.value)}
                        >
                          {
                            allConversations.conversations?.filter(
                              el => el?.source === item.value && item?.unread_messages > 0
                            )?.length > 0 ? allConversations.conversations?.filter(
                              el => el?.source === item.value && item?.unread_messages > 0
                            )?.length : '  '
                          }
                        </Typography>


                    }

                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </List>
          <div className="conversation-content">
            <div className="conversation-content-holder ">
              <div className="content-header">
                <div className="content-text">


                  <Dropdown
                    name="from"
                    // placeholder="Newest"
                    value={sort}
                    className='mr-auto'
                    selection
                    options={[{ key: "Newest", value: "Newest", text: "Newest" }, { key: "Oldest", value: "Oldest", text: "Oldest" }]}
                    onChange={(e, data) =>
                      setSort(data?.value)
                    }
                  />
                  <Tooltip title="Start a new conversation">
                    <div className="content-icons">


                      <Icon
                        name="comment alternate outline"
                        onClick={() => numList?.length > 0 ? setIsModalMessageOpen(true) : null}
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="justify-menu" style={{ minHeight: '650px' }}>
                  <Tab
                    menu={{ secondary: true, pointing: true }}
                    panes={messages}
                    activeIndex={activeIndex}
                    onTabChange={(e, data) => handleTabClick(data.activeIndex)}
                  />
                </div>
                {dataLength?.[activeIndex] > 10 && (
                  <TablePagination
                    component="div"
                    count={allConversations?.total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={allConversations.per_page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </div>
            </div>
          </div>
          <Sidebar.Pushable as={Segment} className="main-segment">
            <div className="conversation-content-messages-info-holder">
              <Sidebar
                visible={true}
                direction="center"
                animation="overlay"
                className="sidebar-contact"
              >
                {activeConvoId && (
                  <Contact
                    res={response?.[activeConvoId]}
                    id={activeConvoId}
                    getConversations={() => getConversations(page + 1)}
                    agentCode={agentCode}
                    activeTab={activeTab}
                    membersList={membersList}
                    setActiveConvoId={setActiveConvoId}
                    getConvo={getConvo}
                  />
                )}
              </Sidebar>
              <Sidebar.Pusher>
                {activeConvoId ? (
                  <Chat
                    res={response?.[activeConvoId]}
                    id={activeConvoId}
                    getConversations={() => getConversations(page + 1)}
                    agentCode={agentCode}
                    activeTab={activeTab}
                    membersList={membersList}
                    newMessage={newMessage}
                    getConvo={getConvo}
                  />
                ) : (
                  <Segment>
                    <div

                      className='no-conversation'
                    >
                      {' '}
                      <div>

                        <img src={relax} alt='' />
                        <Typography variant='h6' component='h6'> No open conversation here </Typography>
                        <Typography variant='body1' component='p'> You're all caught up </Typography>
                      </div>
                    </div>
                  </Segment>
                )}
              </Sidebar.Pusher>
            </div>
          </Sidebar.Pushable>
          {(subScription?.status === 'in_trial' || subScription?.status === 'Active') && isModalMessageOpen && (
            <SendMessageModal
              open={isModalMessageOpen}
              onClose={() => setIsModalMessageOpen(false)}
              setupConversation={setupConversation}
            />
          )}
        </div>
      </div>

    </div>

  )
}

const mapStateToProps = state => ({
  profile: state.settings.profile,
  numbers: state.numbers.numbers,
  allConversations: state.conversation.allConversations,
  subScription: state.home?.subscription?.data
})

export default connect(mapStateToProps, {
  getProfile,
  getConversations,
  getNumbers
})(Content)

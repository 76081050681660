import React, { memo, useEffect, useRef, useState } from 'react'
import { Modal } from 'semantic-ui-react'

import callLogPic from '../assets/images/Icons-nav-help.svg'
import callLogActive from '../assets/images/Icons-nav-help.svg'



import helpActive from '../assets/images/Dashboard-14.png'
import helpinActive from '../assets/images/Asset 19.svg'
import knowledgeInactive from '../assets/images/Dashboard-15.png'
import writeInactive from '../assets/images/Dashboard-16.png'
import writeActive from '../assets/images/Asset 24.svg'

import callbackInactive from '../assets/images/Dashboard-17.png'
import playStore from '../assets/images/play-store.png'
import callbackActive from '../assets/images/Asset 26.svg'
import ShareLinkModal from './ShareLinkModal'
import ReferOpenModal from './ReferOpenModal'
import PeopleIcon from '@mui/icons-material/People';

const HoursModal = () => {
  const [open, setOpen] = useState(false)

  const [help, setHelp] = useState(helpinActive)
  const [help2, setHelp2] = useState(helpinActive)
  const [knowledge, setKnowledge] = useState(knowledgeInactive)
  const [write, setWrite] = useState(writeInactive)
  const [callback, setCallBack] = useState(callbackInactive)
  const [shareModel, setShareModel] = useState(false)
  const [referModel, setReferModel] = useState(false)

  const close = () => setOpen(false)

  const onCloseModal = () => {
    const profile = document.querySelector('.profile-wrapper')
    if (profile) {
      profile.parentNode.parentNode.parentNode.click()
    }
  }

  const handleModalOpen = () => {
    setOpen(!open)
    onCloseModal()
  }

  // const handleHelpUsLink = () => {
  //   window.Intercom('update', {
  //     "hide_default_launcher": false
  //   });
  //   window.Intercom('show')
  // }

  const closeModal=()=>{
    setReferModel(!referModel)
  }

  return (
    <div className="menu-link" onClick={handleModalOpen} >
      <div className="icon-wrapper">
    <div className="miniModal" >
      <style>
        {open && (`.ui.dimmer {
        background-color: transparent;
      }`)}
      </style>
      <Modal
        className="mini-modal nav_mini_profile"
        size="mini"
        dimmer={false}
        open={open}
        onClose={close}
        trigger={
          <img
            src={open === false ? callLogPic : callLogActive}
          
            
            alt="img"
          />
        }
      >
        <Modal.Content>
          <div className="hours-call-wrapper">
            <div className="hours-call-holder">
              {/* <a href="#" onClick={handleHelpUsLink}> */}
              <a href="http://support.limecall.com/" target="_blank">
                <img
                  src={help}
                  onMouseEnter={e => {
                    setHelp(helpActive)
                  }}
                  onMouseOut={() => {
                    setHelp(helpinActive)
                  }}

                  alt="help"
                />
                <p
                  className="hours-title"
                  onMouseEnter={e => {
                    setHelp(helpActive)
                  }}
                  onMouseOut={() => {
                    setHelp(helpinActive)
                  }}
                >
                  Get help from us
                </p>
              </a>
            </div>

            <div className="hours-call-holder">
              <a href="http://help.limecall.com/" target="__blank">
                <img
                  src={help2}
                  onMouseEnter={e => {
                    setHelp2(helpActive)
                  }}
                  onMouseOut={() => {
                    setHelp2(helpinActive)
                  }}
                  alt="help"
                />
                <p
                  className="hours-title"
                  onMouseEnter={e => {
                    setHelp2(helpActive)
                  }}
                  onMouseOut={() => {
                    setHelp2(helpinActive)
                  }}
                >
                  Search our Help Center
                </p>
              </a>
            </div>

            <div className="hours-call-holder">
              <a onClick={()=>closeModal(true)} target="__blank">
                <PeopleIcon
                  onMouseEnter={e => {
                    setHelp2(helpActive)
                  }}
                  onMouseOut={() => {
                    setHelp2(helpinActive)
                  }}
                />
                <p
                  className="hours-title"
                  onMouseEnter={e => {
                    setHelp2(helpActive)
                  }}
                  onMouseOut={() => {
                    setHelp2(helpinActive)
                  }}
                >
                  Share Referral and Earn
                </p>
              </a>
            </div>



            <div className="hours-call-holder">
              <a
                href="https://stats.uptimerobot.com/XQYMNu4nNX"
                target="__blank"
              >
                <img
                  src={write}
                  onMouseEnter={e => {
                    setWrite(writeActive)
                  }}
                  onMouseOut={() => {
                    setWrite(writeInactive)
                  }}
                  alt="write"
                />
                <p
                  className="hours-title"
                  onMouseEnter={e => {
                    setWrite(writeActive)
                  }}
                  onMouseOut={() => {
                    setWrite(writeInactive)
                  }}
                >
                  System Status
                </p>
              </a>
            </div>


            <div className="hours-call-holder">
              <a href="https://update.limecall.com/" target="__blank">

                <img src={callback} alt="callback" />
                <p
                  onMouseEnter={e => {
                    setCallBack(callbackActive)
                  }}
                  onMouseOut={() => {
                    setCallBack(callbackInactive)
                  }}
                  className="hours-title"
                >
                  Submit a Feedback
                </p>
              </a>
            </div>
      
            {/* <div className="hours-call-holder">
              <a
                href="https://play.google.com/store/apps/details?id=com.limecall"
                target="__blank"
              >
                <img
                  src={playStore}

                  alt="play-store"
                />
                <p
                  className="hours-title"
                >
                Share meeting link
                </p>
              </a>
            </div> */}
            <div className="hours-call-holder">
              <a
                href="https://play.google.com/store/apps/details?id=com.limecall"
                target="__blank"
              >
                <img
                  src={playStore}

                  alt="play-store"
                />
                <p
                  className="hours-title"
                >
                  Download Mobile App
                </p>
              </a>
            </div>
            <div className="hours-call-holder">
              <a onClick={()=>setShareModel(true)}>

                <img src={callback} alt="callback" />
                <p
                  onMouseEnter={e => {
                    setCallBack(callbackActive)
                  }}
                  onMouseOut={() => {
                    setCallBack(callbackInactive)
                  }}
                  className="hours-title"
                >
                Share meeting link
                </p>
              </a>
            </div>


          </div>
        </Modal.Content>
      </Modal>
      <ShareLinkModal {...{  isModalClose:shareModel,setShareModel
 /*  profile,
  getProfile,
  widget,
  getWidget */}}/>
   {open &&    <ReferOpenModal {...{open:referModel,closeModal:closeModal}}/>}
    </div>
        <span>Help</span>
    </div>
    </div>
  )
}

export default HoursModal

import axios from 'axios'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
//import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2'
import { connect } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import { getDialer, setPhoneNumber } from '../../../actions/dialer'
import { getWidgetCountries } from '../../../actions/settings'
import beep from '../../../assets/sound/beep.mp3'
import { CommonNotify } from '../../../common/CommonNotify'
import CreateCallModal from '../../../common/CreateCallDialerModal'
import { useGetUserIdWithNameQuery } from '../../../features/api/apiSlice'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
// import microphone from "../../../assets/images/microphone.png"
import CountryData from '../../data/countryCode.json'
import DialpadIcon from '@mui/icons-material/Dialpad';
import MicOffIcon from '@mui/icons-material/MicOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
import './styles.scss'
import AudioInputOutut from '../../../common/AudioInputOutut'
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment'
const API_URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1`
const apiToken = localStorage.getItem('access_token')

const Dialer = ({
  requestTokenForOutbound,
  setCallStatus,
  getWidgetCountries,
  widgetCountries,
  systemMic,
  setCallData,
  outboundCall,
  runDialer,
  requestOutboundCall,
  dialerStatus,
  hangupCall,
  getDialer,
  always,
  pNumber
}) => {
  const user = useGetUserIdWithNameQuery()
  const [openCall, setOpenCall] = useState(false)
  const [sourceNo, setSourceNo] = useState('')
  const [dropdown, setDropDown] = useState([])
  const [number, setNumber] = useState('+1')
  const [viaNumber, setViaNumber] = useState(null)
  const [callInitiate, setCallInitiate] = useState(false)
  const [disabledCall, setDisabledCall] = useState(false)
  const [micBlocked, setMicBlocked] = useState(true)

  const [errorMessage, setErrorMessage] = useState('')
  const [message, setMessage] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [code, setCode] = useState('+1')
  const [allowedCountries, setAllowedCountries] = useState([])
  const [dropDownData, setDropdownData] = useState([])
  const [callRates, setCallRates] = useState('')
  const [userTime, setUserTime] = useState(null)
  const [dialer, setShowDialer] = useState(true)
  const [audioSetting, setAudioSetting] = useState(false)
  const textPos = useRef(null)

  useEffect(() => {
    if (user.isSuccess) {
      setUserId(user)
    }
  }, [user])

  useEffect(() => {
  
    document.addEventListener('keydown', escFunction, false)
  }, [])

 useEffect(() => {
   if(pNumber){
     setNumber(pNumber)
    }
 }, [pNumber])
 

  useEffect(() => {
    if (dialerStatus) {
      setupDevice()
    }
    checkNumber(dialerStatus)
  }, [dialerStatus])

  useEffect(() => {
    const cCode = '+' + countryCode

    if (!countryCode) return
    if (disabledCall) return
    setNumber(cCode)
  }, [countryCode])

  useEffect(() => {
    resetWidgetCountries(widgetCountries?.data?.allowed_countries)
  }, [widgetCountries])

  useEffect(() => {
    getWidgetCountries()
    navigator.getUserMedia({audio : true}, micEnabled, micDisabled);
  }, [])

  useEffect(() => {
    const {
      error,
      connected,
      disconnected,
      ringing,
      leadId,
      msg,
      errormsg
    } = outboundCall

    if (error) {
      setErrorMessage(errormsg)
      setDisabledCall(true)
      setPhoneNumber()
      return
    }

    if (ringing) {
      setMessage('Ringing ' + number)
    }

    if (connected) {
      setPhoneNumber()
      getDialer(false)
      return
    }

    if (disconnected) {
      setPhoneNumber()
      getDialer(false)
      return
    }
  }, [outboundCall])

  const setUserId = res => {
    const data = []
    const datas = res?.data?.data?.map((number, i) => {
      if (i === 0) {
        setViaNumber(number?.number || number?.phone_number)
      }
      data.push({
        key: number?.name,
        value: number?.number || number?.phone_number,
        text: `${number?.name?.replace('Number', '')} (${number?.number ||
          number?.phone_number})`
      })
    })
    setDropdownData(data)
  }

  const resetWidgetCountries = countries => {
    const allCountry = CountryData
    const allowed = allCountry
      ?.filter(item => {
        if (countries?.includes(item.name)) {
          return item
        }
      })
      .map(item => item.code.toLowerCase())

    setAllowedCountries(allowed)
  }

  const escFunction = event => {
    if (event.key === 'Escape') {
      closeDialer()
    }
  }

  const isModalCallClose = () => {
    setPhoneNumber()
    setOpenCall(false)
  }

  const isModalCallOpen = () => {
    setOpenCall(true)
  }

  const checkNumber = num => {
    if (num === true || num === false) return
    setNumber(num)
  }

  const addNumber = num => {
    new Audio(beep)
    let n = number
    n = n + num
    setNumber(n)
  }

  const removeNumber = () => {
  
    const ext = textPos.current

    // if(ext == -1 ){
    //   let n = number;
    //   n = n.slice(0, -1);
    //   setNumber(n);
    //   return;
    // }

    let n = number
    n = n?.slice(0, -1)
    setNumber(n)
  }

  const executeCall = () => {
    if (disabledCall) {
      CommonNotify(errorMessage ? errorMessage : 'Something went wrong')
      return
    }

    if (number?.length < 12) {
      CommonNotify('Please enter valid number first')
      return
    }

    if (disabledCall) return
    const phoneNumber = number
    callCustomer(phoneNumber)
  }

  const exitCall = () => {
    if (disabledCall) return
    setCallInitiate(false)
    hangupCall(true)
    setPhoneNumber()
    getDialer(false)
  }

  const closeDialer = () => {
    setPhoneNumber()  
    getDialer(false)}


  function setupDevice() {
    // setNumber('+1')
    setCallInitiate(false)

    if (!micBlocked) {
      setErrorMessage('')
      setMessage('')
      setDisabledCall(false)
    }
    requestTokenForOutbound().then(r => {
      runDialer(r.data.data.token)
    })
  }

  const micEnabled = () => {
    // const permissions = navigator.mediaDevices.getUserMedia({audio: !audio, video: false})
    // permissions.then((stream) => {
    //   // alert('accepted the permissions');
    //   // this.setState(((prevState) => {
    //   //   // havePermissions: !prevState.havePermissions
    //   // }));
    // })
    // .catch((err) => {
    //   // this.setState(((prevState) => {
    //   //   havePermissions: false
    //   // }));
    // });
    setMicBlocked(false)
  }
  

  const micDisabled = () => {
    setMicBlocked(true)
    setDisabledCall(true)
  }

  function callCustomer(phoneNumber) {
    if (!viaNumber) {
      CommonNotify('Via number is required.')
      return
    }
    setCallInitiate(true)
    setMessage('Connecting to ' + number)
    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${apiToken}`
    }

    axios
      .post(
        `${API_URL}/customer-browser-calls-create-call`,
        {
          phone_number: phoneNumber,
          country_code: code,
          from: viaNumber
        },
        { headers }
      )
      .then(res => {
        if (res.status == 200) {
          var callId = res.data.data.call_id
          var phoneNumberBE = res.data.data?.phone_number
          setCallRates(res.data.data?.price_per_minute)
          setUserTime(moment(res.data.data?.customer_local_time).format('LT') )

          //return;

          if (!callId) {
            CommonNotify('Cant initiate call, cant fetch lead detail')
            return
          }

          setCallData(callId)

          var params = {
            To: phoneNumberBE,
            callId: callId
          }

          requestOutboundCall(params)
        } else {
          setCallInitiate(false)
        }
      })
      .catch(error => {
        setCallInitiate(false)
        CommonNotify(
          error?.response?.data?.errors?.[0] ||
            error?.response?.data?.message ||
            error?.response?.message ||
            'Cant initiate call, cant fetch lead detail'
        )
      })
  }

  function showError() {
    return null
  }

  function checkDialer() {
    return <>disabledCall ? showError() : showDialer()</>
  }
  function showDialer() {
    return (
      <>
        <Draggable>
          <div className="draggalbe-container-main" id="out-dialer">
            <div className="draggable-content-main">
              <div className="dialpad ">
                <div className="main">
                  {/* {disabledCall ? (
                    // <p id="error">{errorMessage}</p>
                  ) : ( */}
                    <>
                      {' '}
                      {callInitiate ? (
                        <>
                          <p id="connecting">
                            {message} <br />{' '}
                            {callRates ? ` ${callRates}$ / Minute ` : ''} <br />{' '}
                            {userTime
                              ? `Local Date & Time : ${userTime}`
                              : ''}
                          </p>{' '}
                        </>
                      ) : (
                        <></>
                      )}{' '}
                    </>
                  {/* )} */}

                  <ul>
                    {!callInitiate && (
                      <>
                        <Fragment>
                          <div
                            className="input-select-wrapper input-wrapper"
                            style={{
                              marginTop: '0 !important',
                              padding: '40px 20px'
                            }}
                          >
                            <PhoneInput
                              international
                              className="text-color"
                              country={'us'}
                              onlyCountries={allowedCountries}
                              ref={textPos}
                              onChange={(e, data) => {
                                setCode('+' + data?.dialCode)
                                setCountryCode(e)
                              }}
                              value={number}
                            />

                            {always !== true && (
                              <div onClick={closeDialer} className="close-btn">
                                X
                              </div>
                            )}
                          </div>
                        </Fragment>
                        
                     
                      
                      </>
                    )}
               
                       
                       <div className="dailer-icon">
                            {micBlocked ? 
                            <span className='voice-icon'><KeyboardVoiceIcon sx={{fill:'#fff'} } onClick={()=> micEnabled()} /><p>Mute</p></span>:
                            <span className='mic-off-icon'><MicOffIcon sx={{fill:'#fff'} } onClick={()=> micDisabled()}/><p>Unmute</p></span> }
                            <span className='mic-off-icon'><SettingsIcon sx={{fill:'#fff'} } onClick={()=> setAudioSetting(!audioSetting)}/>
                            <p>Setting</p>
                            </span> 
                            <span className='dial-icon'><DialpadIcon sx={{fill:'#fff'} }onClick={()=>setShowDialer(!dialer)} /> <p>Keypad</p></span>
                            <span className='call-end-icon'><CallEndIcon sx={{fill:'#fff'} }onClick={()=>exitCall()} /><p>End</p></span>

                          </div>
                          
                          {dialer &&
                       <> 
                          <li>
                          <div className="pad" onClick={() => addNumber('1')}>
                            <span>1</span>
                          </div>
                        </li>
                        {/* <img onClick={()=>setShowDialer(!dialer)} width="30px" src={nineDot}/> */}
                        <li>
                          <div className="pad" onClick={() => addNumber('2')}>
                            <span>2</span>
                            <span className="sub">abc</span>
                          </div>
                        </li>
                        <li>
                          <div className="pad" onClick={() => addNumber('3')}>
                            <span>3</span>
                            <span className="sub">def</span>
                          </div>
                        </li>
                        <li>
                          <div className="pad" onClick={() => addNumber('4')}>
                            <span>4</span>
                            <span className="sub">ghi</span>
                          </div>
                        </li>
                        <li>
                          <div className="pad" onClick={() => addNumber('5')}>
                            <span>5</span>
                            <span className="sub">jkl</span>
                          </div>
                        </li>
                        <li>
                          <div className="pad" onClick={() => addNumber('6')}>
                            <span>6</span>
                            <span className="sub">mno</span>
                          </div>
                        </li>
                        <li>
                          <div className="pad" onClick={() => addNumber('7')}>
                            <span>7</span>
                            <span className="sub">pqrs</span>
                          </div>
                        </li>
                        <li>
                          <div className="pad" onClick={() => addNumber('8')}>
                            <span>8</span>
                            <span className="sub">tuv</span>
                          </div>
                        </li>
                        <li>
                          <div className="pad" onClick={() => addNumber('9')}>
                            <span>9</span>
                            <span className="sub">wxyz</span>
                          </div>
                        </li>
                        {/* <li><div className="pad"><span className="fa fa-gear" onClick={ () => getDialer(false)}></span> </div></li> */}
                        <li>
                          <div className="pad" onClick={() => addNumber('+')}>
                            <span>+</span>
                          </div>
                        </li>
                        <li>
                          <div className="pad" onClick={() => addNumber('0')}>
                            <span>0</span>
                            <span className="sub"></span>
                          </div>
                        </li>
                        <li>
                          <div className="pad" onClick={()=>removeNumber()}>
                            <span className="fa fa-trash"></span>{' '}
                          </div>
                        </li>
                        </>
  }

                    <li>
                      {!callInitiate &&
                      // ? (
                      //   <>
                      //     {/* <div
                      //       className="dial dialphoneIcon"
                      //       onClick={exitCall}
                      //     >
                      //       <span className="disconnect-call  pulse pulse-animated fa fa-phone">
                      //         <i className="fa fa-phone"></i>{' '}
                      //       </span>
                      //     </div> */}
                      //   </>
                      // ) : (
                          <div
                            className="dial dialphoneIcon"
                            onClick={executeCall}
                          >
                            <span
                              className={
                                disabledCall
                                  ? 'fa fa-phone connect-call disabled-connect-call '
                                  : 'fa fa-phone pulse connect-call'
                              }
                            ></span>
                          </div>
                      // )
                      }
                    </li>
                    <Fragment>
                      <div
                        className="input-select-wrapper input-wrapper"
                        style={{
                          marginTop: '0 !important',
                          padding: '20px 20px'
                        }}
                      >
                        <h4 className="d-block mb-2 text-left text-white">
                          Caller ID
                        </h4>
                        <Dropdown
                          name="from"
                          placeholder="Select Number"
                          selection
                          className="wia-call"
                          value={viaNumber}
                          options={dropDownData}
                          onChange={(e, data) => setViaNumber(data.value)}
                        />
                      </div>
                    </Fragment>
                  </ul>
                  <div style={{ height: '20px' }}></div>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
{audioSetting &&  <AudioInputOutut isModalClose={()=>setAudioSetting(!audioSetting)} open={audioSetting}/>}

        <CreateCallModal
          isModalClose={isModalCallClose}
          open={openCall}
          dropDownData={dropdown}s
          onChangeRequest={setSourceNo}
        />
      </>
    )
  }

  return <>{dialerStatus ? showDialer() : null}</>
}

const mapStateToProps = state => {
  return {
    dialerStatus: state.dialer.currentStatus,
    pNumber: state.dialer.PNumber,
    widgetCountries: state.settings.widgetCountries
  }
}

export default connect(mapStateToProps, { getDialer, getWidgetCountries })(
  Dialer
)

// getwidget

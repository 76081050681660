import axios from 'axios'
import moment from 'moment'
import {
  GET_PROFILE_DATA,
  GET_NOTIFICATIONS_WIDGET,
  GET_NOTIFICATIONS_ACTIONS,
  GET_CALLFORWADING_SETTINGS,
  GET_TEAM_MEMBER,
  GET_WIDGET_DAYS,
  GET_USER_INTEGRATIONS,
  GET_TEAMS,
  GET_TEAM_MEMBERS,
  GET_ALL_TEAMS,
  GET_MEMBER_ROLES,
  GET_CUSTOMER_INVOICES,
  GET_PAYMENT_LOG,
  GET_BILLING_INFO,
  GET_PAYMENT_METHOD,
  GET_WEB_HOOK,
  GET_WIDGET_INSTALL,
  GET_WIDGET_COUNTRIES,
  GET_SMS_PLACEHOLDERS,
  GET_CALLER_ID,
  GET_CHARGE_LOG,
  GET_WIDGET_SETTINGS,
  SET_FLAG
} from './types'
import { logOut } from '../common/ProfileModal'

const w_id = localStorage.getItem('widget_id')
const w_api_key = localStorage.getItem('limecall-widget-api')

const getWidgetId = async () => {
  return new Promise(async (resolve, reject) => {
    const wid = await localStorage.getItem('widget_id')
    if (wid) resolve(wid)
    reject('')
  })
}

// Get Profile Data
export const getProfile = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/profile`
  const res = await axios.get(url, {
    timeout: 0,
    processData: false,
    mimeType: 'multipart/form-data',
    contentType: false
  })

  dispatch({
    type: GET_PROFILE_DATA,
    payload: res.data
  })
}

// Get Notifications Widgets
export const getNotificationsWidget = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/notifications`
  const res = await axios.get(url)

  dispatch({
    type: GET_NOTIFICATIONS_WIDGET,
    payload: res.data
  })
}

// Get Notifications Actions
export const getNotificationsActions = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/in-app-notification-actions`
  const res = await axios.get(url)

  dispatch({
    type: GET_NOTIFICATIONS_ACTIONS,
    payload: res.data
  })
}

// Get CallForwarding Settings
export const getCallForwadingSettings = () => async dispatch => {
  const w_id = await getWidgetId()
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-call-settings?widget_id=${w_id}`
  const res = await axios.get(url)
  dispatch({
    type: GET_CALLFORWADING_SETTINGS,
    payload: res?.data || {}
  })
}

// Get CallForwarding Settings
export const getWidgetCountries = () => async dispatch => {
  const w_id = await getWidgetId()
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-countries?widget_id=${w_id}`
  const res = await axios.get(url)

  dispatch({
    type: GET_WIDGET_COUNTRIES,
    payload: res.data
  })
}

// Get Team Member
export const getTeamMember = () => async dispatch => {
  const uid = await localStorage.getItem('id')
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/member/${uid}`
  const res = await axios.get(url)

  dispatch({
    type: GET_TEAM_MEMBER,
    payload: res.data
  })
}

// Get Team Members
export const getTeamMembers = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members?with_non_active=1`
  const res = await axios.get(url)

  dispatch({
    type: GET_TEAM_MEMBERS,
    payload: res.data
  })
}

// Get Widget Days
export const getWidgetDays = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-days`
  const res = await axios.get(url)

  dispatch({
    type: GET_WIDGET_DAYS,
    payload: res.data
  })
}

// Get User Integrations
export const getUserIntegrations = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/user-integrations`
  const res = await axios.get(url)

  dispatch({
    type: GET_USER_INTEGRATIONS,
    payload: res.data
  })
}

// Get Teams
export const getTeams = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/teams?limit=20000&offset=0`
  const res = await axios.get(url)

  dispatch({
    type: GET_TEAMS,
    payload: res.data
  })
}

// Get Teams Without Limits
export const getAllTeams = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/teams`
  const res = await axios.get(url)

  dispatch({
    type: GET_ALL_TEAMS,
    payload: res.data
  })
}

// Get Member Roles
export const getMemberRoles = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/member-roles`
  const res = await axios.get(url)

  dispatch({
    type: GET_MEMBER_ROLES,
    payload: res.data
  })
}

// Get customer invoices
export const getCustomerInvoices = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-customer-invoices`
  const res = await axios.get(url)

  dispatch({
    type: GET_CUSTOMER_INVOICES,
    payload: res.data
  })
}

// get Payment logs
export const getPaymentLog = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/account-activities?type=payment was made`
  const res = await axios.get(url)

  dispatch({
    type: GET_PAYMENT_LOG,
    payload: res.data
  })
}

export const getRecentChargeLog = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/account-activities?type=charge was made`
  const res = await axios.get(url)

  dispatch({
    type: GET_CHARGE_LOG,
    payload: res.data
  })
}

// Get Billing info
export const getBillingInfo = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-customer-billing-info`
  const res = await axios.get(url)

  dispatch({
    type: GET_BILLING_INFO,
    payload: res.data
  })
}

// Get Customer Payment Methode
export const getPaymentMethod = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-customer-payment-methods`
  const res = await axios.get(url)

  dispatch({
    type: GET_PAYMENT_METHOD,
    payload: res.data
  })
}

// Get Web Hooks
export const getWebHook = () => async dispatch => {
  const w_id = await getWidgetId()
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-hooks?widget_id=${w_id}`
  const res = await axios.get(url)

  dispatch({
    type: GET_WEB_HOOK,
    payload: res.data
  })
}

// Get Widget Install
export const getWidgetInstall = () => async dispatch => {
  const w_id = await getWidgetId()
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/install?widget_id=${w_id}`
  const res = await axios.get(url)

  dispatch({
    type: GET_WIDGET_INSTALL,
    payload: res.data
  })
}


// Get Sms Placeholder
export const getSmsPlaceholders = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/sms/placeholders`
  const res = await axios.get(url)

  dispatch({
    type: GET_SMS_PLACEHOLDERS,
    payload: res.data
  })
}

// Get CAller Id
export const getCallerId = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/caller-id`
  const res = await axios.get(url)

  dispatch({
    type: GET_CALLER_ID,
    payload: res.data
  })
}

// Get Widget Settings Data
export const getWidgetSettings = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/widget/${w_api_key}/settings`
  //const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/install?widget_id=${w_id}`
  const res = await axios.get(url)

  dispatch({
    type: GET_WIDGET_SETTINGS,
    payload: res.data
  })
}

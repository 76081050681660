import axios from 'axios'
import moment from 'moment'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Checkbox, Dimmer, Loader } from 'semantic-ui-react'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import CommonSelect from '../../common/CommonSelectCountry'
import CommonSubscriptionModal from '../../common/CommonSubscriptionModal'
import CommonTable from '../../common/CommonTableNo'
import { getSubscription } from '../../config/subscription'
import CountryList from '../../helpers/CountryList'

const apiToken = localStorage.getItem('access_token')
const isAppsumo = localStorage.getItem('is_appsumo')
const plan_name = localStorage.getItem('plan_name')

const table = {
  type: '6',
  header: [
    { headerTitle: 'Phone Number' },
    { headerTitle: 'SMS' },
    { headerTitle: 'Voice' },
    { headerTitle: 'KYC' },
    { headerTitle: 'Price' },
    { headerTitle: 'Action' }
  ],
  tableContentData: []
}

class LocalNumbers extends Component {
  state = {
    subscriptionData: {
      tableContentData: [
        { ends: 0, max_calls: 0, calls_used: 0, max_sms: 0, sms_used: 0 }
      ]
    },
    plan_name: null,
    showSubscriptionModal: false,
    isLoading: true,
    isCancelModalOpen: false,
    redirect: false,
    selectedCountry: '',
    selectedRegion: '',
    regionList: [],
    haveRegion: false,
    tableData: table,
    countryListt: [],
    city: '',
    fax: false,
    mms: false,
    sms: false,
    voice: false,
    noRecord: false
  }

  async componentDidMount() {
    this.setState({
      tableData: {
        type: '6',
        header: [
          { headerTitle: 'Phone Number' },
          { headerTitle: 'SMS' },
          { headerTitle: 'Voice' },
          { headerTitle: 'KYC' },
          { headerTitle: 'Price' },
          { headerTitle: 'Action' }
        ],
        tableContentData: []
      }
    })
    this.props.loading(false)
    this.fetchCountries()
    this.GetSubscribeData()
  }

  GetSubscribeData = async () => {
    getSubscription().then(res => {
      if (res && res.data) {
        var upgradeStatus = false
        var titleLine = ''
        var upgradeLine = ''
        const data = res.data.data
        let trialEndDate = moment(data.trial_end)
        let planEndDate = moment(
          data.current_term_end ? data.current_term_end : null
        )
        let today = moment(new Date())
        var trialDays = trialEndDate.diff(today, 'days')
        var plan_expired = planEndDate.diff(today, 'days')
        var max_calls = data.max_calls
        var used_calls = data.calls_used
        var pleaseUpgrade = ''

        var customPlan =
          data.plan_name.charAt(0).toUpperCase() +
          data.plan_name.slice(1).toLowerCase()

        const tableData = {
          tableContentData: [
            {
              plan_name: data.plan_name,
              ends: 0,
              max_calls: data.max_calls,
              calls_used: data.calls_used,
              max_sms: data.max_sms,
              sms_used: data.sms_used
            }
          ]
        }

        //------------------- Trial Plan -------------------//
        if (+isAppsumo) {
          titleLine = ' '
        } else {
          if (data.status === 'in_trial') {
            if (trialDays > 0 && max_calls > used_calls) {
              upgradeStatus = true
              pleaseUpgrade = 'Upgrade now'
              titleLine =
                'See what your trial is all about. Your trial plan will expire in ' +
                trialDays +
                ' days .'
              upgradeLine = '  for a seamless service.'
            } else if (trialDays > 0 && max_calls <= used_calls) {
              upgradeStatus = true
              pleaseUpgrade = 'Please Upgrade '
              titleLine = `You have reached the limits of your trial plan.`
              upgradeLine = '  or contact us for add-ons to'
            } else if (trialDays <= 0) {
              upgradeStatus = true
              pleaseUpgrade = 'Please Upgrade '
              titleLine =
                'Your trial plan has expired on ' +
                moment(data.trial_end).format('LL') +
                '.'
              upgradeLine = ' .'
            } else {
              titleLine = ''
            }
          } else if (data.status === 'Active') {
            if (plan_expired > 0 && max_calls > used_calls) {
              upgradeStatus = true
              titleLine = ''
              // 'You are subscribed to ' +
              // data.plan_name +
              // ', Your plan renews on ' +
              // moment(data.current_term_end).format('LL') +
              // ' .'
            } else if (plan_expired > 0 && max_calls <= used_calls) {
              upgradeStatus = true
              pleaseUpgrade = 'Please Upgrade '
              titleLine =
                'You have reached the limits of your current plan ' +
                data.plan_name +
                '.'
              upgradeLine = ' or contact us for add-ons to'
            } else if (plan_expired <= 0) {
              upgradeStatus = true
              pleaseUpgrade = 'Please Upgrade '
              titleLine =
                'Your current plan ' +
                data.plan_name +
                ' has expired on ' +
                moment(data.current_term_end).format('LL') +
                '.'
              upgradeLine = ' .'
            } else {
              titleLine = ''
            }
          } else {
            titleLine = ''
          }
        }

        localStorage.setItem('plan_name', res.data.data.plan_name)
        localStorage.setItem('max_users', res.data.data.max_users)

        this.setState({
          plan_name:
            data.status === 'Cancelled' || data.status === 'in_trial'
              ? ''
              : res.data.data.plan_name,
          pleaseUpgrade: pleaseUpgrade,
          isTrial: upgradeStatus,
          trialDays: trialDays,
          titleLine: titleLine,
          subscriptionData: tableData,
          upgradeLine: upgradeLine
        })
      }
    })
  }

  fetchCountries = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/numbers/countries?number_type=tollFree`
    axios
      .get(url)
      .then(res => {
        var cArray = []

        for (let value of Object.values(res.data.data)) {
          var temp = {
            name: value,
            regions: []
          }
          cArray.push(temp)
        }

        if (
          cArray?.some(({ name }) => name?.toLowerCase() === 'united states')
        ) {
          var t = {
            name: 'United States',
            regions: []
          }

          let reg = this.getRegion('United States')
          this.setState({ selectedCountry: 'United States' })

          if (reg?.length) {
            this.setState({ haveRegion: true, regionList: reg })
          } else {
            this.setState({
              haveRegion: false,
              regionList: [],
              selectedRegion: ''
            })
            cArray.push(t)
          }
        } else {
          this.setState({
            haveRegion: false,
            regionList: [],
            selectedRegion: '',
            selectedCountry:cArray?.[0]?.name
          })
        }

        this.setState({ countryListt: cArray, isLoading: false })
      })
      .catch(error => {
        this.setState({ isLoading: false })
      })
  }

  handleModal = () => {
    let { isCancelModalOpen } = this.state
    isCancelModalOpen = !isCancelModalOpen
    this.setState({ isCancelModalOpen })
  }
  handleYesModal = () => {
    this.setState({ isCancelModalOpen: false, redirect: true })
  }
  handleCancelModal = () => {
    this.setState({ isCancelModalOpen: false })
  }
  handleCloseCancelModal = () => this.setState({ isCancelModalOpen: false })

  getLocalNo = async () => {
    const plan = await localStorage.getItem('plan_name')
    if (plan == 'STARTER PLAN') {
      CommonNotify('Paid plan not activated', 'warning')
      await this.setState({ showSubscriptionModal: true })
      return
    }

    if (this.state.selectedCountry == '') {
      CommonNotify('Please Select Country', 'warning')
      return
    }

    this.setState({ isLoading: true })
    const { city, fax, mms, sms, voice } = this.state
    const params = `key=null&type=tollFree&country=${
      this.state.selectedCountry
    }&region=${this.state.selectedRegion}&fax=${fax ? 'true' : 'false'}&mms=${
      mms ? 'true' : 'false'
    }&voice=${voice ? 'true' : 'false'}&sms=${
      sms ? 'true' : 'false'
    }&city=${city}`

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/get-available-phone-numbers?${params}`

    axios
      .get(url)
      .then(res => {
        this.setState({ isLoading: false })
        if (res.data.data) {
          let tableData = this.state.tableData
          tableData.tableContentData = []
          let rows = []
          const amount = res.data.data.price
          const kyc = res.data.data.required_regulatory_bundle
            ? res.data.data.required_regulatory_bundle
            : false
          if (!res.data.data.phoneNumbers?.length) {
            // CommonNotify('Number Not Available', 'warning')
            tableData.tableContentData = rows
            this.setState({ tableData, noRecord: true })
            return
          }

          res.data.data.phoneNumbers.map(row => {
            let obj = {}
            obj.phone = row
            obj.price = amount
            obj.kyc = kyc
            rows.push(obj)
          })
          tableData.tableContentData = rows
          this.setState({ tableData, noRecord: false })
        }
      })
      .catch(error => {
        let tableData = this.state.tableData
        tableData.tableContentData = []
        let rows = []
        tableData.tableContentData = rows
        this.setState({ isLoading: false, tableData })
        const errors = { ...error }
        if (errors.response.data.errors) {
          CommonNotify(errors.response.data.errors[0])
        } else {
          CommonNotify('Some thing went wrong')
        }
      })
  }

  changeCountry = (p1, p2) => {
    const val = p2.value
    if (val == '') {
      return
    }
    let reg = this.getRegion(val)
    this.setState({ selectedCountry: val })
    if (reg?.length) {
      this.setState({ haveRegion: true, regionList: reg })
    } else {
      this.setState({ haveRegion: false, regionList: [], selectedRegion: '' })
    }
  }

  changeRegion = (p1, p2) => {
    const val = p2.value
    if (val == '') {
      return
    }
    this.setState({ selectedRegion: val })
  }

  getRegion = country => {
    let selectedReg
    CountryList.map((row, index) => {
      if (row.name == country) {
        selectedReg = row.regions
        return
      }
    })

    return selectedReg
  }

  buyNo = data => {
    data.plan = 'monthly'
    data.access = 1

    if (data.name.trim() == '') {
      CommonNotify('Please Enter a Friendly Name For the Number', 'warning')
      return
    }

    this.setState({ isLoading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/buy-phone-number`

    axios
      .post(url, data)
      .then(res => {
        this.setState({ isLoading: false })
        if (res.data.data) {
          CommonNotify(res.data.message[0], 'success')
          //window.location.href = '/numbers'
          window.location.href = '/numbers/' + res.data.data.id
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        CommonNotify('Can`t Add Number')
      })
  }

  closeSubscriptionModal = async () => {
    await this.setState({ showSubscriptionModal: false })
  }

  render() {
    const { redirect } = this.state

    if (redirect) {
      return <Redirect to="/setCampaign?campaignId=2" />
    }
    return (
      <>
        <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>
        <div>
          <div className="local-number-wrap">
            <div className="local-number-header">
              {this.state.selectedCountry && (
                <CommonSelect
                  style={{ width: '280px' }}
                  onChange={this.changeCountry}
                  name="config"
                  defaultValue={this.state.selectedCountry}
                  className="popup-font-select"
                  placeholder="Select Country"
                  options={this.state.countryListt}
                />
              )}

              {/* {this.state.haveRegion ? (
                <CommonSelect2
                  style={{ width: '280px' }}
                  onChange={this.changeRegion}
                  name="config"
                  className="popup-font-select"
                  placeholder="Select Region"
                  options={this.state.regionList}
                  disabled={this.state.haveRegion == false ? true : false}
                />
              ) : null} */}

              {/* <Input
                style={{ width: '280px', height: '35px' }}
                onChange={e =>
                  this.setState({
                    city: e?.target?.value
                  })
                }
                name="city"
                defaultValue={this.state?.city}
                className="popup-font-select ml-4 mr-4"
                placeholder="Enter City"
              /> */}

              <CommonButtons
                type="button"
                content="Search"
                background="blue"
                onClick={this.getLocalNo}
                btnClass="ml-4"
              />
            </div>
            <div
              className="local-number-header mt-4"
              style={{ marginLeft: '-10px' }}
            >
              <Checkbox
                label="Fax"
                checked={this.state?.fax}
                onChange={(e, data) => {
                  this.setState({
                    fax: !this.state?.fax
                  })
                }}
              />
              <Checkbox
                label="MMS"
                checked={this.state?.mms}
                onChange={(e, data) => {
                  this.setState({
                    mms: !this.state?.mms
                  })
                }}
              />
              <Checkbox
                label="SMS"
                checked={this.state?.sms}
                onChange={(e, data) => {
                  this.setState({
                    sms: !this.state?.sms
                  })
                }}
              />
              <Checkbox
                label="Voice"
                checked={this.state?.voice}
                onChange={(e, data) => {
                  this.setState({
                    voice: !this.state?.voice
                  })
                }}
              />
            </div>
            <CommonTable
              handleBuy={this.buyNo}
              style={{ marginTop: '30px' }}
              dataTable={this.state.tableData}
              noRecord={this.state.noRecord}
            />
          </div>
        </div>

        {isAppsumo != 1 && (
          <CommonSubscriptionModal
            open={this.state.showSubscriptionModal}
            onClose={this.closeSubscriptionModal}
            currentPlan={plan_name}
            dataTable={this.state.subscriptionData}
          />
        )}
      </>
    )
  }
}

export default LocalNumbers

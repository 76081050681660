import classnames from 'classnames'
import React from 'react'

const accountSetup = [
  {
    id: 1,
    title: 'Profile',
    class: 'okk'
  },
  // {
  //   id: 2,
  //   title: 'Use Casssse',
  //   class: ''
  // },
  // {
  //   id: 3,
  //   title: 'Working hours',
  //   class: ''
  // },
  {
    id: 4,
    title: 'Setup Call Forwarding',
    class: ''
  },
  {
    id: 5,
    title: 'Go live',
    class: ''
  }
]

const callBack = [
  {
    id: 1,
    title: 'Profile',
    class: 'okk'
  },
  {
    id: 2,
    title: 'Phone Verification',
    class: ''
  },
  {
    id: 3,
    title: 'Configure Widget',
    class: ''
  },
  {
    id: 4,
    title: 'Install',
    class: ''
  },
  {
    id: 5,
    title: ' Go live',
    class: ''
  }
]

const callTrackingTitle = [
  {
    id: 1,
    title: 'Billing info',
    class: 'okk'
  },
  {
    id: 2,
    title: 'Use case',
    class: ''
  },
  {
    id: 3,
    title: 'Call routing',
    class: ''
  },
  {
    id: 4,
    title: 'Install',
    class: ''
  },
  {
    id: 5,
    title: 'Welcome',
    class: ''
  }
]

const virtualTitle = [
  {
    id: 1,
    title: 'Billing Info',
    class: 'okk'
  },
  {
    id: 2,
    title: 'Use case',
    class: ''
  },
  {
    id: 3,
    title: 'Your phone number',
    class: ''
  },
  {
    id: 4,
    title: 'Setup call routing',
    class: ''
  },
  {
    id: 5,
    title: 'Invite Team Member',
    class: ''
  },
  {
    id: 6,
    title: 'Welcome',
    class: ''
  }
]

const WizardBox = props => {
  const { step } = props

  const handleManageTitle = () => {
    let title = localStorage.getItem('flowType')
    switch (title) {
      case 'callback':
        return 'Callback Setup'
      case 'callTraking':
        return 'Call Tracking Setup'
      case 'invited':
        return 'Account Setup'
      case 'virtualPhone':
        return 'Virtual Phone Setup'

      default:
        return 'Quick Setup'
    }
  }

  const handleSelectMenu = () => {
    let title = localStorage.getItem('flowType')
    switch (title) {
      case 'callback':
        return callBack
      case 'callTraking':
        return callTrackingTitle
      case 'virtualPhone':
        return virtualTitle
      case 'invited':
        return accountSetup

      default:
        return callBack
    }
  }

  return (
    <div className="quicksetup-wizard">
      <div className="wizard">
        <h2 className="wizard-title"> {handleManageTitle()} </h2>
        <div className="wizard-holder">
          {handleSelectMenu()?.map((item, index) => {
            return (
              <span
                className={
                  step >= index + 1
                    ? classnames(`wizard-item active`)
                    : 'wizard-item'
                }
                key={item.id}
              >
                {item.title}
              </span>
            )
          })}
        </div>
        <span className="wizard-step-title">
          {handleSelectMenu()[step - 1]?.title}
        </span>
      </div>
    </div>
  )
}

export default WizardBox

import React, { useState, useEffect } from 'react'
import { TextArea } from 'semantic-ui-react'
import _ from 'lodash'

import CommonColor from '../../common/CommonColor'
import NodeToggle from '../../common/NodeToggle'
import CommonSelect from '../../common/CommonSelect'
import CommonInput from '../../common/CommonInput'
import { Accordion, Rail, Ref, Sticky } from 'semantic-ui-react'
import CommonButton from '../../common/CommonButtons'
import iconStyle from '../../assets/images/Dashboard 2-07.png'
import whatsappIcon from '../../assets/images/whatsapp.png'
import telegramIcon from '../../assets/images/telegram.png'
import lineIcon from '../../assets/images/line.png'
import messangerIcon from '../../assets/images/messanger.png'
import axios from 'axios'
import LinkIcon from '@mui/icons-material/Link'

import {
  liveCallBackDisplay,
  modernTitle,
  modernSubTitle,
  callMeLaterCompanySize,
  callMeLaterMessage,
  callBackCompanySize,
  callBackMessage,
  callBackIndustry,
  digitalCallCompanySize,
  digitalCallIndustry,
  digitalCallMessage,
  leaveMessageCompanySize,
  leaveMessageIndustry,
  leaveMessageMessage,
} from '../../lib/WidgetData'
import CommonButtons from '../../common/CommonButtons'
import { useSelector } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import { Button } from '@mui/material'

import { CommonNotify } from '../../common/CommonNotify'
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import deleteIcon from '../../assets/images/delete-icon.png';


const modernKeys = {
  header: 'modern_header_title',
  headerStatus: 'modern_header_title_status',
  subHeader: 'modern_header_subtitle',
  subHeaderStatus: 'modern_header_subtitle_status',
  webCall: {
    key: 'digital_call',
    title: "digitalcall_button_title_text",
    subTitle: "digitalcall_button_subtitle_text",
    headerText:'modern_digital_calls_title',
    cta: 'digitalcall_modern_cta',
    name: 'full_name_in_digital_calls_tab',
    email: 'email_in_digital_calls_tab',
    team: 'team_in_digital_calls_tab',
    companySize: 'modern_digital_call_company_size',
    website: 'modern_digital_call_website',
    message: 'modern_digital_call_message',
    company: 'modern_digital_call_company',
    phone: 'modern_digital_call_phone'
  },

  callBack: {
    key: 'modern_call_now',
    title: "callback_title",
    subTitle: "callback_subtitle",
    headerText:'modern_call_now_title',    
    cta: 'callback_modern_cta',
    name: 'full_name_in_call_now_tab',
    email: 'email_in_call_now_tab',
    team: 'team_in_call_now_tab',
    companySize: 'modern_call_now_company_size',
    website: 'modern_call_now_website',
    message: 'modern_call_now_message',
    company: 'modern_call_now_company'
  },
  scheduleCall: {
    key: 'modern_call_later',
    liveCallBack: 'modern_call_now',
    title: "schedule_call_tab_text",
    headerText:'modern_schedule_call_title',
    
    subTitle: "schedule_call_subtitle_text",
    cta: 'schedulecall_modern_cta',
    name: 'full_name_in_call_later_tab',
    email: 'email_in_call_later_tab',
    team: 'team_in_call_later_tab',
    companySize: 'modern_call_later_company_size',
    website: 'modern_call_later_website',
    message: 'modern_call_later_message',
    company: 'modern_call_later_company'
  },
  leaveMessage: {
    key: 'modern_send_message',
    title: "leave_message_tab_text",
    subTitle: "leave_message_tab_subtext",
    headerText:'modern_send_message_title',
    cta: 'contact_modern_cta',
    name: 'modern_send_message_name',
    email: 'modern_send_message_email',
    team: 'modern_send_message_team',
    companySize: 'modern_send_msg_company_size',
    website: 'modern_send_msg_website',
    company: 'modern_send_msg_industry',
    phone: 'modern_send_message_phone'
  }
}

const classicKeys = {
  
  title: "schedule_call_tab_text",
  subTitle: "offline_schedule_call_message",

  digitalCall: {
    key: 'digital_call',
    title: 'digital_call_tab_text',
    cta: 'digital_call_button_text',
    name: 'full_name_in_digital_calls_tab',
    email: 'email_in_digital_calls_tab',
    team: 'team_in_digital_calls_tab',
    company: 'company_in_digital_calls_tab',
    companySize: 'classic_digital_call_company_size',
    message: 'classic_digital_call_message',
    website: 'classic_digital_call_website'
  },

  callNow: {
    key: 'call_now',
    title: 'schedule_call_tab_text',
    subTitle: 'offline_schedule_call_message',
    cta: 'submit_request_call_text',
    name: 'full_name_in_call_now_tab',
    email: 'email_in_call_now_tab',
    team: 'team_in_call_now_tab',
    company: 'company_in_call_now_tab',
    companySize: 'classic_call_now_company_size',
    message: 'classic_call_now_message',
    website: 'classic_call_now_website',

  },
  calllater: {
    key: 'call_me_later',
    liveCallBack: 'call_now',
    title: 'schedule_call_tab_text',
    cta: 'call-latter-button',
    name: 'full_name_in_call_later_tab',
    email: 'email_in_call_later_tab',
    team: 'team_in_call_later_tab',
    company: 'company_in_call_later_tab',
    companySize: 'classic_call_later_company_size',
    message: 'classic_call_later_message',
    website: 'classic_call_later_website'
  },
  leaveMessage: {
    key: 'leave_message',
    title: 'leave_message_tab_text',
    subTitle:'contact_form_subtext',
    name: 'full_name_in_leave_message_tab',
    email: 'email_in_leave_message_tab',
    team: 'team_in_leave_message',
    company: 'classic_send_msg_industry',
    companySize: 'classic_send_msg_company_size',
    website: 'classic_send_msg_website'
  }
}
const ultimateKeys = {
  title: "schedule_call_tab_text",
  subTitle: "offline_schedule_call_message",
  header: 'ultimate_header_title',
  headerStatus: 'ultimate_header_title_status',
  subHeader: 'ultimate_header_subtitle',
  subHeaderStatus: 'ultimate_header_subtitle_status',
  digitalCall: {
      title: "modern_digital_calls_title",
    subTitle: "ultimate_subheading_home_webcall",
    homeShow:'home_audiocall_visible_status',
    key: 'ultimate_digital_call',
    headerText: 'digitalcall_button_title_text',
    cta: 'digitalcall_ultimate_cta',
    name: 'ultimate_digital_call_name',
    email: 'ultimate_digital_call_email',
    team: 'ultimate_digital_call_team',
    phone: 'ultimate_digital_call_phone',
    website: 'ultimate_digital_call_website',
    company: 'ultimate_digital_call_company',
    companySize: 'ultimate_digital_call_company_size',
    message: 'ultimate_digital_call_message'
  },

  callNow: {
    
      homeShow:'home_callback_visible_status',
      titleHome:'ultimate_heading_home_callback',
      subTitleHome:'ultimate_subheading_home_callback',
    key: 'ultimate_call_now',
    onlineHeader: 'schedule_call_tab_text',
    offlineHeader: 'offline_schedule_call_message',
    ctaCalllater:'ultimate_callback_cta_test_call_later',
    ctaCallback:'ultimate_callback_cta_test_call_now',
    subTitle:'ultimate_sub_title_callback',
    subHeader:'ultimate_sub_header_callback',
    cta: 'callback_ultimate_cta',
    name: 'ultimate_call_now_name',
    email: 'ultimate_call_now_email',
    team: 'ultimate_call_now_team',   
    phone: 'ultimate_call_now_phone',
    website: 'ultimate_call_now_website',
    company: 'ultimate_call_now_company',
    companySize: 'ultimate_call_now_company_size',
    message: 'ultimate_call_now_message'

  },
  calllater: {
      homeShow:'home_meeting_visible_status',
      title: "ultimate_heading_home_meeting",
  subTitle: "ultimate_subheading_home_meeting",
    key: 'ultimate_call_later',
    headerText: 'ultimate_meeting_title',
    cta: 'schedulecall_ultimate_cta',
    name: 'ultimate_call_later_name',
    email: 'ultimate_call_later_email',
    team: 'ultimate_call_later_team',
    phone: 'ultimate_call_later_phone',
    website: 'ultimate_call_later_website',
    company: 'ultimate_call_later_company',
    companySize: 'ultimate_call_later_company_size',
    message: 'ultimate_call_later_message'
  },
  leaveMessage: {
    homeShow:'home_message_visible_status',
      title: "ultimate_heading_home_message",
    subTitle: "ultimate_subheading_home_message",
    key: 'ultimate_leave_message',
    headerText: 'ultimate_send_message_title',
    subHeaderText:'contact_form_subtext',
    cta: 'ultimate_send_message_cta_button',
    name: 'ultimate_send_msg_name',
    email: 'ultimate_send_msg_email',
    team: 'ultimate_send_msg_team',
    phone: 'ultimate_send_msg_phone',
    company: 'ultimate_send_msg_company',
    website: 'ultimate_send_msg_website',
    companySize: 'ultimate_send_msg_company_size',
    message: 'ultimate_send_msg_message'
  },
  textUs: {
    key: 'ultimate_text_message',
    homeShow:'home_text_us_visible_status',
      title: "ultimate_send_textheader",
    subTitle: "ultimate_send_text_intro",
    headerText: 'ultimate_send_text_title',
    cta: 'ultimate_send_text_cta_button',
    name: 'ultimate_send_textname',
    email: 'ultimate_send_textemail',
    team: 'ultimate_send_textteam',

    company: 'ultimate_send_textcompany',
  },
}
const smartKeys = {
  
  header: 'smart_home_title',
  headerStatus: 'smart_home_title_status',
  subHeader: 'smart_home_subtitle',
  subHeaderStatus: 'smart_home_subtitle_status',

  digitalCall: {
    key: 'digital_call',
    title: 'digital_call_tab_text',
    cta: 'digital_call_button_text',
    name: 'full_name_in_digital_calls_tab',
    email: 'email_in_digital_calls_tab',
    team: 'team_in_digital_calls_tab',
    company: 'smart_digital_call_industry',
    companySize: 'smart_digital_call_company_size',
    message: 'smart_digital_call_message'
  },
  calllater: {
    key: 'call_me_later',
    title: 'schedule_call_tab_text',
    cta: 'offline_schedule_call_message',
    name: 'full_name_in_call_later_tab',
    email: 'email_in_call_later_tab',
    team: 'team_in_call_later_tab',
  },
    callBack: {
    key: 'call_now',
    callButtonText: 'call_btn_text_callback_template',
    cta: 'cta_btn_callback_template',
    headline: 'headline_in_callback_template',
    subHeadline: 'sub_headline_in_callback_template',
    name: 'name_in_callback_template',
    email: 'email_in_callback_template',
    team: 'team_in_callback_template',
    comment: 'comment_in_callback_template',
    company: 'company_in_callback_template',
    companySize: 'company_size_in_callback_template',
    website: 'website_in_callback_template'
  },
}

const callbackKeys = {

  
  header: 'callback_title',
  headerStatus: 'callback_title_status',
  subHeader: 'callback_subtitle',
  subHeaderStatus: 'callback_subtitle_status',
  title: "schedule_call_tab_text",
  subTitle: "offline_schedule_call_message",
  calllater: {
    key: 'call_me_later',
    liveCallBack: 'call_now',
    callButtonText: 'call_btn_text_callback_template',
    cta: 'cta_btn_callback_template',
    headline: 'headline_in_callback_template',
    subHeadline: 'sub_headline_in_callback_template',
    name: 'name_in_callback_template',
    email: 'email_in_callback_template',
    team: 'team_in_callback_template',
    comment: 'comment_in_callback_template',
    company: 'company_in_callback_template',
    companySize: 'company_size_in_callback_template',
    website: 'website_in_callback_template'
  },

}

let widgetDefaultSetting = {
  template: 'Classic'
}

const apiToken = localStorage.getItem('access_token')

const bubbleDefaultSetting = {
  color: '#0080FF',
  shadow: false,
  textOnline: 'Would you like us to call you now ?',
  textOffline: 'Hey there! We are offline now ?',
  position: '',
  labelColor: '#2B2B2C',
  shape: 'default'
}

const bussinessLimecall = [
  'Industry',
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel/Fashion',
  'Architecture/Planning',
  'Arts/Crafts',
  'Automotive',
  'Aviation/Aerospace',
  'Banking/Mortgage',
  'Biotechnology/Greentech',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies/Equipment',
  'Capital Markets/Hedge Fund/Private Equity',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software/Engineering',
  'Computer/Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense/Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment/Movie Production',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Fundraising',
  'Furniture',
  'Gambling/Casinos',
  'Glass/Ceramics/Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design/Web Design',
  'Health/Fitness',
  'Higher Education/Acadamia',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Individual/Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology/IT',
  'Insurance',
  'International Affairs',
  'International Trade/Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management/Hedge Fund/Private Equity',
  'Judiciary',
  'Law Enforcement',
  'Law Practice/Law Firms',
  'Legal Services',
  'Legislative Office',
  'Leisure/Travel',
  'Library',
  'Logistics/Procurement',
  'Luxury Goods/Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing/Advertising/Sales',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Mental Health Care',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Museums/Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers/Journalism',
  'Non-Profit/Volunteering',
  'Oil/Energy/Solar/Greentech',
  'Online Publishing',
  'Other Industry',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Relations/PR',
  'Public Safety',
  'Publishing Industry',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate/Mortgage',
  'Recreational Facilities/Services',
  'Religious Institutions',
  'Renewables/Environment',
  'Research Industry',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing/Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation/Localization',
  'Transportation',
  'Utilities',
  'Venture Capital/VC',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine/Spirits',
  'Wireless',
  'Writing/Editing'
]
const roleLimeCall = [
  'Sale Manager',
  'Marketing / PR Manager',
  'Customer Services Manager',
  'CXO / General Manager',
  'It Manager',
  'Operation Manager',
  'Developer / software Engineer / Analyst',
  'Student / Personal Interest',
  'other'
]
const orgLimecall = [
  '1-10',
  '11-50',
  '51-250',
  '251-1K',
  '1k-5k',
  '5K-10K',
  '10K-50K',
  '50K-100K',
  '100K+'
]


export const getToggleObj = (title, callDesc, callId, callRef) => (
  {
    callTitle: title,
    callDesc,
    callId,
    callRef: callRef || callId,
  }
)

export const WidgetStyleTitle = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconStyle} alt="logo" />
    </div>
    <div className="accordion-title-holder">
      <h2 className="accordion-title">Configure your web widget</h2>
      <p className="accordion-description">
       Decide how your widget will look and behave and configure your lead qualification.
      </p>
    </div>
  </div>
)

export const WidgetLeadQualification = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconStyle} alt="logo" />
    </div>
    <div className="accordion-title-holder">
      <h2 className="accordion-title">Custom Lead Form Questions</h2>
      <p className="accordion-description">
        Routing forms let you request information before someone can book a call or submit a form with you.
      </p>
    </div>
  </div>
)
export const WidgetSocialTitle = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconStyle} alt="logo" />
    </div>
    <div className="accordion-title-holder">
      <h2 className="accordion-title">Social & External Links</h2>
      <p className="accordion-description">
        Integrate your call button with whatsapp, telegram, email and custom links
        {/* Decide how the call widget on your website will look, behave and what information it will offer. */}
      </p>
    </div>
  </div>
)

export const WidgetStyleContent = ({
  reloadWidget,
  handleTabActive,
  widget,
  styleRef,
  closeWidgetStyle,
  handleDataRef,
  handleToggleData,
  clickToCallName,
  clickToCallNameToggle,
  clickToCallEmail,
  clickToCallEmailToggle,
  clickToCallTeam,
  clickToCallInterest,
  clickToCallIndustry,
  clickToCallEmployee,
  clickToCallRevenue,
  clickToCallRole,
  clickToCallComment,
  clickToCallTeamToggle,
  clickToCallCustomFields,

  widgetSettDataUpdate,
  widgetStyledDataUpdate,

  clickToCallCustomFieldsToggle,
  lstCustomFields,
  onUpdateCustomeFields,
  handleQualification,
  onAddRemoveCustomeFields,
  onSubmitCustomFields,
  callMeBackName,
  callMeBackEmail,
  callMeBackTeam,
  callMeBackInterest,
  callMeBackIndustry,
  callMeBackEmployee,
  callMeBackRevenue,
  callMeBackRole,
  callMeBackComment,
  callMeBackCustomFields,
  callMeBackCustomFieldsToggle,
  callMeLaterName,
  callMeLaterNameToggle,
  callMeLaterEmail,
  callMeLaterEmailToggle,
  callMeLaterTeam,
  callMeLaterInterest,
  callMeLaterIndustry,
  callMeLaterEmployee,
  callMeLaterRevenue,
  callMeLaterRole,
  callMeLaterComment,
  callMeLaterTeamToggle,
  callMeLaterCustomFields,
  callMeLaterCustomFieldsToggle,
  leaveMessageName,
  leaveMessageEmail,
  leaveMessageTeam,
  leaveMessageInterest,
  leaveMessageIndustry,
  leaveMessageComment,
  leaveMessageEmployee,
  leaveMessageRevenue,
  leaveMessageRole,
  leaveMessageCustomFields,
  leaveMessageCustomFieldsToggle,
  classicSmart,
  contextRef,
  legalWidget,
  legalToggle,
  socialWidget,
  privacyWidget,
  privacyToggle,
  onClickTableCategory,
  widgetStyleTabs,
  activeIndexWidget,
  handleClickWidget,
  allowedTabs,
  webCallDisplay,
  callMeBackDisplay,
  contactUsDisplay,
  callMeLaterDisplay,
  leaveMessageDisplay,
  loading,
  fullNameToggle,
  emailToggle,
  onClickAllowedTabs,
  widgetObject,
  intilizeTab
}) => {
  const [buttonColor, setButtonColor] = useState('#662D91')
  const [isInputChange, setIsInputChange] = useState(false)


  const [clickToCallTabText, setClickToCallTabText] = useState(
    'Click to connect to agent!'
  )
  const [clickToCallButtonText, setClickToCallButtonText] = useState(
    'Start Call'
  )

  const [callMeLaterTabText, setCallMeLaterTabText] = useState(
    'Would you like us to call you later ?'
  )
  const [callMeLaterButtonText, setCallMeLaterButtonText] = useState('Schedule')

  const [leaveMessageButtonText, setLeaveMessageButtonText] = useState(
    'Send Message'
  )
  const [widgetApiData, setWidgetApiData] = useState(widgetDefaultSetting)


  const [legalText, setLegalText] = useState('Terms')
  const [queryFields, setQueryFields] = useState(['custom'])

  const [widgetAllSetting, setWidgetALlSetting] = useState(null)
  const [widgetSetting, setWidgetSetting] = useState(widgetDefaultSetting)
  const [bubbleSetting, setBubbleSetting] = useState(bubbleDefaultSetting)
  const [widgetStyleData, setWidgetStyleData] = useState({})
  const [apiLoaded, setApiLoaded] = useState(false)
  const [widgetApiResData, setWidgetApiResData] = useState({})
  const [socialData, setSocialData] = useState({})
  const [socialAPI, setSocialAPI] = useState({})

  const [interestData, setInterestData] = useState([])
  const [virtualNumbers, setVirtualNumbers] = useState([])
  const [teamData, setTeamData] = useState([])
  const teamsData = useSelector((state) => state.home?.teamsData)
  const modernHeader = [, 'modern_header_title_status', 'modern_header_subtitle_status']

  useEffect(() => {

    if (!widget.id) return
    fetchSetting()


  }, [widget, widgetObject])
  
  useEffect(() => {

      fetchNumbers()
  


  }, [])

  useEffect(() => {
    if (teamsData) {
      const teams = teamsData?.map(team => {
        return team.name
      })
      setTeamData(teams);
    }

  }, [teamsData])

  useEffect(() => {
    if (bubbleSetting.position == '') return
    setApiLoaded(true)
  }, [bubbleSetting.position])


  const fetchSetting = async () => {


    await setWidgetALlSetting(widgetObject)
    await setWidgetApiData(widgetObject)
    await setWidget(widgetObject)
    await setBubble(widgetObject)
    let widgetData = widgetObject;
    widgetData["full_name_display_on_call_now"] = "on_call_now_screen";
    widgetData["full_name_display_on_digital_calls"] = "before_call";
    widgetData["full_name_display_on_call_later"] = "before_schedule";
    widgetData["email_display_on_call_now"] = "on_call_now_screen";
    widgetData["email_display_on_digital_calls"] = "before_call";
    widgetData["email_display_on"] = "before_schedule";
    widgetData["email_display_on_call_later"] = "before_schedule";


    setInterestData(widgetObject.interested)


    await setWidgetStyleData(widgetData)
    await setWidgetApiResData(widgetData)

    handleTabActive()




  }

  const postSetting = data => {
    loading(true)
    delete data?.bubble_popup_logo
    const updatedData = { ...data, ultimate_default_screen: data?.ultimate_default_screen?.toString() }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/set-customizations`
    return axios.post(url, updatedData)



  }
  const postModernSetting = data => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/modern-tabs`
    return axios.put(url, data)



  }
  const postModernHeaderSetting = data => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/modern-header`
    return axios.put(url, data)



  }
  const updateNumber = async (key, data) => {

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/dedicated-number-for-widget-text`
    await  axios.put(url, {[key]:data})?.then((res)=>reloadWidget())



  }
  const templateSetting = data => {
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/callback-template-config`
    return axios.put(url, data)



  }
  const fetchNumbers = async() => {
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-user-numbers?support_sms=1`
    await axios.get(url)?.then((res)=>setVirtualNumbers(res.data.data))



  }
  const mordenconfigSetting = data => {
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/modern-template-config`
    return axios.put(url, data)



  }

  const setWidget = data => {
    let widgetSettingData = { ...widgetSetting }
    const dataTemplateType = parseInt(data.template_type);
    if (dataTemplateType == 2) {
      widgetSettingData.template = 'Smart'
    } else if (dataTemplateType == 1) {
      widgetSettingData.template = 'Classic'
    } else if (dataTemplateType == 3) {
      widgetSettingData.template = 'Modern'
    }

    widgetSettingData.classicType =
      data.classic_template_design_type == 1 ? false : true
    widgetSettingData.font = data.font

    widgetSettingData.timerColor = data.popup_timer_color
    widgetSettingData.callColor = data.submit_request_call_color

    widgetSettingData.socialProof = data.social_proof
    widgetSettingData.legalNote = data.terms_and_conditions
    widgetSettingData.legalType = data.terms_type
    widgetSettingData.legalStatus = data.terms_and_conditions_status
    widgetSettingData.privateStatus = data.privacy_note_status
    widgetSettingData.privatePolicy = data.privacy_note

    if (widgetSettingData.legalType == 0) {
      widgetSettingData.legalType = 'Text'
    } else if (widgetSettingData.legalType == 1) {
      widgetSettingData.legalType = 'URL'
    } else {
      widgetSettingData.legalType = 'HTML'
    }

    widgetSettingData.webCall = data.digital_call
    widgetSettingData.callLeter = data.call_me_later
    widgetSettingData.callBack = data.call_now
    widgetSettingData.leaveMessage = data.leave_message

    widgetSettingData.webCallHeader = data.digital_call_tab_text
    widgetSettingData.webCallButton = data.digital_call_button_text
    widgetSettingData.webCallName = data.full_name_in_digital_calls_tab
    widgetSettingData.webCallEmail = data.email_in_digital_calls_tab
    widgetSettingData.webCallTeam = data.team_in_digital_calls_tab

    widgetSettingData.callLeterHeader = data.schedule_call_tab_text
    widgetSettingData.callLeterButton = data.offline_schedule_call_message
    widgetSettingData.callLeterName = data.full_name_in_call_later_tab

    widgetSettingData.callLeterEmail = data.email_in_call_later_tab
    widgetSettingData.callLeterTeam = data.team_in_call_later_tab

    widgetSettingData.callBackHeader = data.widget_text
    widgetSettingData.callBackButton = data.submit_request_call_text
    widgetSettingData.callBackName = data.full_name_in_call_now_tab
    widgetSettingData.callBackEmail = data.email_in_call_now_tab
    widgetSettingData.callBackTeam = data.team_in_call_now_tab

    widgetSettingData.callBackCompHeader = data.headline_in_callback_template
    widgetSettingData.callBackCompButton = data.cta_btn_callback_template
    widgetSettingData.callBackCompName = data.name_in_callback_template
    widgetSettingData.callBackCompEmail = data.email_in_callback_template
    widgetSettingData.callBackCompTeam = data.team_in_call_now_tab
    widgetSettingData.callBackCompComment = data.comment_in_callback_template




    widgetSettingData.leaveMessageHeader = data.leave_message_tab_text
    widgetSettingData.leaveMessageName = data.full_name_in_leave_message_tab
    widgetSettingData.leaveMessageEmail = data.email_in_leave_message_tab
    widgetSettingData.leaveMessageTeam = data.team_in_leave_message

    setWidgetSetting(widgetSettingData)
    setWidgetApiData(widgetSettingData)
  }

  const socialPost = () => {
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/social`
    return axios.put(url, { ...socialData })
      .then(res => {
        reloadWidget()
        loading(false)
        if (res.data.message[0] === 'Successfully') {
          handleTabActive()
          setIsInputChange(false)
          reloadWidget()
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })
  }
  const submitWidget = () => {
    if (Object?.keys(socialData)?.length > 0) {
      setSocialAPI({ ...socialData })
      socialPost()
    }

    const data = widgetStyleData

    if (data.shape === 'btnRight') {
      data.shape = 'square'
    } else {
      data.shape = 'default'
    }

    if (parseInt(widgetStyleData.template_type) === parseInt(6)) {
      templateSetting(data)
    }
    if (parseInt(widgetStyleData.template_type) === parseInt(3)) {
      postModernHeaderSetting(data)

    }
    if (parseInt(widgetStyleData.template_type) === parseInt(3)) {
      mordenconfigSetting(data)

    }

    postSetting(data).then(res => {
      reloadWidget()
      loading(false)
      if (res.data.message[0] === 'Successfully') {
        CommonNotify('Updated Widget Setting', 'success')
        handleTabActive()
        setIsInputChange(false)
        reloadWidget()
      }
    })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })
  }


  const resetWidget = data => {
    let widgetSetting = { ...widgetAllSetting }
    widgetSetting.template_type = 3
    if (data.template == 'Smart') {
      widgetSetting.template_type = 2
    } else if (data.template == 'Classic') {
      widgetSetting.template_type = 1
    }


    widgetSetting.classic_template_design_type =
      data.classicType == true ? 1 : 0


    widgetSetting.font = data.font
    widgetSetting.popup_timer_color = data.timerColor
    widgetSetting.submit_request_call_color = data.callColor

    widgetSetting.social_proof = data.socialProof
    widgetSetting.terms_and_conditions = data.legalNote
    widgetSetting.terms_type = data.legalType
    widgetSetting.terms_and_conditions_status = data.legalStatus
    widgetSetting.privacy_note_status = data.privateStatus
    widgetSetting.privacy_note = data.privatePolicy

    if (widgetSetting.terms_type == 'Text') {
      widgetSetting.terms_type = 0
    } else if (widgetSetting.terms_type == 'URL') {
      widgetSetting.terms_type = 1
    } else {
      widgetSetting.terms_type = 2
    }

    widgetSetting.digital_call = data.webCall
    widgetSetting.call_me_later = data.callLeter
    widgetSetting.call_now = data.callBack
    widgetSetting.leave_message = data.leaveMessage

    widgetSetting.digital_call_tab_text = data.webCallHeader
    widgetSetting.digital_call_button_text = data.webCallButton
    widgetSetting.full_name_in_digital_calls_tab = data.webCallName
    widgetSetting.email_in_digital_calls_tab = data.webCallEmail
    widgetSetting.team_in_digital_calls_tab = data.webCallTeam

    widgetSetting.schedule_call_tab_text = data.callLeterHeader
    widgetSetting.offline_schedule_call_message = data.callLeterButton
    widgetSetting.full_name_in_call_later_tab = data.callLeterName

    widgetSetting.email_in_call_later_tab = data.callLeterEmail
    widgetSetting.team_in_call_later_tab = data.callLeterTeam

    widgetSetting.widget_text = data.callBackHeader
    widgetSetting.submit_request_call_text = data.callBackButton
    widgetSetting.full_name_in_call_now_tab = data.callBackName
    widgetSetting.email_in_call_now_tab = data.callBackEmail
    widgetSetting.team_in_call_now_tab = data.callBackTeam


    widgetSetting.headline_in_callback_template = data.callBackCompHeader
    widgetSetting.cta_btn_callback_template = data.callBackCompButton
    widgetSetting.name_in_callback_template = data.callBackCompName
    widgetSetting.email_in_callback_template = data.callBackCompEmail
    widgetSetting.comment_in_callback_template = data.callBackCompTeam

    widgetSetting.leave_message_tab_text = data.leaveMessageHeader
    widgetSetting.full_name_in_leave_message_tab = data.leaveMessageName
    widgetSetting.email_in_leave_message_tab = data.leaveMessageEmail
    widgetSetting.team_in_leave_message = data.leaveMessageTeam
    setWidgetALlSetting(widgetSetting)
    return widgetSetting
  }

  const changeWidget = (index, value) => {
  console.log('index, value :', index, value);

    let widgetSettingNew = { ...widgetSetting }
    let widgetStyleDataNew = { ...widgetStyleData }
    if (value === true) {
      value = 1
    }
    if (value === false) {
      value = 0
    }
    widgetSettingNew[index] = value
    widgetStyleDataNew[index] = value
    if (index == 'template_type') {
      widgetSettingNew['template_type'] = String(value)
      widgetStyleDataNew['template_type'] = String(value)
      if (value == 1) widgetSettingNew['template'] = 'Classic'
      else if (value == 2) widgetSettingNew['template'] = 'Smart'
      else if (value == 3) widgetSettingNew['template'] = 'Modern'
    }
    setWidgetSetting({ ...widgetSettingNew })
    setWidgetStyleData({ ...widgetStyleDataNew })

    widgetSettDataUpdate({ ...widgetSettingNew })
    widgetStyledDataUpdate({ ...widgetStyleDataNew })

    const check = _.isEqual(widgetSettingNew, widgetApiData)

    if (check === false) {
      setIsInputChange(true)
    } else {
      setIsInputChange(false)
    }
  }
  const changeSocial = (index, value) => {

    let data = socialData
    let widgetStyleDataNew = { ...widgetStyleData }
    if (value === true) {
      value = 1
    } else if (value === false) {
      value = 0
    }
    data[index] = value
    widgetStyleDataNew[index] = value
    setWidgetStyleData({ ...widgetStyleDataNew })
    if (value === 0 || value === 1) {
      setSocialData({ ...data })
      socialPost()
    } else {
      const check = _.isEqual(data, socialAPI)
      if (check === false) {
        setIsInputChange(true)
        setSocialData({ ...data })
      } else {
        setIsInputChange(false)
      }

    }


  }
  const changeWidgetToggle = async (index, value) => {

    loading(true)

    let widgetStyleDataNew = { ...widgetStyleData }

    if (index == 'privacy_note_status' || index == 'call_me_later' || index == 'call_now' || index == 'digital_call' || index == 'call_btn_text_callback_template') {
      widgetStyleDataNew[index] = value;
    } else {
      widgetStyleDataNew[index] = value ? 1 : 0
    }


    if (widgetStyleDataNew.shape === 'btnRight') {
      widgetStyleDataNew.shape = 'square'
    } else {
      widgetStyleDataNew.shape = 'default'
    }

    await setWidgetStyleData({ ...widgetStyleDataNew })

    widgetStyledDataUpdate({ ...widgetStyleDataNew })
    if (modernHeader?.includes(index)) {
      postModernHeaderSetting(widgetStyleDataNew).then(res => {
        reloadWidget()
        loading(false)
        if (res.data.message[0] === 'Successfully') {
         CommonNotify('Updated Widget Setting', 'success')
        }
      })
        .catch(error => {
          loading(false)
        })
    } else if (index?.includes('template')) {

      templateSetting(widgetStyleDataNew).then(res => {
        reloadWidget()
        loading(false)
        if (res.data.message[0] === 'Successfully') {
          
          CommonNotify('Updated Widget Setting', 'success')
        }
      })
        .catch(error => {
          loading(false)
        })
      postSetting(widgetStyleDataNew).then(res => {
        reloadWidget()
        loading(false)
        if (res.data.message[0] === 'Successfully') {
          
          CommonNotify('Updated Widget Setting', 'success')
        }
      })
        .catch(error => {
          loading(false)
        })

    } else if (index?.includes('modern')) {

      if (index === 'modern_call_now' || index === 'modern_call_later' || index === 'modern_send_message') {

        postModernSetting(widgetStyleDataNew).then(res => {
          reloadWidget()
          loading(false)
          if (res.data.message[0] === 'Successfully') {
            CommonNotify('Updated Widget Setting', 'success')
          }
        })
          .catch(error => {
            loading(false)
          })
      } else {
        mordenconfigSetting(widgetStyleDataNew).then(res => {
          // reloadWidget()
          loading(false)
        })
          .catch(error => {
            loading(false)
          })
        postSetting(widgetStyleDataNew).then(res => {
          reloadWidget()
          loading(false)
          if (res.data.message[0] === 'Successfully') {

            
            CommonNotify('Updated Widget Setting', 'success')
          }
        })
          .catch(error => {
            loading(false)
          })

      }
    }



    else {
      postSetting(widgetStyleDataNew).then(res => {
        reloadWidget()
        loading(false)
        if (res.data.message[0] === 'Successfully') {
          
          CommonNotify('Updated Widget Setting', 'success')
        }
      })
        .catch(error => {
          loading(false)
        })
    }
  }
  const changeModernToggle = async (index, value) => {

    loading(true)

    let widgetStyleDataNew = { ...widgetStyleData }

    if (index == 'privacy_note_status' || index == 'call_me_later' || index == 'call_now' || index == 'digital_call') {
      widgetStyleDataNew[index] = value;
    } else {
      widgetStyleDataNew[index] = value ? 1 : 0
    }


    if (widgetStyleDataNew.shape === 'btnRight') {
      widgetStyleDataNew.shape = 'square'
    } else {
      widgetStyleDataNew.shape = 'default'
    }

    await setWidgetStyleData({ ...widgetStyleDataNew })

    widgetStyledDataUpdate({ ...widgetStyleDataNew })

    postModernSetting(widgetStyleDataNew).then(res => {
      reloadWidget()
      loading(false)
      if (res.data.message[0] === 'Successfully') {
        CommonNotify('Updated Widget Setting', 'success')
      }
    })
      .catch(error => {
        loading(false)
      })

  }

  const setBubble = data => {
    let bubbleSetting1 = { ...bubbleSetting }

    bubbleSetting1.shadow = data.bubble_template == 2 ? true : false
    bubbleSetting1.color = data.circle_color
    bubbleSetting1.textOffline = data.bubble_offline_text
    bubbleSetting1.textOnline = data.bubble_text

    if (data.bubble_position == 'bottom_right') {

    } else if (data.bubble_position == 'bottom_left') {

    } else if (data.bubble_position == 'top_right') {

    } else if (data.bubble_position == 'top_left') {

    }

    if (data.shape == 'square') {
      data.shape = 'btnRight'
    } else {
      data.shape = 'btnLeft'
    }

    bubbleSetting1.position = data.bubble_position
    bubbleSetting1.shape = data.shape

    setBubbleSetting(bubbleSetting1)
  }
  const Options = ['Classic', 'Smart', 'Modern', 'Callback', 'Ultimate']
  const defaultHomeScreenOptions = ['Home', 'Text', 'Contact us', 'Meeting', 'Callback']
  const defaultHomeScreenMap = {
    'Home': 0,
    'Text': 1,
    'Contact us': 2,
    'Meeting': 3,
    'Callback': 4
  }
  const termOption = ['Text', 'URL', 'HTML']
  const popup_font = [
    'Roboto Slab',
    'Karma',
    'acumin-pro',
    'objektiv',
    'museo-sans',
    'Roboto',
    'Source Sans',
    'poppins',
    'new-hero',
    'proxima-nova',
    'expressway'
  ]
  const onCancel = () => {
    setWidgetStyleData(widgetApiResData)
    setIsInputChange(false)
  }



  return (
    <div className="style-widget-wrapper accordion-widget">
      <div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">Theme</h3>

        <CommonSelect
          name="popupFont"
          className="popup-font-select"
          placeholder="Select Template"
          options={Options}
          value={
            parseInt(widgetStyleData.template_type) === parseInt(1)
              ? 'Classic'
              : parseInt(widgetStyleData.template_type) === parseInt(2)
                ? 'Smart'
                : parseInt(widgetStyleData.template_type) === parseInt(3)
                  ? 'Modern'

                  : parseInt(widgetStyleData.template_type) === parseInt(5) ?

                    'Ultimate' :
                    parseInt(widgetStyleData.template_type) === parseInt(6) ?
                      'Callback' :
                      ' '
          }
          onChange={(p1, p2, p3) => {
            changeWidget(
              'template_type',
              p2.value == 'Classic' ? 1 : p2.value === 'Smart' ? 2 : p2.value === 'Modern' ? 3 : p2.value === 'Ultimate' ? 5 : p2.value === 'Callback' ? 6 : 0
            )
          }
          }
        />

        <CommonButton
          onClick={submitWidget}
          type="button"
          content="Save"
          background="blue"
        />

      </div>
      <div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">Default home screen</h3>

        {console.log('widgetStyleData :', widgetStyleData)}
        <CommonSelect
          name="popupFont"
          className="popup-font-select"
          placeholder="Select default home screen"
          options={defaultHomeScreenOptions}
          value={defaultHomeScreenOptions[+widgetStyleData.ultimate_default_screen]}
          onChange={(p1, p2, p3) => {
          console.log('p1, p2, p3 :', p1, p2, p3);
            changeWidget(
              'ultimate_default_screen',
              defaultHomeScreenMap[p2.value]
            )
          }
          }
        />

        <CommonButton
          onClick={submitWidget}
          type="button"
          content="Save"
          background="blue"
        />

      </div>
      {parseInt(widgetStyleData.template_type) === parseInt(1) &&
        <>
           
          <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Widget Design Type</h3>

            <CommonSelect
              name="popupFont"
              className="popup-font-select"
              placeholder="Select Template"
              options={['Popup / modal style', 'Slide extension style']}
              value={
                widgetStyleData.classic_template_design_type === 0
                  ? 'Popup / modal style'
                  : 'Slide extension style'
              }
              onChange={(p1, p2, p3) =>
                changeWidget(
                  'classic_template_design_type',
                  p2.value === 'Popup / modal style' ? 0 : 1
                )
              }
            />
          </div>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(classicKeys?.digitalCall?.key, e)}
                dataToggle={getToggleObj('Web Call', 'Enable voice calls through your website.', classicKeys?.digitalCall?.key)}
                activeDefault={widgetStyleData?.[classicKeys?.digitalCall?.key]}
              />
            </div>



            {widgetStyleData?.[classicKeys?.digitalCall?.key] ? (
              <>
                <div>
                  <div className="call-tab-text">
                    <h3 className="call-title widget-sub-heading">Header text</h3>
                    <CommonInput
                      placeholder="Do you want us to call you in 28 seconds?"
                      name="call-me-now"
                      type="text"

                      value={widgetStyleData?.[classicKeys?.digitalCall?.title]}
                      onChange={event =>
                        changeWidget(
                          classicKeys?.digitalCall?.title,
                          event.target.value
                        )
                      }
                    />
                  </div>

                </div>
                <div>
                  {widgetSetting.webCall && (
                    <div className="call-tab-text">
                      <h3 className="call-title widget-sub-heading">Call to action text</h3>
                      {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '}  */}

                      <CommonInput
                        placeholder="Connect"
                        name="click-to-call"
                        type="text"

                        value={
                          widgetStyleData?.[classicKeys?.digitalCall?.cta]

                        }
                        onChange={event =>
                          changeWidget(
                            classicKeys?.digitalCall?.cta,
                            event.target.value
                          )
                        }
                      />
                    </div>
                  )}
                </div>

                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Lead Qualification Form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> If enabled, this form is shown before audio calls start. </p>{' '}

                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.digitalCall?.name, e)
                      }
                      dataToggle={getToggleObj("Name", ' ', classicKeys?.digitalCall?.name)}
                      activeDefault={widgetStyleData?.[classicKeys?.digitalCall?.name]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.digitalCall?.email, e)
                      }
                      dataToggle={getToggleObj("Email", ' ', classicKeys?.digitalCall?.email)}
                      activeDefault={widgetStyleData?.[classicKeys?.digitalCall?.email]}
                      dataToggleActive={widgetStyleData?.[classicKeys?.digitalCall?.email]}
                      emailToggle={emailToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.digitalCall?.team, e)
                      }
                      dataToggle={getToggleObj("Team", ' ', classicKeys?.digitalCall?.team)}
                      activeDefault={widgetStyleData?.[classicKeys?.digitalCall?.team]}
                      dataToggleActive={widgetStyleData?.[classicKeys?.digitalCall?.team]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.digitalCall?.company, e)
                      }
                      dataToggle={getToggleObj("Company", ' ', classicKeys?.digitalCall?.company)}
                      activeDefault={widgetStyleData?.[classicKeys?.digitalCall?.company]}
                      dataToggleActive={widgetStyleData?.[classicKeys?.digitalCall?.company]}

                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.digitalCall?.companySize, e)
                      }
                      dataToggle={getToggleObj("Company Size", ' ', classicKeys?.digitalCall?.companySize)}
                      activeDefault={widgetStyleData?.[classicKeys?.digitalCall?.companySize]}
                      dataToggleActive={widgetStyleData?.[classicKeys?.digitalCall?.companySize]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.digitalCall?.message, e)
                      }
                      dataToggle={getToggleObj("Message", ' ', classicKeys?.digitalCall?.message)}
                      activeDefault={widgetStyleData?.[classicKeys?.digitalCall?.message]}
                      dataToggleActive={widgetStyleData?.[classicKeys?.digitalCall?.message]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.digitalCall?.website, e)
                      }
                      dataToggle={getToggleObj("Website", ' ', classicKeys?.digitalCall?.website)}
                      activeDefault={widgetStyleData?.[classicKeys?.digitalCall?.website]}
                      dataToggleActive={widgetStyleData?.[classicKeys?.digitalCall?.website]}
                    />
                  </div>


                </div>

              </>
            ) : null}
          </section>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(classicKeys?.callNow?.key, e)}
                dataToggle={getToggleObj("Call Back", 'Offer Phone call-backs proactively on any web page.', classicKeys?.callNow?.key)}
                activeDefault={widgetStyleData?.[classicKeys?.callNow?.key]}
              />
            </div>

            {widgetStyleData?.[classicKeys?.callNow?.key] ? (


              <>
                <div>

                </div>

            


                <div className="call-tab-text">
                  <h3 className="call-title widget-sub-heading">{'Offline header text'}</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"

                    value={widgetStyleData?.[classicKeys?.callNow?.subTitle]}
                    onChange={event =>
                      changeWidget(
                        classicKeys?.callNow?.subTitle,
                        event.target.value
                      )
                    }
                  />
                </div>



                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Lead Qualification Form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> If enabled, this form is shown before audio calls start. </p>{' '}
             <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Online header text"}</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-back"
                    type="text"

                    value={

                      widgetStyleData?.[classicKeys?.callNow?.title]
                    }
                    onChange={event =>
                      changeWidget(classicKeys?.callNow?.title, event.target.value)
                    }
                  />
                </div>
                  <div className="call-tab-text">

                    <h3 className="call-title widget-sub-heading">Call to action text</h3>
                    {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                     */}
                    <CommonInput
                      placeholder="Call Me Now"
                      name="call-me-now"
                      type="text"
                      value={
                        widgetStyleData?.[classicKeys?.callNow?.cta] || null
                      }
                      onChange={event =>
                        changeWidget(
                          classicKeys?.callNow?.cta,
                          event.target.value
                        )
                      }
                    />
                  </div>

                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.callNow?.name, e)
                      }
                      dataToggle={getToggleObj('Name', ' ', classicKeys?.callNow?.name)}
                      activeDefault={widgetStyleData?.[classicKeys?.callNow?.name]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.callNow?.email, e)
                      }
                      dataToggle={getToggleObj('Email', ' ', classicKeys?.callNow?.email)}
                      activeDefault={widgetStyleData?.[classicKeys?.callNow?.email]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.callNow?.team, e)
                      }
                      dataToggle={getToggleObj('Team', ' ', classicKeys?.callNow?.team)}
                      activeDefault={widgetStyleData?.[classicKeys?.callNow?.team]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  {(parseInt(widgetStyleData.template_type) === parseInt(1) || widgetStyleData.template_type === parseInt(6)) &&
                    <>
                      <div className="display-content">
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(classicKeys?.callNow?.company, e)
                          }
                          dataToggle={getToggleObj('Company', ' ', classicKeys?.callNow?.company)}
                          activeDefault={widgetStyleData?.[classicKeys?.callNow?.company]
                          }
                        />
                      </div>
                      <div className="display-content">
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(classicKeys?.callNow?.companySize, e)
                          }
                          dataToggle={getToggleObj('Company Size', ' ', classicKeys?.callNow?.companySize)}
                          activeDefault={widgetStyleData?.[classicKeys?.callNow?.companySize]}
                        />
                      </div>
                      <div className="display-content">
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(classicKeys?.callNow?.message, e)
                          }
                          dataToggle={getToggleObj('Message', ' ', classicKeys?.callNow?.message)}
                          activeDefault={widgetStyleData?.[classicKeys?.callNow?.message]}
                        />
                      </div>
                      <div className="display-content">
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(classicKeys?.callNow?.website, e)
                          }
                          dataToggle={getToggleObj('Website', ' ', classicKeys?.callNow?.website)}
                          activeDefault={widgetStyleData?.[classicKeys?.callNow?.website]}
                        />
                      </div>
                    </>
                  }
                </div>
              </>

            ) : null}

          </section>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(classicKeys?.calllater?.key, e)}
                dataToggle={getToggleObj('Meetings', 'Your prospect can schedule phone calls and book appointments.', classicKeys?.calllater?.key)}
                activeDefault={widgetStyleData?.[classicKeys?.calllater?.key]}
              />

            </div>


            {widgetStyleData?.[classicKeys?.calllater?.key]


              ? (
                <>

                  <div>
                    <div className="call-tab-text">

                      <h3 className="call-title widget-sub-heading">Header text</h3>
                      {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to schedule a meeting </p>{' '}
                    */}

                      <CommonInput
                        placeholder="Would you like us to call you later ?"
                        name="call-me-now"
                        type="text"
                        defaultValue={widgetStyleData?.[classicKeys?.calllater?.key]}
                        value={
                          widgetStyleData?.[classicKeys?.calllater?.title]

                        }
                        onChange={event =>
                          changeWidget(
                            classicKeys?.calllater?.title,
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  <>

                    <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>
                      <h3 className="call-title widget-sub-heading">Lead form </h3>
                      <p className="accordion-description" style={{ color: '#9a9a9a' }}> This form collects user data when scheduling a meeting.</p>{' '}
                      <div className="call-tab-text">
                        <h3 className="call-title widget-sub-heading">Call to action text</h3>
                        {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                   */}
                        <CommonInput
                          placeholder="Call Me Now"
                          name="call-me-now"
                          type="text"

                          value={
                            widgetStyleData?.[classicKeys?.calllater?.cta]
                          }
                          onChange={event =>
                            changeWidget(
                              classicKeys?.calllater?.cta,
                              event.target.value
                            )
                          }
                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(classicKeys?.calllater?.name, e)
                          }
                          dataToggle={getToggleObj('Name', ' ', classicKeys?.calllater?.name)}
                          activeDefault={widgetStyleData?.[classicKeys?.calllater?.name]}
                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(classicKeys?.calllater?.email, e)
                          }
                          dataToggle={getToggleObj('Email', ' ', classicKeys?.calllater?.email)}
                          activeDefault={widgetStyleData?.[classicKeys?.calllater?.email]}
                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(classicKeys?.calllater?.team, e)
                          }
                          dataToggle={getToggleObj('Team', ' ', classicKeys?.calllater?.team)}
                          activeDefault={widgetStyleData?.[classicKeys?.calllater?.team]}
                        />
                      </div>

                      <>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(classicKeys?.calllater?.company, e)
                            }
                            dataToggle={getToggleObj('Company', ' ', classicKeys?.calllater?.company)}
                            activeDefault={widgetStyleData?.[classicKeys?.calllater?.company]}
                          />
                        </div>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(classicKeys?.calllater?.companySize, e)
                            }
                            dataToggle={getToggleObj('Company Size', ' ', classicKeys?.calllater?.companySize)}
                            activeDefault={widgetStyleData?.[classicKeys?.calllater?.companySize]}
                          />
                        </div>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(classicKeys?.calllater?.message, e)
                            }
                            dataToggle={getToggleObj('Message', ' ', classicKeys?.calllater?.message)}
                            activeDefault={widgetStyleData?.[classicKeys?.calllater?.message]}
                          />
                        </div>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(classicKeys?.calllater?.website, e)
                            }
                            dataToggle={getToggleObj('Website', ' ', classicKeys?.calllater?.website)}
                            activeDefault={widgetStyleData?.[classicKeys?.calllater?.website]}
                          />
                        </div>
                      </>
                    </div>
                  </>
                </>
              ) : null}

          </section>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(classicKeys?.leaveMessage?.key, e)}
                dataToggle={getToggleObj('Contact form', 'Generate more leads from your website.', 'leave_message')}
                activeDefault={widgetStyleData?.[classicKeys?.leaveMessage?.key]}
              />
            </div>

            {widgetStyleData?.[classicKeys?.leaveMessage?.key] ? (
              <>
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Header text"}</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to send a message </p>{' '}
                       */}

                  <CommonInput
                    placeholder="Leave your Message"
                    name="call-me-now"
                    type="text"

                    value={
                      widgetStyleData?.[classicKeys?.leaveMessage?.title]

                    }
                    onChange={e =>
                      changeWidget(classicKeys?.leaveMessage?.title, e.target.value)
                    }
                  />
                </div>
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Sub Header text"}</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to send a message </p>{' '}
                       */}

                  <CommonInput
                    placeholder="Leave your Message"
                    name="call-me-now"
                    type="text"

                    value={
                      widgetStyleData?.[classicKeys?.leaveMessage?.subTitle]

                    }
                    onChange={e =>
                      changeWidget(classicKeys?.leaveMessage?.subTitle, e.target.value)
                    }
                  />
                </div>
                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Pre contact form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> This form collects user data before the form is submitted. </p>{' '}
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.leaveMessage?.name, e)
                      }
                      dataToggle={getToggleObj('Name', ' ', classicKeys?.leaveMessage?.name)}
                      activeDefault={
                        widgetStyleData?.[classicKeys?.leaveMessage?.name]
                      }
                      fullNameToggle={leaveMessageName}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.leaveMessage?.email, e)
                      }
                      dataToggle={getToggleObj('Email', ' ', classicKeys?.leaveMessage?.email)}
                      activeDefault={widgetStyleData?.[classicKeys?.leaveMessage?.email]}
                      fullNameToggle={leaveMessageEmail}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.leaveMessage?.team, e)
                      }
                      dataToggle={getToggleObj('Team', ' ', classicKeys?.leaveMessage?.team)}
                      activeDefault={widgetStyleData?.[classicKeys?.leaveMessage?.team]}
                      fullNameToggle={leaveMessageTeam}
                    />
                  </div>


                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.leaveMessage?.company, e)
                      }
                      dataToggle={getToggleObj('Company', ' ', classicKeys?.leaveMessage?.company)}
                      activeDefault={widgetStyleData?.[classicKeys?.leaveMessage?.company]}
                      dataToggleActive={widgetStyleData?.[classicKeys?.leaveMessage?.company]}

                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.leaveMessage?.companySize, e)
                      }
                      dataToggle={getToggleObj('Company Size', ' ', classicKeys?.leaveMessage?.companySize)}
                      activeDefault={widgetStyleData?.[classicKeys?.leaveMessage?.companySize]}
                      dataToggleActive={widgetStyleData?.[classicKeys?.leaveMessage?.companySize]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(classicKeys?.leaveMessage?.website, e)
                      }
                      dataToggle={getToggleObj('Website', ' ', classicKeys?.leaveMessage?.website)}
                      activeDefault={widgetStyleData?.[classicKeys?.leaveMessage?.website]}
                      dataToggleActive={widgetStyleData?.[classicKeys?.leaveMessage?.website]}
                    />
                  </div>
                </div>


              </>
            ) : null}

          </section>

        </>


      }

      {parseInt(widgetStyleData.template_type) === parseInt(2) &&
        <>
       <div className="popup-font theme-selector">
            
            <h3 className="call-title widget-sub-heading">Title</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="Call Me Now"
                name="call-me-now"
                type="text"
                value={
                  widgetStyleData?.[smartKeys?.header]

                }
                onChange={event =>
                  changeWidget(
                    smartKeys?.header,
                    event.target.value,
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(smartKeys?.headerStatus, e)}
                dataToggle={getToggleObj('', ' ', smartKeys?.headerStatus)}
                activeDefault={widgetStyleData?.[smartKeys?.headerStatus]}
              />
            </div>

          </div>
          <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Subtitle</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="Call Me Now"
                name="call-me-now"
                type="text"
                value={
                  widgetStyleData?.[smartKeys?.subHeader]
                }
                onChange={event =>
                  changeWidget(
                    smartKeys?.subHeader,
                    event.target.value
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(smartKeys?.subHeaderStatus, e)}
                dataToggle={getToggleObj('', ' ', smartKeys?.subHeaderStatus)}
                activeDefault={widgetStyleData?.[smartKeys?.subHeaderStatus]}
              />
            </div>

          </div>   
        <section className="card-manage-number">
          <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>

            <NodeToggle
              handleDataRef={e => changeWidgetToggle(smartKeys?.callBack?.key, e)}
              dataToggle={getToggleObj('Callback', 'Offer Phone call-backs proactively on any web page.', smartKeys?.callBack?.key)}
              activeDefault={widgetStyleData?.[smartKeys?.callBack?.key]}
            />

          </div>
          <div className="call-tab-text">
            <h3 className="call-title widget-sub-heading">Call Button Text</h3>
            <CommonInput
              placeholder="Do you want us to call you in 28 seconds?"
              name="call-me-now"
              type="text"


              onChange={event =>
                changeWidget(
                  smartKeys?.callBack?.callButtonText,
                  event.target.value
                )
              }
              value={widgetStyleData?.[smartKeys?.callBack?.callButtonText]}

            />
          </div>
          {
            widgetStyleData?.[smartKeys?.callBack?.key]

              ? (   <>
                  <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>
                    <h3 className="call-title widget-sub-heading">Lead form </h3>
                    <p className="accordion-description" style={{ color: '#9a9a9a' }}> This form collects user data when scheduling a meeting.</p>{' '}
                    <div className="call-tab-text">
                      <h3 className="call-title widget-sub-heading">Call to action text</h3>
                      {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                  */}
                      <CommonInput
                        placeholder="Call Me Now"
                        name="call-me-now"
                        type="text"

                        value={
                          widgetStyleData?.[smartKeys?.callBack?.cta]
                        }
                        onChange={event =>
                          changeWidget(
                            smartKeys?.callBack?.cta,
                            event.target.value
                          )
                        }
                      />
                    </div>

                    <div>
                      <div className="call-tab-text">
                        <h3 className="call-title widget-sub-heading">Headline Text</h3>

                        <CommonInput
                          placeholder="Would you like us to call you later ?"
                          name="call-me-now"
                          type="text"
                          defaultValue={widgetStyleData?.[smartKeys?.callBack?.headline]}
                          value={
                            widgetStyleData?.[smartKeys?.callBack?.headline]
                          }
                          onChange={event =>
                            changeWidget(
                              smartKeys?.callBack?.headline,
                              event.target.value
                            )
                          }
                        />
                      </div>
                      {<div className="call-tab-text">
                        <h3 className="call-title widget-sub-heading">Subheadline Text</h3>
                        <CommonInput
                          placeholder="Would you like us to call you later ?"
                          name="call-me-now"
                          type="text"
                          defaultValue={widgetStyleData?.[smartKeys?.callBack?.subHeadline]}
                          value={
                            widgetStyleData?.[smartKeys?.callBack?.subHeadline]
                          }
                          onChange={event =>
                            changeWidget(
                              smartKeys?.callBack?.subHeadline,
                              event.target.value
                            )
                          }
                        />
                      </div>}

                    </div>
                    <div className="display-content">
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(smartKeys?.callBack?.name, e)
                        }
                        dataToggle={getToggleObj('Name', ' ', smartKeys?.callBack?.name)}
                        activeDefault={widgetStyleData?.[smartKeys?.callBack?.name]}
                      />
                    </div>
                    <div className="display-content">
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(smartKeys?.callBack?.email, e)
                        }
                        dataToggle={getToggleObj('Email', ' ', smartKeys?.callBack?.email)}
                        activeDefault={widgetStyleData?.[smartKeys?.callBack?.email]}

                      />
                    </div>
                    <div className="display-content">
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(smartKeys?.callBack?.team, e)
                        }
                        dataToggle={getToggleObj('Team', ' ', smartKeys?.callBack?.team)}
                        activeDefault={widgetStyleData?.[smartKeys?.callBack?.team]}

                      />
                    </div>


                    <div className="display-content">
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(smartKeys?.callBack?.comment, e)
                        }
                        dataToggle={getToggleObj('Comments', ' ', smartKeys?.callBack?.comment)}
                        activeDefault={widgetStyleData?.[smartKeys?.callBack?.comment]}

                      />
                    </div>


                    <div className="display-content">
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(smartKeys?.callBack?.company, e)
                        }
                        dataToggle={getToggleObj('Company', ' ', smartKeys?.callBack?.company)}
                        activeDefault={widgetStyleData?.[smartKeys?.callBack?.company]}

                      />
                    </div>
                    <div className="display-content">
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(smartKeys?.callBack?.website, e)
                        }
                        dataToggle={getToggleObj('Website', ' ', smartKeys?.callBack?.website)}
                        activeDefault={widgetStyleData?.[smartKeys?.callBack?.website]}

                      />
                    </div>


                  </div>


                </>

              ) : null}

        </section>       
          <section className="card-manage-number">
                           {/* <h3 className="call-title widget-sub-heading">{'Web Call'}</h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> Enable voice calls through your website. </p>{' '} */}
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                 handleDataRef={e =>
                        changeWidgetToggle(smartKeys?.digitalCall?.key, e)
                      }
                dataToggle={getToggleObj('Web Call', 'Enable voice calls through your website.', smartKeys?.digitalCall?.key)}
                activeDefault={widgetStyleData?.[smartKeys?.digitalCall?.key]}
              />
            </div>

              <>
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Header text"}</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
    */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"

                    value={widgetStyleData?.[smartKeys?.digitalCall?.title]}
                    onChange={event =>
                      changeWidget(
                        smartKeys?.digitalCall?.title,
                        event.target.value
                      )
                    }
                  />
                </div>
                <div>
                  {widgetStyleData.webCall ? (
                    <div className="call-tab-text">
                      <h3 className="call-title widget-sub-heading">{"Call to action text"} </h3>
                      {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '}  */}

                      <CommonInput
                        placeholder="Connect"
                        name="click-to-call"
                        type="text"
                        value={
                          widgetStyleData?.[smartKeys?.digitalCall?.cta]

                        }
                        onChange={event =>
                          changeWidget(
                            smartKeys?.digitalCall?.cta,
                            event.target.value
                          )
                        }
                      />
                    </div>
                  ) : null}
                </div>
                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Lead Qualification Form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> If enabled, this form is shown before audio calls start. </p>{' '}

                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(smartKeys?.digitalCall?.name, e)
                      }
                      dataToggle={getToggleObj('Name', ' ', smartKeys?.digitalCall?.name)}
                      activeDefault={widgetStyleData?.[smartKeys?.digitalCall?.name]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(smartKeys?.digitalCall?.email, e)
                      }
                      dataToggle={getToggleObj('Email', ' ', smartKeys?.digitalCall?.email)}
                      activeDefault={widgetStyleData?.[smartKeys?.digitalCall?.email]}
                      dataToggleActive={widgetStyleData?.[smartKeys?.digitalCall?.email]}
                      emailToggle={emailToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(smartKeys?.digitalCall?.team, e)
                      }
                      dataToggle={getToggleObj('Team', ' ', smartKeys?.digitalCall?.team)}
                      activeDefault={widgetStyleData?.[smartKeys?.digitalCall?.team]}
                      dataToggleActive={widgetStyleData?.[smartKeys?.digitalCall?.team]}
                    />
                  </div>
                </div>

              </>
          </section>
          <section className="card-manage-number">
            
                  <h3 className="call-title widget-sub-heading">{"Offline Callback (Meetings)"}</h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> Enable visitors to schedule a call during offline hours </p>{' '}
      

            <>
              <div>
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">Header text</h3>
                  <CommonInput
                    placeholder="Would you like us to call you later ?"
                    name="call-me-now"
                    type="text"
                    defaultValue={widgetStyleData?.[smartKeys?.calllater?.title]}
                    value={
                      widgetStyleData?.[smartKeys?.calllater?.title]
                    }
                    onChange={(event) =>
                      changeWidget(
                        smartKeys?.calllater?.title,
                        event.target.value
                      )
                    }
                  />
                </div>
              </div>


              <>

                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>
                  <h3 className="call-title widget-sub-heading">Lead form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> This form collects user data when scheduling a meeting.</p>{' '}
                  <div className="call-tab-text">
                    <h3 className="call-title widget-sub-heading">Call button text</h3>
                    <CommonInput
                      placeholder="Call Me Now"
                      name="call-me-now"
                      type="text"

                      value={
                        widgetStyleData?.[smartKeys?.calllater?.cta]
                      }
                      onChange={event =>
                        changeWidget(
                          smartKeys?.calllater?.cta,
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(smartKeys?.calllater?.name, e)
                      }
                      dataToggle={getToggleObj('Name', ' ', smartKeys?.calllater?.name)}
                      activeDefault={widgetStyleData?.[smartKeys?.calllater?.name]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(smartKeys?.calllater?.email, e)
                      }
                      dataToggle={getToggleObj('Email', ' ', smartKeys?.calllater?.email)}
                      activeDefault={widgetStyleData?.[smartKeys?.calllater?.email]}

                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(smartKeys?.calllater?.team, e)
                      }
                      dataToggle={getToggleObj('Team', ' ', smartKeys?.calllater?.team)}
                      activeDefault={widgetStyleData?.[smartKeys?.calllater?.team]}

                    />
                  </div>

                </div>


              </>
            </>


          </section>
        </>

      }

      {parseInt(widgetStyleData.template_type) === parseInt(3) &&

        <>
    
          <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Greeting</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="Hi"
                name="call-me-now"
                type="text"
                value={
                  widgetStyleData?.[modernKeys?.header]

                }
                onChange={event =>
                  changeWidget(
                    modernKeys?.header,
                    event.target.value,
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(modernKeys?.headerStatus, e)}
                dataToggle={getToggleObj('', ' ', modernKeys?.headerStatus)}
                activeDefault={widgetStyleData?.[modernKeys?.headerStatus]}
              />
            </div>

          </div>
          <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Introduction</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="How can we help you?"
                name="call-me-now"
                type="text"
                value={
                  widgetStyleData?.[modernKeys?.subHeader]
                }
                onChange={event =>
                  changeWidget(
                    modernKeys?.subHeader,
                    event.target.value
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(modernKeys?.subHeaderStatus, e)}
                dataToggle={getToggleObj('', ' ', modernKeys?.subHeaderStatus)}
                activeDefault={widgetStyleData?.[modernKeys?.subHeaderStatus]}
              />
            </div>

          </div>
        <div className="popup-font"  >
          <LinkIcon width={35} style={{
            width: '35px',
            height: '30px',
            marginTop: '-6px',
          }}
            className="mr-2" />
          <h3 className="call-title widget-sub-heading">Custom Link 1</h3>

          <div className="header-swich" >
            <div className="w-100" style={{ maxWidth: 291 }}>
              {widgetStyleData?.custom1_call_linkstatus ?
                <>

                  <CommonInput
                    title="Link name:"
                    className="popup-font-select"
                    placeholder="Label"
                    name="call-me-now"
                    type="text"
                    value={widgetStyleData?.custom1_call_name
                    }
                    onChange={event =>
                      changeWidget(
                        'custom1_call_name',
                        event.target.value
                      )
                    }
                  />

                  <CommonInput
                    className="popup-font-select"
                    placeholder="Url link"
                    name="call-me-now"
                    type="text"
                    value={widgetStyleData?.custom1_call_link
                    }
                    onChange={event =>
                      changeWidget(
                        'custom1_call_link',
                        event.target.value
                      )
                    }
                    example={'example:https://limecall.com'}
                  />
                </> : null
              }
            </div>
            <NodeToggle
              style={{
              }}
              handleDataRef={e => changeWidgetToggle('custom1_call_linkstatus', e)}
              dataToggle={{
                callId: 'custom1_call_linkstatus',
                callRef: 'custom1_call_linkstatus'
              }}
              activeDefault={widgetStyleData?.custom1_call_linkstatus}
              setText={{ 'off': 'Hidden', 'on': 'Visible' }}

            />

          </div>

        </div>

        <div className="popup-font"  >
          <LinkIcon width={35} style={{
            width: '35px',
            height: '30px',
            marginTop: '-6px',
          }}
            className="mr-2" />
          <h3 className="call-title widget-sub-heading">Custom Link 2</h3>

          <div className="header-swich" >
            <div className="w-100" style={{ maxWidth: 291 }}>
              {widgetStyleData?.custom2_call_linkstatus ?
                <>
                  <CommonInput
                    title="Link name:"

                    className="popup-font-select"
                    placeholder="Label"
                    name="call-me-now"
                    type="text"
                    value={widgetStyleData?.custom2_call_name
                    }
                    onChange={event =>
                      changeWidget(
                        'custom2_call_name',
                        event.target.value
                      )
                    }
                  />

                  <CommonInput
                    className="popup-font-select"
                    placeholder="Url link"
                    name="call-me-now"
                    type="text"
                    value={widgetStyleData?.custom2_call_link
                    }
                    onChange={event =>
                      changeWidget(
                        'custom2_call_link',
                        event.target.value
                      )
                    }
                    example={'example:https://limecall.com'}
                  />
                </> : null}
            </div>
            <NodeToggle
              style={{
              }}
              handleDataRef={e => changeWidgetToggle('custom2_call_linkstatus', e)}
              dataToggle={{
                callId: 'custom2_call_linkstatus',
                callRef: 'custom2_call_linkstatus'
              }}
              activeDefault={widgetStyleData?.custom2_call_linkstatus}
              setText={{ 'off': 'Hidden', 'on': 'Visible' }}

            />

          </div>

        </div>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(modernKeys?.webCall?.key, e)}
                dataToggle={getToggleObj('Web Call', 'Enable voice calls through your website.', modernKeys?.webCall?.key)}
                activeDefault={widgetStyleData?.[modernKeys?.webCall?.key]}
              />
            </div>



            {widgetStyleData?.[modernKeys?.webCall?.key] ? (
              <>

             
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Button Title"}</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"

                    value={widgetStyleData[modernKeys?.webCall?.title] || null}
                    onChange={event =>
                      changeWidget(
                        modernKeys?.webCall?.title,
                        event.target.value
                      )
                    }
                  />
                </div>
                <div>

                  <div className="call-tab-text">
                    <h3 className="call-title widget-sub-heading">{"Button Subtitle"} </h3>
                    {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '}  */}

                    <CommonInput
                      placeholder="Connect"
                      name="click-to-call"
                      type="text"
                      value={
                        widgetStyleData[modernKeys?.webCall?.subTitle]

                      }
                      onChange={event =>
                        changeWidget(
                          modernKeys?.webCall?.subTitle,
                          event.target.value
                        )
                      }
                    />
                  </div>

                </div>

                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Lead Qualification Form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> If enabled, this form is shown before audio calls start. </p>{' '}
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Header Text"}</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"

                    value={widgetStyleData[modernKeys?.webCall?.headerText]}
                    onChange={event =>
                      changeWidget(
                        modernKeys?.webCall?.headerText,
                        event.target.value
                      )
                    }
                  />
                </div>
                  <div className="call-tab-text">
                    <h3 className="call-title widget-sub-heading">Call to action text</h3>
                    {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '}  */}

                    <CommonInput
                      placeholder="Connect"
                      name="click-to-call"
                      type="text"

                      value={
                        widgetStyleData[modernKeys?.webCall?.cta]
                      }
                      onChange={event =>
                        changeWidget(
                          modernKeys?.webCall?.cta,
                          event.target.value
                        )
                      }
                    />
                  </div>

                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.webCall?.name, e)
                      }
                      dataToggle={getToggleObj('Name', ' ', modernKeys?.webCall?.name)}
                      activeDefault={widgetStyleData?.[modernKeys?.webCall?.name]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.webCall?.email, e)
                      }
                      dataToggle={getToggleObj('Email', ' ', modernKeys?.webCall?.email)}
                      activeDefault={widgetStyleData?.[modernKeys?.webCall?.email]}
                      dataToggleActive={widgetStyleData?.[modernKeys?.webCall?.email]}
                      emailToggle={emailToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.webCall?.team, e)
                      }
                      dataToggle={getToggleObj('Team', ' ', modernKeys?.webCall?.team)}
                      activeDefault={widgetStyleData?.[modernKeys?.webCall?.team]}
                      dataToggleActive={widgetStyleData?.[modernKeys?.webCall?.team]}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.webCall?.company, e)
                      }
                      dataToggle={getToggleObj('Company', ' ', modernKeys?.webCall?.company)}
                      activeDefault={widgetStyleData?.[modernKeys?.webCall?.company]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.webCall?.companySize, e)
                      }
                      dataToggle={getToggleObj('Company Size', ' ', modernKeys?.webCall?.companySize)}
                      activeDefault={widgetStyleData?.[modernKeys?.webCall?.companySize]}
                      dataToggleActive={widgetStyleData?.[modernKeys?.webCall?.companySize]}
                      emailToggle={emailToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.webCall?.website, e)
                      }
                      dataToggle={getToggleObj('Website', ' ', modernKeys?.webCall?.website)}
                      activeDefault={widgetStyleData?.[modernKeys?.webCall?.website]}
                      dataToggleActive={widgetStyleData?.[modernKeys?.webCall?.website]}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.webCall?.message, e)
                      }
                      dataToggle={getToggleObj('Message', ' ', modernKeys?.webCall?.message)}
                      activeDefault={widgetStyleData?.[modernKeys?.webCall?.message]}
                      dataToggleActive={widgetStyleData?.[modernKeys?.webCall?.message]}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.webCall?.phone, e)
                      }
                      dataToggle={getToggleObj('Phone Number', ' ', modernKeys?.webCall?.phone)}
                      activeDefault={widgetStyleData?.[modernKeys?.webCall?.phone]}
                      dataToggleActive={widgetStyleData?.[modernKeys?.webCall?.phone]}
                    />
                  </div>
                </div>

              </>
            ) : null}
          </section>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(modernKeys?.callBack?.key || 'call_now', e)}
                dataToggle={getToggleObj("Call Back", 'Offer Phone call-backs proactively on any web page.', 'call_now')}
                activeDefault={modernKeys?.callBack?.key ? widgetStyleData?.[modernKeys?.callBack?.key] : widgetStyleData.call_now}
              />
            </div>

            {widgetStyleData?.[modernKeys?.callBack?.key] ? (

              <>


                <div>

                </div>
 
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Button Title"}</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-back"
                    type="text"

                    value={
                      widgetStyleData?.[modernKeys?.callBack?.title] || null
                    }
                    onChange={event =>
                      changeWidget(modernKeys?.callBack?.title, event.target.value)
                    }
                  />
                </div>
                <div className="call-tab-text">
                  <h3 className="call-title widget-sub-heading">{'Button SubTitle'}</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"

                    value={widgetStyleData?.[modernKeys?.callBack?.subTitle]}
                    onChange={event =>
                      changeWidget(
                        modernKeys?.callBack?.subTitle,
                        event.target.value
                      )
                    }
                  />
                </div>



                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Lead Qualification Form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> If enabled, this form is shown before audio calls start. </p>{' '}
   <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Header Text"}</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"

                    value={widgetStyleData[modernKeys?.callBack?.headerText]}
                    onChange={event =>
                      changeWidget(
                        modernKeys?.callBack?.headerText,
                        event.target.value
                      )
                    }
                  />
                </div>
                  <div className="call-tab-text">

                    <h3 className="call-title widget-sub-heading">Call to action text</h3>
                    {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                     */}
                    <CommonInput
                      placeholder="Call Me Now"
                      name="call-me-now"
                      type="text"
                      value={
                        widgetStyleData[modernKeys?.callBack?.cta]
                      }
                      onChange={event =>
                        changeWidget(
                          modernKeys?.callBack?.cta,
                          event.target.value
                        )
                      }
                    />
                  </div>

                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.callBack?.name, e)
                      }
                      dataToggle={getToggleObj('Name', ' ', modernKeys?.callBack?.name)}
                      activeDefault={widgetStyleData?.[modernKeys?.callBack?.name]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.callBack?.email, e)
                      }
                      dataToggle={getToggleObj('Email', ' ', modernKeys?.callBack?.email)}
                      activeDefault={widgetStyleData?.[modernKeys?.callBack?.email]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.callBack?.team, e)
                      }
                      dataToggle={getToggleObj('Team', ' ', modernKeys?.callBack?.team)}
                      activeDefault={widgetStyleData?.[modernKeys?.callBack?.team]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.callBack?.company, e)
                      }
                      dataToggle={getToggleObj('Company', ' ', modernKeys?.callBack?.company)}
                      activeDefault={widgetStyleData?.[modernKeys?.callBack?.company]}
                      dataToggleActive={widgetStyleData?.[modernKeys?.callBack?.company]}

                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.callBack?.companySize, e)
                      }
                      dataToggle={getToggleObj('Company Size', ' ', modernKeys?.callBack?.companySize)}
                      activeDefault={widgetStyleData?.[modernKeys?.callBack?.companySize]}
                      dataToggleActive={widgetStyleData?.[modernKeys?.callBack?.companySize]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.callBack?.website, e)
                      }
                      dataToggle={getToggleObj('Website', ' ', modernKeys?.callBack?.website)}
                      activeDefault={widgetStyleData?.[modernKeys?.callBack?.website]}
                      dataToggleActive={widgetStyleData?.[modernKeys?.callBack?.website]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(modernKeys?.callBack?.message, e)
                      }
                      dataToggle={getToggleObj('Message', ' ', modernKeys?.callBack?.message)}
                      activeDefault={widgetStyleData?.[modernKeys?.callBack?.message]}
                      dataToggleActive={widgetStyleData?.[modernKeys?.callBack?.message]}
                    />
                  </div>
                </div>

              </>

            ) : null}

          </section>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(modernKeys?.scheduleCall?.key, e)}
                dataToggle={getToggleObj('Meetings', 'Your prospect can schedule phone calls and book appointments.', modernKeys?.scheduleCall?.key)}
                activeDefault={modernKeys?.scheduleCall?.key ? widgetStyleData?.[modernKeys?.scheduleCall?.key] : widgetStyleData.call_me_later}
              />

            </div>
            {/* <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(modernKeys?.scheduleCall?.liveCallBack, e)}
                dataToggle={getToggleObj('Live Callbacks','Enable live phone callbacks from your widget.',modernKeys?.scheduleCall?.liveCallBack)}
                activeDefault={widgetStyleData?.[modernKeys?.scheduleCall?.liveCallBack]}
              /></div> */}


            {
              widgetStyleData?.[modernKeys?.scheduleCall?.key]


                ? (
                  <>

                    {widgetStyleData.template_type !== parseInt(1) ? (
                      <div>
                       
                        <div className="call-tab-text">
                          <h3 className="call-title widget-sub-heading">{
                            parseInt(widgetStyleData.template_type) === parseInt(3) ? "Button Title" : "Header text"}</h3>
                          {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to schedule a meeting </p>{' '}
                   */}

                          <CommonInput
                            placeholder="Would you like us to call you later ?"
                            name="call-me-now"
                            type="text"
                            defaultValue={widgetStyleData[modernKeys?.scheduleCall?.title] || null }
                            value={
                              widgetStyleData?.[modernKeys?.scheduleCall?.title]  || null
                            }
                            onChange={event =>
                              changeWidget(
                                modernKeys?.scheduleCall?.title,
                                event.target.value
                              )
                            }
                          />
                        </div>
                        {(parseInt(widgetStyleData.template_type) === parseInt(6) || parseInt(widgetStyleData.template_type) === parseInt(3)) &&
                          <div className="call-tab-text">
                            <h3 className="call-title widget-sub-heading">Subheadline Text</h3>
                            {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to schedule a meeting </p>{' '}
                   */}

                            <CommonInput
                              placeholder="Would you like us to call you later ?"
                              name="call-me-now"
                              type="text"
                              defaultValue={widgetStyleData?.[modernKeys?.scheduleCall?.subTitle]}
                              value={
                                widgetStyleData?.[modernKeys?.scheduleCall?.subTitle]
                              }
                              onChange={event =>
                                changeWidget(
                                  modernKeys?.scheduleCall?.subTitle,
                                  event.target.value
                                )
                              }
                            />
                          </div>}

                      </div>
                    ) : (
                      <div>
                        <div className="call-tab-text">

                          <h3 className="call-title widget-sub-heading">Header text</h3>
                          {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to schedule a meeting </p>{' '}
                    */}

                          <CommonInput
                            placeholder="Would you like us to call you later ?"
                            name="call-me-now"
                            type="text"
                            defaultValue={widgetStyleData.offline_schedule_call_message}
                            value={
                              widgetStyleData.schedule_call_tab_text
                                ? widgetStyleData.schedule_call_tab_text
                                : null
                            }
                            onChange={event =>
                              changeWidget(
                                'schedule_call_tab_text',
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    )}

                    <>

                      <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>
                        <h3 className="call-title widget-sub-heading">Lead form </h3>
                        <p className="accordion-description" style={{ color: '#9a9a9a' }}> This form collects user data when scheduling a meeting.</p>{' '}
                       
                      <div className="call-tab-text">

                        <h3 className="call-title widget-sub-heading">{"Header Text"}</h3>
                        {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                        <CommonInput
                          placeholder="Do you want us to call you in 28 seconds?"
                          name="call-me-now"
                          type="text"

                          value={widgetStyleData[modernKeys?.scheduleCall?.headerText]}
                          onChange={event =>
                            changeWidget(
                              modernKeys?.scheduleCall?.headerText,
                              event.target.value
                            )
                          }
                        />
                      </div>
                       
                        <div className="call-tab-text">
                          <h3 className="call-title widget-sub-heading">Call to action text</h3>
                          {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                   */}
                          <CommonInput
                            placeholder="Call Me Now"
                            name="call-me-now"
                            type="text"

                            value={
                              modernKeys?.scheduleCall?.cta ? widgetStyleData[modernKeys?.scheduleCall?.cta] : (parseInt(widgetStyleData.template_type) === parseInt(6) ? widgetStyleData.cta_btn_callback_template : widgetStyleData.offline_schedule_call_message
                                ? widgetStyleData.call_latter_button
                                : null)
                            }
                            onChange={event =>
                              changeWidget(
                                modernKeys?.scheduleCall?.cta ? modernKeys?.scheduleCall?.cta : parseInt(widgetStyleData.template_type) === parseInt(6) ? 'cta_btn_callback_template' : 'offline_schedule_call_message',
                                event.target.value
                              )
                            }
                          />
                        </div>
                        <div className="display-content">
                          {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(modernKeys?.scheduleCall?.name, e)
                            }
                            dataToggle={getToggleObj('Name', ' ', modernKeys?.scheduleCall?.name)}
                            activeDefault={widgetStyleData?.[modernKeys?.scheduleCall?.name]}
                            fullNameToggle={fullNameToggle}
                          />
                        </div>
                        <div className="display-content">
                          {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(modernKeys?.scheduleCall?.email, e)
                            }
                            dataToggle={getToggleObj('Email', ' ', modernKeys?.scheduleCall?.email)}
                            activeDefault={widgetStyleData?.[modernKeys?.scheduleCall?.email]}
                            fullNameToggle={fullNameToggle}
                          />
                        </div>
                        <div className="display-content">
                          {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(modernKeys?.scheduleCall?.team, e)
                            }
                            dataToggle={getToggleObj('Team', ' ', modernKeys?.scheduleCall?.team)}
                            activeDefault={widgetStyleData?.[modernKeys?.scheduleCall?.team]}
                            fullNameToggle={fullNameToggle}
                          />
                        </div>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(modernKeys?.scheduleCall?.company, e)
                            }
                            dataToggle={getToggleObj('Company', ' ', modernKeys?.scheduleCall?.company)}
                            activeDefault={widgetStyleData?.[modernKeys?.scheduleCall?.company]}
                            fullNameToggle={fullNameToggle}
                          />
                        </div>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(modernKeys?.scheduleCall?.companySize, e)
                            }
                            dataToggle={getToggleObj('Company Size', ' ', modernKeys?.scheduleCall?.companySize)}
                            activeDefault={widgetStyleData?.[modernKeys?.scheduleCall?.companySize]}
                            dataToggleActive={widgetStyleData?.[modernKeys?.scheduleCall?.companySize]}
                            emailToggle={emailToggle}
                          />
                        </div>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(modernKeys?.scheduleCall?.website, e)
                            }
                            dataToggle={getToggleObj('Website', ' ', modernKeys?.scheduleCall?.website)}
                            activeDefault={widgetStyleData?.[modernKeys?.scheduleCall?.website]}
                            dataToggleActive={widgetStyleData?.[modernKeys?.scheduleCall?.website]}
                          />
                        </div>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(modernKeys?.scheduleCall?.message, e)
                            }
                            dataToggle={getToggleObj('Message', ' ', modernKeys?.scheduleCall?.message)}
                            activeDefault={widgetStyleData?.[modernKeys?.scheduleCall?.message]}
                            dataToggleActive={widgetStyleData?.[modernKeys?.scheduleCall?.message]}
                          />
                        </div>



                      </div>


                    </>
                  </>
                ) : null}

          </section>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(modernKeys?.leaveMessage?.key, e)}
                dataToggle={getToggleObj('Contact Us', 'Let your website visitors send you a contact message.', 'modern_send_message')}
                activeDefault={modernKeys?.leaveMessage?.key ? widgetStyleData?.[modernKeys?.leaveMessage?.key] : widgetStyleData.leave_message}
              />
            </div>

            {(parseInt(widgetStyleData.template_type) === parseInt(3) ? widgetStyleData.modern_send_message : widgetStyleData.leave_message) ? (
              <>


                <>

          

                  <div className="call-tab-text">
                  <h3 className="call-title widget-sub-heading">{"Button Title"}</h3>

                    <CommonInput
                      placeholder="Do you want us to call you in 28 seconds?"
                      name="call-me-back"
                      type="text"

                      value={
                        widgetStyleData[modernKeys?.leaveMessage?.title] || null
                      }
                      onChange={event =>
                        changeWidget(modernKeys?.leaveMessage?.title || 'widget_text', event.target.value)
                      }
                    />
                  </div>

                  <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">Button SubTitle</h3>

                    <CommonInput
                      placeholder="Call Me Now"
                      name="call-me-now"
                      type="text"
                      value={
                        modernKeys?.leaveMessage?.subTitle ? widgetStyleData[modernKeys?.leaveMessage?.subTitle] : (widgetStyleData.submit_request_call_text
                          ? widgetStyleData.submit_request_call_text
                          : null)
                      }
                      onChange={event =>
                        changeWidget(
                          modernKeys?.leaveMessage?.subTitle || 'submit_request_call_text',
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                    <h3 className="call-title widget-sub-heading">Contact Form </h3>
                    <p className="accordion-description" style={{ color: '#9a9a9a' }}> Leave a message to our team </p>{' '}

                  <div className="call-tab-text">

                    <h3 className="call-title widget-sub-heading">{"Header Text"}</h3>
                    {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                    <CommonInput
                      placeholder="Do you want us to call you in 28 seconds?"
                      name="call-me-now"
                      type="text"

                      value={widgetStyleData[modernKeys?.leaveMessage?.headerText]}
                      onChange={event =>
                        changeWidget(
                          modernKeys?.leaveMessage?.headerText,
                          event.target.value
                        )
                      }
                    />
                  </div>

                    <div className="call-tab-text">
                      <h3 className="call-title widget-sub-heading">Call to action text</h3>
                      {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '}  */}

                      <CommonInput
                        placeholder="Connect"
                        name="click-to-call"
                        type="text"
                        value={
                          widgetStyleData[modernKeys?.leaveMessage?.cta] || null
                        }
                        onChange={event =>
                          changeWidget(
                            modernKeys?.leaveMessage?.cta,   event.target.value
                          )
                        }
                      />
                    </div>
                    <div className="display-content">
                      {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(modernKeys?.leaveMessage?.name, e)
                        }
                        dataToggle={getToggleObj('Name', ' ', modernKeys?.leaveMessage?.name)}
                        activeDefault={widgetStyleData?.[modernKeys?.leaveMessage?.name]}
                        fullNameToggle={fullNameToggle}
                      />
                    </div>
                    <div className="display-content">
                      {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(modernKeys?.leaveMessage?.email, e)
                        }
                        dataToggle={getToggleObj('Email', ' ', modernKeys?.leaveMessage?.email)}
                        activeDefault={widgetStyleData?.[modernKeys?.leaveMessage?.email]}
                        fullNameToggle={fullNameToggle}
                      />
                    </div>
                    <div className="display-content">
                      {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(modernKeys?.leaveMessage?.team, e)
                        }
                        dataToggle={getToggleObj('Team', ' ', modernKeys?.leaveMessage?.team)}
                        activeDefault={widgetStyleData?.[modernKeys?.leaveMessage?.team]}
                        fullNameToggle={fullNameToggle}
                      />
                    </div>
                    <div className="display-content">
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(modernKeys?.leaveMessage?.company, e)
                        }
                        dataToggle={getToggleObj('Company', ' ', modernKeys?.leaveMessage?.company)}
                        activeDefault={widgetStyleData?.[modernKeys?.leaveMessage?.company]}
                        fullNameToggle={fullNameToggle}
                      />
                    </div>
                    <div className="display-content">
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(modernKeys?.leaveMessage?.companySize, e)
                        }
                        dataToggle={getToggleObj('Company Size', ' ', modernKeys?.leaveMessage?.companySize)}
                        activeDefault={widgetStyleData?.[modernKeys?.leaveMessage?.companySize]}
                        dataToggleActive={widgetStyleData?.[modernKeys?.leaveMessage?.companySize]}
                        emailToggle={emailToggle}
                      />
                    </div>
                    <div className="display-content">
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(modernKeys?.leaveMessage?.website, e)
                        }
                        dataToggle={getToggleObj('Website', ' ', modernKeys?.leaveMessage?.website)}
                        activeDefault={widgetStyleData?.[modernKeys?.leaveMessage?.website]}
                        dataToggleActive={widgetStyleData?.[modernKeys?.leaveMessage?.website]}
                      />
                    </div>

                    <div className="display-content">
                      <NodeToggle
                        handleDataRef={e =>
                          changeWidgetToggle(modernKeys?.leaveMessage?.phone, e)
                        }
                        dataToggle={getToggleObj('Phone Number', ' ', modernKeys?.leaveMessage?.phone)}
                        activeDefault={widgetStyleData?.[modernKeys?.leaveMessage?.phone]}
                        dataToggleActive={widgetStyleData?.[modernKeys?.leaveMessage?.phone]}
                      />
                    </div>

                  </div>
                </>
              </>) : null}

          </section>
        </>
      }

      {parseInt(widgetStyleData.template_type) === parseInt(5) &&
        <>
  
          <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Greeting</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="Hi"
                name="call-me-now"
                type="text"
                value={
                  widgetStyleData?.[ultimateKeys?.header]

                }
                onChange={event =>
                  changeWidget(
                    ultimateKeys?.header,
                    event.target.value,
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.headerStatus, e)}
                dataToggle={getToggleObj('', ' ', ultimateKeys?.headerStatus)}
                activeDefault={widgetStyleData?.[ultimateKeys?.headerStatus]}
              />
            </div>

          </div>
          <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Introduction</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="How can we help you?"
                name="call-me-now"
                type="text"
                value={
                  widgetStyleData?.[ultimateKeys?.subHeader]
                }
                onChange={event =>
                  changeWidget(
                    ultimateKeys?.subHeader,
                    event.target.value
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.subHeaderStatus, e)}
                dataToggle={getToggleObj('', ' ', ultimateKeys?.subHeaderStatus)}
                activeDefault={widgetStyleData?.[ultimateKeys?.subHeaderStatus]}
              />
            </div>
          </div>

          <div className="popup-font"  >
            <LinkIcon width={35} style={{
              width: '35px',
              height: '30px',
              marginTop: '-6px',
            }}
              className="mr-2" />
            <h3 className="call-title widget-sub-heading">Custom Link 1</h3>

            <div className="header-swich" >
              <div className="w-100" style={{ maxWidth: 291 }}>
                {widgetStyleData?.custom1_call_linkstatus ?
                  <>

                   <CommonInput
                   title="Link name:"
                    className="popup-font-select"
                    placeholder="Label"
                    name="call-me-now"
                    type="text"
                    value={widgetStyleData?.custom1_call_name
                    }
                    onChange={event =>
                      changeWidget(
                        'custom1_call_name',
                        event.target.value
                      )
                    }
                  />

                    <CommonInput
                      className="popup-font-select"
                      placeholder="Url link"
                      name="call-me-now"
                      type="text"
                      value={widgetStyleData?.custom1_call_link
                      }
                      onChange={event =>
                        changeWidget(
                          'custom1_call_link',
                          event.target.value
                        )
                      }
                      example={'example:https://limecall.com'}
                    />
                  </> : null
                }
              </div>
              <NodeToggle
                style={{
                }}
                handleDataRef={e => changeWidgetToggle('custom1_call_linkstatus', e)}
                dataToggle={{
                  callId: 'custom1_call_linkstatus',
                  callRef: 'custom1_call_linkstatus'
                }}
                activeDefault={widgetStyleData?.custom1_call_linkstatus}
                setText={{ 'off': 'Hidden', 'on': 'Visible' }}

              />

            </div>

          </div>

          <div className="popup-font"  >
            <LinkIcon width={35} style={{
              width: '35px',
              height: '30px',
              marginTop: '-6px',
            }}
              className="mr-2" />
            <h3 className="call-title widget-sub-heading">Custom Link 2</h3>

            <div className="header-swich" >
              <div className="w-100" style={{ maxWidth: 291 }}>
                {widgetStyleData?.custom2_call_linkstatus ?
                  <>
                    <CommonInput
                   title="Link name:"
                    
                      className="popup-font-select"
                      placeholder="Label"
                      name="call-me-now"
                      type="text"
                      value={widgetStyleData?.custom2_call_name
                      }
                      onChange={event =>
                        changeWidget(
                          'custom2_call_name',
                          event.target.value
                        )
                      }
                    />

                    <CommonInput
                      className="popup-font-select"
                      placeholder="Url link"
                      name="call-me-now"
                      type="text"
                      value={widgetStyleData?.custom2_call_link
                      }
                      onChange={event =>
                        changeWidget(
                          'custom2_call_link',
                          event.target.value
                        )
                      }
                      example={'example:https://limecall.com'}
                    />
                  </> : null}
              </div>
              <NodeToggle
                style={{
                }}
                handleDataRef={e => changeWidgetToggle('custom2_call_linkstatus', e)}
                dataToggle={{
                  callId: 'custom2_call_linkstatus',
                  callRef: 'custom2_call_linkstatus'
                }}
                activeDefault={widgetStyleData?.custom2_call_linkstatus}
                setText={{ 'off': 'Hidden', 'on': 'Visible' }}

              />

            </div>

          </div>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.digitalCall?.key, e)}
                dataToggle={getToggleObj('Web Call', 'Enable voice calls through your website.', ultimateKeys?.digitalCall?.key)}
                activeDefault={widgetStyleData?.[ultimateKeys?.digitalCall?.key]}
              />
            </div>
         



            {widgetStyleData?.[ultimateKeys?.digitalCall?.key] ? (
              <>
              <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.digitalCall?.homeShow, e)}
                dataToggle={getToggleObj('Show this on homescreen', ' ', ultimateKeys?.digitalCall?.homeShow)}
                activeDefault={widgetStyleData?.[ultimateKeys?.digitalCall?.homeShow]}
              />
            </div>
             <div className="call-tab-text">
                <h3 className="call-title widget-sub-heading">{"Button Title"}</h3>

                    <CommonInput
                      placeholder="Do you want us to call you in 28 seconds?"
                      name="call-me-back"
                      type="text"

                      value={
                        widgetStyleData[ultimateKeys?.digitalCall?.title] || null
                      }
                      onChange={event =>
                        changeWidget(ultimateKeys?.digitalCall?.title || 'widget_text', event.target.value)
                      }
                    />
                  </div>

                  <div className="call-tab-text">

                <h3 className="call-title widget-sub-heading">Button SubTitle</h3>

                    <CommonInput
                      placeholder="Call Me Now"
                      name="call-me-now"
                      type="text"
                      value={
                       widgetStyleData[ultimateKeys?.digitalCall?.subTitle] || null
                      }
                      onChange={event =>
                        changeWidget(
                          ultimateKeys?.digitalCall?.subTitle || 'submit_request_call_text',
                          event.target.value
                        )
                      }
                    />
                  </div>
                
               

                
          

                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Lead Qualification Form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> If enabled, this form is shown before audio calls start. </p>{' '}
   <div className="call-tab-text">
                    <h3 className="call-title widget-sub-heading">Header text</h3>
                    <CommonInput
                      placeholder="Do you want us to call you in 28 seconds?"
                      name="call-me-now"
                      type="text"

                      value={widgetStyleData?.[ultimateKeys?.digitalCall?.headerText]}
                      onChange={event =>
                        changeWidget(
                          ultimateKeys?.digitalCall?.headerText,
                          event.target.value
                        )
                      }
                    />
                  </div>
                     <div className="call-tab-text">
                      <h3 className="call-title widget-sub-heading">Call to action text</h3>
                      {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '}  */}

                      <CommonInput
                        placeholder="Connect"
                        name="click-to-call"
                        type="text"

                        value={
                          widgetStyleData?.[ultimateKeys?.digitalCall?.cta]

                        }
                        onChange={event =>
                          changeWidget(
                            ultimateKeys?.digitalCall?.cta,
                            event.target.value
                          )
                        }
                      />
                    </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.digitalCall?.name, e)
                      }
                      dataToggle={getToggleObj("Name", ' ', ultimateKeys?.digitalCall?.name)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.digitalCall?.name]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.digitalCall?.email, e)
                      }
                      dataToggle={getToggleObj("Email", ' ', ultimateKeys?.digitalCall?.email)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.digitalCall?.email]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.digitalCall?.email]}
                      emailToggle={emailToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.digitalCall?.team, e)
                      }
                      dataToggle={getToggleObj("Team", ' ', ultimateKeys?.digitalCall?.team)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.digitalCall?.team]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.digitalCall?.team]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.digitalCall?.website, e)
                      }
                      dataToggle={getToggleObj("Website", ' ', ultimateKeys?.digitalCall?.website)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.digitalCall?.website]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.digitalCall?.website]}

                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.digitalCall?.phone, e)
                      }
                      dataToggle={getToggleObj("Phone", ' ', ultimateKeys?.digitalCall?.phone)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.digitalCall?.phone]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.digitalCall?.phone]}

                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.digitalCall?.company, e)
                      }
                      dataToggle={getToggleObj("Company", ' ', ultimateKeys?.digitalCall?.company)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.digitalCall?.company]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.digitalCall?.company]}

                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.digitalCall?.companySize, e)
                      }
                      dataToggle={getToggleObj("Company Size", ' ', ultimateKeys?.digitalCall?.companySize)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.digitalCall?.companySize]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.digitalCall?.companySize]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.digitalCall?.message, e)
                      }
                      dataToggle={getToggleObj("Message", ' ', ultimateKeys?.digitalCall?.message)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.digitalCall?.message]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.digitalCall?.message]}
                    />
                  </div>


                </div>

              </>
            ) : null}
          </section>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.callNow?.key, e)}
                dataToggle={getToggleObj("Call Back", 'Offer Phone call-backs proactively on any web page.', ultimateKeys?.callNow?.key)}
                activeDefault={widgetStyleData?.[ultimateKeys?.callNow?.key]}
              />
            </div>
         

            {widgetStyleData?.[ultimateKeys?.callNow?.key] ? (


              <>
                  <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.callNow?.homeShow, e)}
                dataToggle={getToggleObj('Show this on homescreen', ' ', ultimateKeys?.callNow?.homeShow)}
                activeDefault={widgetStyleData?.[ultimateKeys?.callNow?.homeShow]}
              />
            </div>
               <div className="call-tab-text">
                <h3 className="call-title widget-sub-heading">{"Button Title"}</h3>

                    <CommonInput
                      placeholder="Do you want us to call you in 28 seconds?"
                      name="call-me-back"
                      type="text"

                      value={
                        widgetStyleData[ultimateKeys?.callNow?.titleHome] || null
                      }
                      onChange={event =>
                        changeWidget(ultimateKeys?.callNow?.titleHome, event.target.value)
                      }
                    />
                  </div>

                  <div className="call-tab-text">

                <h3 className="call-title widget-sub-heading">Button SubTitle</h3>

                    <CommonInput
                      placeholder="Call Me Now"
                      name="call-me-now"
                      type="text"
                      value={
                       widgetStyleData[ultimateKeys?.callNow?.subTitleHome] || null
                      }
                      onChange={event =>
                        changeWidget(
                          ultimateKeys?.callNow?.subTitleHome,
                          event.target.value
                        )
                      }
                    />
                  </div>

                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Online header text"}</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-back"
                    type="text"

                    value={

                      widgetStyleData?.[ultimateKeys?.callNow?.onlineHeader]
                    }
                    onChange={event =>
                      changeWidget(ultimateKeys?.callNow?.onlineHeader, event.target.value)
                    }
                  />
                </div>


                <div className="call-tab-text">
                  <h3 className="call-title widget-sub-heading">{'Offline header text'}</h3>
                  {/* <p className="accordion-description" style={{color: 'gray'}}> The body text of the widget </p>{' '}
                      */}
                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-now"
                    type="text"

                    value={widgetStyleData?.[ultimateKeys?.callNow?.offlineHeader]}
                    onChange={event =>
                      changeWidget(
                        ultimateKeys?.callNow?.offlineHeader,
                        event.target.value
                      )
                    }
                  />
                </div>
                {/* <div className="popup-font theme-selector">    */}
                {/* <h3 className="call-title widget-sub-heading">Ultimate Header Title</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="Call Me Now"
                name="call-me-now"
                type="text"
                value={
                  widgetStyleData?.[ultimateKeys?.header]

                }
                onChange={event =>
                  changeWidget(
                    ultimateKeys?.header,
                    event.target.value,
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.headerStatus, e)}
                dataToggle={getToggleObj('',' ',ultimateKeys?.headerStatus)}
                activeDefault={widgetStyleData?.[ultimateKeys?.headerStatus]}
              />sss
            </div> */}

                {/* </div> */}
                <div className="popup-font theme-selector">
                  <h3 className="call-title widget-sub-heading">Header Subtitle</h3>
                  <div className="header-swich">
                    <CommonInput
                      placeholder="Call Me Now"
                      name="call-me-now"
                      type="text"
                      value={
                        widgetStyleData?.[ultimateKeys?.callNow?.subTitle]
                      }
                      onChange={event =>
                        changeWidget(
                          ultimateKeys?.callNow?.subTitle,
                          event.target.value
                        )
                      }
                    />

                  </div>
                </div>
                <div className="popup-font theme-selector" style={{    marginBottom:'85px'}}>
                  <h3 className="call-title widget-sub-heading">Sub Header</h3>
                    <TextArea
                    className="w-50"
                  placeholder="For a free & immediate callback, enter your number below and we will call you in 28 seconds."

               value={
                        widgetStyleData?.[ultimateKeys?.callNow?.subHeader]
                      }
              onChange={event =>
                        changeWidget(
                          ultimateKeys?.callNow?.subHeader,
                          event.target.value
                        )
                      }

              />
                 
                </div>

             

                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Lead Qualification Form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> If enabled, this form is shown before audio calls start. </p>{' '}

 <div className="call-tab-text">

                <h3 className="call-title widget-sub-heading">Cta call me back</h3>
                {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                     */}
                <CommonInput
                  placeholder="Call Me Now"
                  name="call-me-now"
                  type="text"
                  value={
                    widgetStyleData?.[ultimateKeys?.callNow?.ctaCallback] || null
                  }
                  onChange={event =>
                    changeWidget(
                      ultimateKeys?.callNow?.ctaCallback,
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="call-tab-text">

                <h3 className="call-title widget-sub-heading">Cta call me later</h3>
                {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                     */}
                <CommonInput
                  placeholder="Call Me later"
                  name="call-me-later"
                  type="text"
                  value={
                    widgetStyleData?.[ultimateKeys?.callNow?.ctaCalllater] || null
                  }
                  onChange={event =>
                    changeWidget(
                      ultimateKeys?.callNow?.ctaCalllater,
                      event.target.value
                    )
                  }
                />
              </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.callNow?.name, e)
                      }
                      dataToggle={getToggleObj('Name', ' ', ultimateKeys?.callNow?.name)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.callNow?.name]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.callNow?.email, e)
                      }
                      dataToggle={getToggleObj('Email', ' ', ultimateKeys?.callNow?.email)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.callNow?.email]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.callNow?.team, e)
                      }
                      dataToggle={getToggleObj('Team', ' ', ultimateKeys?.callNow?.team)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.callNow?.team]}
                      fullNameToggle={fullNameToggle}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.callNow?.website, e)
                      }
                      dataToggle={getToggleObj('Website', ' ', ultimateKeys?.callNow?.website)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.callNow?.website]
                      }
                    />
                  </div>
                  {/* <div className="display-content">
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(ultimateKeys?.callNow?.phone, e)
                          }
                          dataToggle={getToggleObj('Phone',' ',ultimateKeys?.callNow?.phone)}
                          activeDefault={widgetStyleData?.[ultimateKeys?.callNow?.phone]
                          }
                        />
                      </div> */}
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.callNow?.company, e)
                      }
                      dataToggle={getToggleObj('Company', ' ', ultimateKeys?.callNow?.company)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.callNow?.company]
                      }
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.callNow?.companySize, e)
                      }
                      dataToggle={getToggleObj('Company Size', ' ', ultimateKeys?.callNow?.companySize)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.callNow?.companySize]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.callNow?.message, e)
                      }
                      dataToggle={getToggleObj('Message', ' ', ultimateKeys?.callNow?.message)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.callNow?.message]}
                    />
                  </div>

                </div>
              </>

            ) : null}

          </section>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.calllater?.key, e)}
                dataToggle={getToggleObj('Meetings', 'Your prospect can schedule phone calls and book appointments.', ultimateKeys?.calllater?.key)}
                activeDefault={widgetStyleData?.[ultimateKeys?.calllater?.key]}
              />

            </div>
           
            {/* <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.calllater?.liveCallBack, e)}
                dataToggle={getToggleObj('Live Callbacks','Enable live phone callbacks from your widget.',ultimateKeys?.calllater?.liveCallBack)}
                activeDefault={widgetStyleData.ultimateKeys?.calllater?.liveCallBack}
              />

            </div> */}
            {widgetStyleData?.[ultimateKeys?.calllater?.key]


              ? (
                <>
                       <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.calllater?.homeShow, e)}
                activeDefault={widgetStyleData?.[ultimateKeys?.calllater?.homeShow]}
                dataToggle={getToggleObj('Show this on homescreen', ' ', ultimateKeys?.calllater?.homeShow)}
              />
            </div>
                <div className="call-tab-text">
                  <h3 className="call-title widget-sub-heading">{"Button Title"}</h3>

                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-back"
                    type="text"

                    value={
                      widgetStyleData[ultimateKeys?.calllater?.title] || null
                    }
                    onChange={event =>
                      changeWidget(ultimateKeys?.calllater?.title || 'widget_text', event.target.value)
                    }
                  />
                </div>

                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">Button SubTitle</h3>

                  <CommonInput
                    placeholder="Call Me Now"
                    name="call-me-now"
                    type="text"
                    value={
                      widgetStyleData[ultimateKeys?.calllater?.subTitle] || null
                    }
                    onChange={event =>
                      changeWidget(
                        ultimateKeys?.calllater?.subTitle || 'submit_request_call_text',
                        event.target.value
                      )
                    }
                  />
                </div>
                

                  <>

                    <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>
                      <h3 className="call-title widget-sub-heading">Lead Qualification Form</h3>
                      <p className="accordion-description" style={{ color: '#9a9a9a' }}> This form collects user data when scheduling a meeting.</p>{' '}

                    <div className="call-tab-text">

                      <h3 className="call-title widget-sub-heading">Header text</h3>
                      {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to schedule a meeting </p>{' '}
                    */}

                      <CommonInput
                        placeholder="Would you like us to call you later ?"
                        name="call-me-now"
                        type="text"
                        defaultValue={widgetStyleData?.[ultimateKeys?.calllater?.headerText]}
                        value={
                          widgetStyleData?.[ultimateKeys?.calllater?.headerText]

                        }
                        onChange={event =>
                          changeWidget(
                            ultimateKeys?.calllater?.headerText,
                            event.target.value
                          )
                        }
                      />
                    </div>
                  <div className="call-tab-text">
                    <h3 className="call-title widget-sub-heading">Call to action text</h3>
                    {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                   */}
                    <CommonInput
                      placeholder="Call Me Now"
                      name="call-me-now"
                      type="text"

                      value={
                        widgetStyleData?.[ultimateKeys?.calllater?.cta]
                      }
                      onChange={event =>
                        changeWidget(
                          ultimateKeys?.calllater?.cta,
                          event.target.value
                        )
                      }
                    />
                  </div>

                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(ultimateKeys?.calllater?.name, e)
                          }
                          dataToggle={getToggleObj('Name', ' ', ultimateKeys?.calllater?.name)}
                          activeDefault={widgetStyleData?.[ultimateKeys?.calllater?.name]}
                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(ultimateKeys?.calllater?.email, e)
                          }
                          dataToggle={getToggleObj('Email', ' ', ultimateKeys?.calllater?.email)}
                          activeDefault={widgetStyleData?.[ultimateKeys?.calllater?.email]}
                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(ultimateKeys?.calllater?.team, e)
                          }
                          dataToggle={getToggleObj('Team', ' ', ultimateKeys?.calllater?.team)}
                          activeDefault={widgetStyleData?.[ultimateKeys?.calllater?.team]}
                        />
                      </div>
                      <>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(ultimateKeys?.calllater?.website, e)
                            }
                            dataToggle={getToggleObj('Website', ' ', ultimateKeys?.calllater?.website)}
                            activeDefault={widgetStyleData?.[ultimateKeys?.calllater?.website]
                            }
                          />
                        </div>
                        {/* <div className="display-content">
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(ultimateKeys?.calllater?.phone, e)
                          }
                          dataToggle={getToggleObj('Phone',' ',ultimateKeys?.calllater?.phone)}
                          activeDefault={widgetStyleData?.[ultimateKeys?.calllater?.phone]
                          }
                        />
                      </div> */}
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(ultimateKeys?.calllater?.company, e)
                            }
                            dataToggle={getToggleObj('Company', ' ', ultimateKeys?.calllater?.company)}
                            activeDefault={widgetStyleData?.[ultimateKeys?.calllater?.company]}
                          />
                        </div>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(ultimateKeys?.calllater?.companySize, e)
                            }
                            dataToggle={getToggleObj('Company Size', ' ', ultimateKeys?.calllater?.companySize)}
                            activeDefault={widgetStyleData?.[ultimateKeys?.calllater?.companySize]}
                          />
                        </div>
                        <div className="display-content">
                          <NodeToggle
                            handleDataRef={e =>
                              changeWidgetToggle(ultimateKeys?.calllater?.message, e)
                            }
                            dataToggle={getToggleObj('Message', ' ', ultimateKeys?.calllater?.message)}
                            activeDefault={widgetStyleData?.[ultimateKeys?.calllater?.message]}
                          />
                        </div>
                      </>
                    </div>
                  </>
                </>
              ) : null}

          </section>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.leaveMessage?.key, e)}
                dataToggle={getToggleObj('Contact form', 'Generate more leads from your website.', ultimateKeys?.leaveMessage?.key)}
                activeDefault={widgetStyleData?.[ultimateKeys?.leaveMessage?.key]}
              />
            </div>
       

            {widgetStyleData?.[ultimateKeys?.leaveMessage?.key] ? (
              <>
                        <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.leaveMessage?.homeShow, e)}
                dataToggle={getToggleObj('Show this on homescreen', ' ', ultimateKeys?.leaveMessage?.homeShow)}
                activeDefault={widgetStyleData?.[ultimateKeys?.leaveMessage?.homeShow]}
              />
            </div>
               <div className="call-tab-text">
                <h3 className="call-title widget-sub-heading">{"Button Title"}</h3>

                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-back"
                    type="text"

                    value={
                      widgetStyleData[ultimateKeys?.leaveMessage?.title] || null
                    }
                    onChange={event =>
                      changeWidget(ultimateKeys?.leaveMessage?.title || 'widget_text', event.target.value)
                    }
                  />
                </div>

                <div className="call-tab-text">

                <h3 className="call-title widget-sub-heading">Button SubTitle</h3>

                  <CommonInput
                    placeholder="Call Me Now"
                    name="call-me-now"
                    type="text"
                    value={
                      widgetStyleData[ultimateKeys?.leaveMessage?.subTitle] || null
                    }
                    onChange={event =>
                      changeWidget(
                        ultimateKeys?.leaveMessage?.subTitle || 'submit_request_call_text',
                        event.target.value
                      )
                    }
                  />
                </div>
               
               
                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Pre contact form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> This form collects user data before the form is submitted. </p>{' '}
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Header text"}</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to send a message </p>{' '}
                       */}

                  <CommonInput
                    placeholder="Leave your Message"
                    name="call-me-now"
                    type="text"

                    value={
                      widgetStyleData?.[ultimateKeys?.leaveMessage?.headerText]
                    }
                    onChange={e =>
                      changeWidget(ultimateKeys?.leaveMessage?.headerText, e.target.value)
                    }
                  />
                </div>
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Sub Header text"}</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to send a message </p>{' '}
                       */}

                  <CommonInput
                    placeholder="Leave your Message"
                    name="call-me-now"
                    type="text"

                    value={
                      widgetStyleData?.[ultimateKeys?.leaveMessage?.subHeaderText]
                    }
                    onChange={e =>
                      changeWidget(ultimateKeys?.leaveMessage?.subHeaderText, e.target.value)
                    }
                  />
                </div>
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">Call to action text</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
 */}
                  <CommonInput
                    placeholder="Call Me Now"
                    name="call-me-now"
                    type="text"
                    value={
                      widgetStyleData?.[ultimateKeys?.leaveMessage?.cta] || null
                    }
                    onChange={event =>
                      changeWidget(
                        ultimateKeys?.leaveMessage?.cta,
                        event.target.value
                      )
                    }
                  />
                </div>
                
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leaveMessage?.name, e)
                      }
                      dataToggle={getToggleObj('Name', ' ', ultimateKeys?.leaveMessage?.name)}
                      activeDefault={
                        widgetStyleData?.[ultimateKeys?.leaveMessage?.name]
                      }

                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leaveMessage?.email, e)
                      }
                      dataToggle={getToggleObj('Email', ' ', ultimateKeys?.leaveMessage?.email)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leaveMessage?.email]}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leaveMessage?.team, e)
                      }
                      dataToggle={getToggleObj('Team', ' ', ultimateKeys?.leaveMessage?.team)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leaveMessage?.team]}
                    />
                  </div>

                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leaveMessage?.website, e)
                      }
                      dataToggle={getToggleObj('Website', ' ', ultimateKeys?.leaveMessage?.website)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leaveMessage?.website]
                      }
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leaveMessage?.phone, e)
                      }
                      dataToggle={getToggleObj('Phone', ' ', ultimateKeys?.leaveMessage?.phone)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leaveMessage?.phone]
                      }
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leaveMessage?.company, e)
                      }
                      dataToggle={getToggleObj('Company', ' ', ultimateKeys?.leaveMessage?.company)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leaveMessage?.company]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.leaveMessage?.company]}

                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leaveMessage?.companySize, e)
                      }
                      dataToggle={getToggleObj('Type of Query', ' ', ultimateKeys?.leaveMessage?.companySize)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leaveMessage?.companySize]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.leaveMessage?.companySize]}
                    />
                  </div>


                  {widgetStyleData?.[ultimateKeys?.leaveMessage?.companySize] ?

                    <>
                      {queryFields?.map((obj, index) =>

                        <div className="popup-font" style={{ width: '75%' }}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px'
                          }}>
                            {/* <img width={35} className="mr-2" src={messangerIcon} alt="messanger" /> */}

                            <CommonInput
                              className="popup-font-select"
                              placeholder="Type name here.."
                              name="call-me-now"
                              type="text"
                            // value={(widgetStyleData?.fb_messenger_call_url || socialData.fb_messenger_call_url)?.replace('https://m.me/', ``)
                            // }
                            // onChange={event =>
                            //   changeSocial(
                            //     'fb_messenger_call_url',
                            //     event?.target?.value?.includes('https://m.me/') ? event?.target?.value : `https://m.me/${event?.target?.value}`
                            //   )
                            // }
                            />
                          </div>
                          <div className="header-swich" style={{ width: '60%', justifyContent: 'space-between', alignItems: 'baseline', marginTop: '9px' }}>
                            <CommonInput
                              className="popup-font-select"
                              placeholder="Type description here.."
                              name="call-me-now"
                              type="text"
                            // value={(widgetStyleData?.fb_messenger_call_url || socialData.fb_messenger_call_url)?.replace('https://m.me/', ``)
                            // }
                            // onChange={event =>
                            //   changeSocial(
                            //     'fb_messenger_call_url',
                            //     event?.target?.value?.includes('https://m.me/') ? event?.target?.value : `https://m.me/${event?.target?.value}`
                            //   )
                            // }
                            />

                            <img src={deleteIcon} alt='delete' onClick={() => {

                              setQueryFields([...queryFields?.filter((val, inde) => inde !== index)])
                            }



                            } />
                          </div>

                        </div>)}

                      <Button variant="standard" onClick={() => {

                        setQueryFields([...queryFields, 'custom'])
                      }}><DataSaverOnIcon /> Add More</Button>
                    </> : null
                  }
                </div>


              </>
            ) : null}

          </section>
          <section className="card-manage-number">
           
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              <NodeToggle
                handleDataRef={e =>widgetStyleData?.dedicated_number_for_widget_text ?  changeWidgetToggle(ultimateKeys?.textUs?.key, e) : CommonNotify('Add virtual number')}
                dataToggle={getToggleObj('Text Us', 'Generate more leads from your website.', ultimateKeys?.textUs?.key)}
                activeDefault={widgetStyleData?.[ultimateKeys?.textUs?.key]}
              />
            </div>
       

            {widgetStyleData?.[ultimateKeys?.textUs?.key] ? (
              <>
              <div className="d-flex" style={{justifyContent:'space-between' ,marginBottom:'35px'}}>
              <h5>Associated Number</h5>
             <CommonSelect
                style={{width:'40%' }}
                name="config"
                className="popup-font-select"
                placeholder="Select Number"
                value={widgetStyleData?.dedicated_number_for_widget_text}
                options={[...virtualNumbers?.map((obj)=>obj?.phone_number)]}
                onChange={(e, data) =>updateNumber('dedicated_number_for_widget_text',data?.value==='Select Number' ? '' :data?.value)
               
                }
              />
            </div>
               <div className="call-tab-text">
                <h3 className="call-title widget-sub-heading">{"Button Title"}</h3>

                  <CommonInput
                    placeholder="Do you want us to call you in 28 seconds?"
                    name="call-me-back"
                    type="text"

                    value={
                      widgetStyleData[ultimateKeys?.textUs?.title] || null
                    }
                    onChange={event =>
                      changeWidget(ultimateKeys?.textUs?.title || 'widget_text', event.target.value)
                    }
                  />
                </div>

                <div className="call-tab-text">

                <h3 className="call-title widget-sub-heading">Button SubTitle</h3>

                  <CommonInput
                    placeholder="Call Me Now"
                    name="call-me-now"
                    type="text"
                    value={
                      widgetStyleData[ultimateKeys?.textUs?.subTitle] || null
                    }
                    onChange={event =>
                      changeWidget(
                        ultimateKeys?.textUs?.subTitle || 'submit_request_call_text',
                        event.target.value
                      )
                    }
                  />
                </div>
               
               
                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Pre contact form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> This form collects user data before the form is submitted. </p>{' '}
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Header text"}</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to send a message </p>{' '}
                       */}

                  <CommonInput
                    placeholder="Leave your Message"
                    name="call-me-now"
                    type="text"

                    value={
                      widgetStyleData?.[ultimateKeys?.textUs?.headerText]
                    }
                    onChange={e =>
                      changeWidget(ultimateKeys?.textUs?.headerText, e.target.value)
                    }
                  />
                </div>
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">Call to action text</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
 */}
                  <CommonInput
                    placeholder="Call Me Now"
                    name="call-me-now"
                    type="text"
                    value={
                      widgetStyleData?.[ultimateKeys?.textUs?.cta] || null
                    }
                    onChange={event =>
                      changeWidget(
                        ultimateKeys?.textUs?.cta,
                        event.target.value
                      )
                    }
                  />
                </div>
                
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.textUs?.name, e)
                      }
                      dataToggle={getToggleObj('Name', ' ', ultimateKeys?.textUs?.name)}
                      activeDefault={
                        widgetStyleData?.[ultimateKeys?.textUs?.name]
                      }

                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.textUs?.email, e)
                      }
                      dataToggle={getToggleObj('Email', ' ', ultimateKeys?.textUs?.email)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.textUs?.email]}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.textUs?.team, e)
                      }
                      dataToggle={getToggleObj('Team', ' ', ultimateKeys?.textUs?.team)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.textUs?.team]}
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.textUs?.company, e)
                      }
                      dataToggle={getToggleObj('Company', ' ', ultimateKeys?.textUs?.company)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.textUs?.company]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.textUs?.company]}

                    />
                  </div>
             


                  
                </div>


              </>
            ) : null}

          </section>
          {/* <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(ultimateKeys?.leadMessage?.key, e)}
                dataToggle={getToggleObj('Lead form', 'Generate more leads from your website.', ultimateKeys?.leadMessage?.key)}
                activeDefault={widgetStyleData?.[ultimateKeys?.leadMessage?.key]}
              />
            </div>

            {widgetStyleData?.[ultimateKeys?.leadMessage?.key] ? (
              <>
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">Call to action text</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 

                  <CommonInput
                    placeholder="Call Me Now"
                    name="call-me-now"
                    type="text"
                    value={
                      widgetStyleData?.[ultimateKeys?.leadMessage?.cta] || null
                    }
                    onChange={event =>
                      changeWidget(
                        ultimateKeys?.leadMessage?.cta,
                        event.target.value
                      )
                    }
                  />
                </div>
                <div className="call-tab-text">

                  <h3 className="call-title widget-sub-heading">{"Header text"}</h3>
                  {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to send a message </p>{' '}
                

                  <CommonInput
                    placeholder="Leave your Message"
                    name="call-me-now"
                    type="text"

                    value={
                      widgetStyleData?.[ultimateKeys?.leadMessage?.title]
                    }
                    onChange={e =>
                      changeWidget(ultimateKeys?.leadMessage?.title, e.target.value)
                    }
                  />
                </div>
                <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>

                  <h3 className="call-title widget-sub-heading">Pre contact form </h3>
                  <p className="accordion-description" style={{ color: '#9a9a9a' }}> This form collects user data before the form is submitted. </p>{' '}
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Name</p> 
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leadMessage?.name, e)
                      }
                      dataToggle={getToggleObj('Name', ' ', ultimateKeys?.leadMessage?.name)}
                      activeDefault={
                        widgetStyleData?.[ultimateKeys?.leadMessage?.name]
                      }

                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Email</p>
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leadMessage?.email, e)
                      }
                      dataToggle={getToggleObj('Email', ' ', ultimateKeys?.leadMessage?.email)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leadMessage?.email]}
                    />
                  </div>
                  <div className="display-content">
                    {/* <p className="style-widget-title widget-sub-heading">Team</p> 
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leadMessage?.team, e)
                      }
                      dataToggle={getToggleObj('Team', ' ', ultimateKeys?.leadMessage?.team)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leadMessage?.team]}
                    />
                  </div>

                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leadMessage?.website, e)
                      }
                      dataToggle={getToggleObj('Website', ' ', ultimateKeys?.leadMessage?.website)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leadMessage?.website]
                      }
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leadMessage?.phone, e)
                      }
                      dataToggle={getToggleObj('Phone', ' ', ultimateKeys?.leadMessage?.phone)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leadMessage?.phone]
                      }
                    />
                  </div>
                  <div className="display-content">
                    <NodeToggle
                      handleDataRef={e =>
                        changeWidgetToggle(ultimateKeys?.leadMessage?.company, e)
                      }
                      dataToggle={getToggleObj('Company', ' ', ultimateKeys?.leadMessage?.company)}
                      activeDefault={widgetStyleData?.[ultimateKeys?.leadMessage?.company]}
                      dataToggleActive={widgetStyleData?.[ultimateKeys?.leadMessage?.company]}

                    />
                  </div>

                </div>


              </>
            ) : null}

          </section> */}

        </>


      }
      {parseInt(widgetStyleData.template_type) === parseInt(6) &&
        <>
                 
      
          <div className="popup-font theme-selector">
            
            <h3 className="call-title widget-sub-heading">Callback Header Title</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="Call Me Now"
                name="call-me-now"
                type="text"
                value={
                  widgetStyleData?.[callbackKeys?.header]

                }
                onChange={event =>
                  changeWidget(
                    callbackKeys?.header,
                    event.target.value,
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(callbackKeys?.headerStatus, e)}
                dataToggle={getToggleObj('', ' ', callbackKeys?.headerStatus)}
                activeDefault={widgetStyleData?.[callbackKeys?.headerStatus]}
              />
            </div>

          </div>
          <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Callback Header Subtitle</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="Call Me Now"
                name="call-me-now"
                type="text"
                value={
                  widgetStyleData?.[callbackKeys?.subHeader]
                }
                onChange={event =>
                  changeWidget(
                    callbackKeys?.subHeader,
                    event.target.value
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(callbackKeys?.subHeaderStatus, e)}
                dataToggle={getToggleObj('', ' ', callbackKeys?.subHeaderStatus)}
                activeDefault={widgetStyleData?.[callbackKeys?.subHeaderStatus]}
              />
            </div>

          </div>
          <section className="card-manage-number">
            <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              {/* <p className="style-widget-title style-switch widget-sub-heading">Lead Qualification Form</p> */}
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(callbackKeys?.calllater?.key, e)}
                dataToggle={getToggleObj('Callback', 'Your prospect can schedule phone calls and book appointments.', callbackKeys?.calllater?.key)}
                activeDefault={widgetStyleData?.[callbackKeys?.calllater?.key]}
              />

            </div>
            {/* <div className="campaign-sec" style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
              <NodeToggle
                handleDataRef={e => changeWidgetToggle(callbackKeys?.calllater?.liveCallBack, e)}
                dataToggle={getToggleObj('Live Callbacks','Enable live phone callbacks from your widget.',callbackKeys?.calllater?.liveCallBack)}
                activeDefault={ widgetStyleData?.[callbackKeys?.calllater?.liveCallBack]}
              />
            </div> */}
            <div className="call-tab-text">
              <h3 className="call-title widget-sub-heading">Call Button Text</h3>
              {/* <NodeToggle
           handleDataRef={e => changeWidgetToggle('call_btn_text_callback_template', e)}
           dataToggle={callButton[0]}
           activeDefault={widgetStyleData.call_btn_text_callback_template}
         /> */}
              <CommonInput
                placeholder="Do you want us to call you in 28 seconds?"
                name="call-me-now"
                type="text"


                onChange={event =>
                  changeWidget(
                    callbackKeys?.calllater?.callButtonText,
                    event.target.value
                  )
                }
                value={widgetStyleData?.[callbackKeys?.calllater?.callButtonText]}

              />
            </div>
            {
              widgetStyleData?.[callbackKeys?.calllater?.key]

                ? (




                  <>

                    <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingTop: "16px", border: "1px solid #e5e5e5" }}>
                      <h3 className="call-title widget-sub-heading">Lead form </h3>
                      <p className="accordion-description" style={{ color: '#9a9a9a' }}> This form collects user data when scheduling a meeting.</p>{' '}
                      <div className="call-tab-text">
                        <h3 className="call-title widget-sub-heading">Call to action text</h3>
                        {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to initiate a call </p>{' '} 
                  */}
                        <CommonInput
                          placeholder="Call Me Now"
                          name="call-me-now"
                          type="text"

                          value={
                            widgetStyleData?.[callbackKeys?.calllater?.cta]
                          }
                          onChange={event =>
                            changeWidget(
                              callbackKeys?.calllater?.cta,
                              event.target.value
                            )
                          }
                        />
                      </div>

                      <div>
                        <div className="call-tab-text">
                          <h3 className="call-title widget-sub-heading">Headline Text</h3>
                          {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to schedule a meeting </p>{' '}
                  */}

                          <CommonInput
                            placeholder="Would you like us to call you later ?"
                            name="call-me-now"
                            type="text"
                            defaultValue={widgetStyleData?.[callbackKeys?.calllater?.headline]}
                            value={
                              widgetStyleData?.[callbackKeys?.calllater?.headline]
                            }
                            onChange={event =>
                              changeWidget(
                                callbackKeys?.calllater?.headline,
                                event.target.value
                              )
                            }
                          />
                        </div>
                        {<div className="call-tab-text">
                          <h3 className="call-title widget-sub-heading">Subheadline Text</h3>
                          {/* <p className="accordion-description" style={{color: '#9a9a9a'}}> The text of the button that the user clicks to schedule a meeting </p>{' '}
                  */}

                          <CommonInput
                            placeholder="Would you like us to call you later ?"
                            name="call-me-now"
                            type="text"
                            defaultValue={widgetStyleData?.[callbackKeys?.calllater?.subHeadline]}
                            value={
                              widgetStyleData?.[callbackKeys?.calllater?.subHeadline]
                            }
                            onChange={event =>
                              changeWidget(
                                callbackKeys?.calllater?.subHeadline,
                                event.target.value
                              )
                            }
                          />
                        </div>}

                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Name</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(callbackKeys?.calllater?.name, e)
                          }
                          dataToggle={getToggleObj('Name', ' ', callbackKeys?.calllater?.name)}
                          activeDefault={widgetStyleData?.[callbackKeys?.calllater?.name]}
                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Email</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(callbackKeys?.calllater?.email, e)
                          }
                          dataToggle={getToggleObj('Email', ' ', callbackKeys?.calllater?.email)}
                          activeDefault={widgetStyleData?.[callbackKeys?.calllater?.email]}

                        />
                      </div>
                      <div className="display-content">
                        {/* <p className="style-widget-title widget-sub-heading">Team</p> */}
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(callbackKeys?.calllater?.team, e)
                          }
                          dataToggle={getToggleObj('Team', ' ', callbackKeys?.calllater?.team)}
                          activeDefault={widgetStyleData?.[callbackKeys?.calllater?.team]}

                        />
                      </div>


                      <div className="display-content">
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(callbackKeys?.calllater?.comment, e)
                          }
                          dataToggle={getToggleObj('Comments', ' ', callbackKeys?.calllater?.comment)}
                          activeDefault={widgetStyleData?.[callbackKeys?.calllater?.comment]}

                        />
                      </div>


                      <div className="display-content">
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(callbackKeys?.calllater?.company, e)
                          }
                          dataToggle={getToggleObj('Company', ' ', callbackKeys?.calllater?.company)}
                          activeDefault={widgetStyleData?.[callbackKeys?.calllater?.company]}

                        />
                      </div>
                      <div className="display-content">
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(callbackKeys?.calllater?.companySize, e)
                          }
                          dataToggle={getToggleObj('Company Size', ' ', callbackKeys?.calllater?.companySize)}
                          activeDefault={widgetStyleData?.[callbackKeys?.calllater?.companySize]}

                        />
                      </div>
                      <div className="display-content">
                        <NodeToggle
                          handleDataRef={e =>
                            changeWidgetToggle(callbackKeys?.calllater?.website, e)
                          }
                          dataToggle={getToggleObj('Website', ' ', callbackKeys?.calllater?.website)}
                          activeDefault={widgetStyleData?.[callbackKeys?.calllater?.website]}

                        />
                      </div>


                    </div>


                  </>

                ) : null}

          </section>

          {/* <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Whatapp Number</h3>
            <div className="header-swich">

              <PhoneInput
                placeholder="(555) 555-5555"
                className="text-color"
                value={(widgetStyleData.whatsapp_social_url || socialData.whatsapp_social_url)?.replace('https://web.whatsapp.com/send?phone=', ``)
                }
                onChange={value =>
                  changeSocial(
                    'whatsapp_social_url',
                    value?.includes('https://web.whatsapp.com/send?phone=') ? value : `https://web.whatsapp.com/send?phone=${value}`
                  )}
              />
              <NodeToggle
                handleDataRef={e => changeSocial('whatsapp_social_status', e)}
                dataToggle={{
                  callId: 'whatsapp_social_status',
                  callRef: 'whatsapp_social_status'
                }}
                activeDefault={widgetStyleData?.whatsapp_social_status}
              />
            </div>

          </div> */}
          {/* <div className="popup-font theme-selector">
        <h3 className="call-title widget-sub-heading">Instagram</h3>
        <div className="header-swich">
        <CommonInput
               className="popup-font-select"
                        placeholder="Type URL here.."
                        name="call-me-now"
                        type="text"
                        value={
                          widgetStyleData.instagram_social_url

                            ? widgetStyleData.instagram_social_url

                            : null
                        }
                        onChange={event =>
                          changeSocial(
                            'instagram_social_url',
                            event.target.value
                          )
                        }
                      />
                       <NodeToggle
            handleDataRef={e => changeSocial('instagram_social_status', e)}
            dataToggle={{
              callId: 'instagram_social_status',
            callRef: 'instagram_social_status'}}
            activeDefault={widgetStyleData.instagram_social_status}
          />
            </div>        
                    
      </div> */}
          {/* <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Line</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="Type URL here.."
                name="call-me-now"
                type="text"
                value={(widgetStyleData.line_call_url || socialData.line_call_url)?.replace('https://line.me/ti/p/', ``)

                }
                onChange={event =>
                  changeSocial(
                    'line_call_url',
                    event?.target?.value?.includes('https://line.me/ti/p/') ? event?.target?.value : `https://line.me/ti/p/${event?.target?.value}`
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeSocial('line_call_status', e)}
                dataToggle={{
                  callId: 'line_call_status',
                  callRef: 'line_call_status'
                }}
                activeDefault={widgetStyleData?.line_call_status}
              />
            </div>

          </div>
          <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Telegram ID</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="Type URL here.."
                name="call-me-now"
                type="text"
                value={(widgetStyleData?.telegram_call_url || socialData.telegram_call_url)?.replace('https://t.me/', ``)
                }
                onChange={event =>
                  changeSocial(
                    'telegram_call_url',
                    event?.target?.value?.includes('https://t.me/') ? event?.target?.value : `https://t.me/${event?.target?.value}`
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeSocial('telegram_call_status', e)}
                dataToggle={{
                  callId: 'telegram_call_status',
                  callRef: 'telegram_call_status'
                }}
                activeDefault={widgetStyleData?.telegram_call_status}
              />
            </div>

          </div>
          <div className="popup-font theme-selector">
            <h3 className="call-title widget-sub-heading">Messenger ID</h3>
            <div className="header-swich">
              <CommonInput
                className="popup-font-select"
                placeholder="Type URL here.."
                name="call-me-now"
                type="text"
                value={(widgetStyleData?.fb_messenger_call_url || socialData.fb_messenger_call_url)?.replace('https://m.me/', ``)
                }
                onChange={event =>
                  changeSocial(
                    'fb_messenger_call_url',
                    event?.target?.value?.includes('https://m.me/') ? event?.target?.value : `https://m.me/${event?.target?.value}`
                  )
                }
              />
              <NodeToggle
                handleDataRef={e => changeSocial('fb_messenger_call_status', e)}
                dataToggle={{
                  callId: 'fb_messenger_call_status',
                  callRef: 'fb_messenger_call_status'
                }}
                activeDefault={widgetStyleData?.fb_messenger_call_status}
              />
            </div>

          </div> */}
        </>
      }




      <div className="legal-wrapper">
        <div className="legal-head">
          <div style={{ maxWidth: '45%' }}>
            <p className="style-widget-title widget-sub-heading">Social Proof</p>
            <p className="general-content-desc">
              Get real-time stats on how many call requests have been made today.{' '}
            </p>
          </div>
          <NodeToggle
            handleDataRef={e => changeWidgetToggle('social_proof', e)}
            dataToggle={socialWidget[0]}
            activeDefault={widgetStyleData.social_proof}
          />
        </div>

        <div className="legal-head">
          <div style={{ maxWidth: '45%' }}>
            <p className="style-widget-title widget-sub-heading">Legal</p>
            <p style={{ display: "block" }} className="general-content-desc">
              Configure Your Terms &amp; Conditions.
            </p>
          </div>




          <NodeToggle
            handleDataRef={e =>
              changeWidgetToggle('terms_and_conditions_status', e)
            }
            dataToggle={legalWidget[0]}
            activeDefault={widgetStyleData.terms_and_conditions_status}
          />
        </div>
        {widgetStyleData.terms_and_conditions_status ? (
          <div className="legal-content legal-dropdown">
            <div className="legal-col">
              <label>Configure Your Terms &amp; Conditions</label>
              <CommonSelect
                name="config"
                className="popup-font-select"
                placeholder="Text"
                value={
                  widgetStyleData.terms_type == '0'
                    ? 'Text'
                    : widgetStyleData.terms_type == '1'
                      ? 'URL'
                      : 'HTML'
                }
                options={termOption}
                onChange={(e, data) =>
                  changeWidget(
                    'terms_type',
                    `${data.value === 'Text'
                      ? '0'
                      : data.value === 'URL'
                        ? '1'
                        : '2'
                    }`
                  )
                }
              />

            </div>
            <div className="legal-col">
              <TextArea
                // defaultValue={widgetSetting.legalNote}
                value={widgetStyleData.terms_and_conditions}
                onChange={event =>
                  changeWidget('terms_and_conditions', event.target.value)
                }
              />
            </div>
          </div>
        ) : null}
        <div className="legal-head">
          <div style={{ maxWidth: '45%' }}>
            <p className="style-widget-title widget-sub-heading">Privacy Note</p>
            <p className="general-content-desc">
              Embed your privacy note on the widget.{' '}
            </p>
          </div>

          <NodeToggle
            handleDataRef={e => {
              changeWidgetToggle(parseInt(widgetStyleData.template_type) === parseInt(6) ? 'privacy_note_status' : 'privacy_note_status', e)
            }}
            dataToggle={privacyWidget[0]}
            activeDefault={parseInt(widgetStyleData.template_type) === parseInt(6) ? widgetStyleData.privacy_note_status : widgetStyleData.privacy_note_status}
          />
        </div>

        {widgetStyleData.privacy_note_status ? (
          <div className="legal-content private-text-area">
            <div className="legal-col">
              <TextArea
                placeholder="Your number will not be used for marketing purposes"

                value={widgetStyleData.privacy_note}
                onChange={event =>
                  changeWidget('privacy_note', event.target.value)
                }

              />
            </div>
          </div>
        ) : null}
        {isInputChange ? (
          <div className="isInputChange">
            <CommonButton
              onClick={submitWidget}
              type="button"
              content="Save"
              background="blue"
            />
            <CommonButtons
              onClick={onCancel}
              type="reset"
              content="Cancel"
              background="grey"
            />
          </div>
        ) : null}
      </div>

      <Ref className="widget-style-mobile" innerRef={contextRef}>
        <Rail position="right" style={{ minHeight: "2000px" }}>

        </Rail>
      </Ref>
    </div>
  )
}

import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { connect } from 'react-redux'
import { Dimmer, Dropdown, Loader, Modal, Table } from 'semantic-ui-react'
import { getWidgetCountries } from '../actions/settings'
import close from '../assets/images/close.png'
import CountryData from '../helpers/data/countryCode.json'
import CommonButtons from './CommonButtons'
import { CommonNotify } from './CommonNotify'
import countries from '../lib/Country'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const CountryModal = ({
    open,
    isModalClose,
    leadData,
    getWidgetCountries,
    widgetCountries,
    phone_number,
    subscription,
    widgetData,
    profile
}) => {
    const [allowedCountries, setAllowedCountries] = useState([])
    const [agentList, setAgentList] = useState([])
    const [loader, setLoader] = useState(false)
    const [sendMsgData, setSendMsgData] = useState({
        lead_id: leadData?.id,
        from: null,
        to: leadData?.phone_number
    })

    useEffect(() => {
        getAgentList()
    }, [])

    const getAgentList = async () => {
        setLoader(true)
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
        await Axios.get(url)
            .then(res => {
                if (res?.data?.data) {
                    setLoader(false)
                    setAgentList([res?.data?.data?.admin, ...res?.data?.data?.members] || [])
                }
            })
            .catch(error => {
                setLoader(false)
            })
    }

    useEffect(() => {
        if (sendMsgData?.close === true) {
            isModalClose()
            setSendMsgData({
                ...sendMsgData,
                close: false
            })
        }
    }, [sendMsgData])

    useEffect(() => {
        resetWidgetCountries(widgetCountries?.data?.allowed_countries)
    }, [widgetCountries])

    useEffect(() => {
        if (!open) return
        if (allowedCountries.length) return

        getWidgetCountries()
    }, [open])

    const resetWidgetCountries = countries => {
        const allCountry = CountryData
        const allowed = allCountry
            .filter(item => {
                if (countries?.includes(item.name)) {
                    return item
                }
            })
            .map(item => item.code.toLowerCase())

        setAllowedCountries(allowed)
    }



    const contryCode = value => {
        let values = ''
        const count = value.length - 10
        values = value?.substring(0, count)
        if (value?.substring(0, 1) !== '+') {
            values = '+' + values
        }
        return values
    }
    //   const dstat=countries?.filter((obj)=>obj?.code===option)?.[0]?.name
    return (
        <Modal
            className="send-message-modal"
            onClose={isModalClose}
            closeIcon
            open={open}
            size="tiny"
        >
            <Dimmer active={loader}>
                <Loader />
            </Dimmer>
            <div className="modal-header align-items-center">
                <h3 className="mx-auto mb-0">Widget Status</h3>
                <div className="close-icon" onClick={() => isModalClose(false)}>
                    <img src={close} alt="close" />
                </div>
            </div>
            <Modal.Content style={{ padding: '30px' }}>

                    <div className='model-status'>
                        <h3>Platform Status</h3>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{" "}</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>
                            <Table.Body>

                                    <Table.Row>
                                        <Table.Cell>
                                        Account Id
                                        </Table.Cell><Table.Cell>
                                            {profile?.id}
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Plan Status
                                        </Table.Cell><Table.Cell>
                                            {subscription?.status}
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Account Status
                                        </Table.Cell><Table.Cell>
                                            {widgetData?.active ? 'Enabled':'Disabled'}
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Approved Domains
                                        </Table.Cell><Table.Cell>
                                            {widgetData?.available_domains?.map((val)=><span>{val}</span>)}
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Route call status
                                        </Table.Cell><Table.Cell>
                                            {widgetData?.route_calls_status ? 'Enabled':'Disabled'}
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Skill based routing
                                        </Table.Cell><Table.Cell>
                                        {widgetData?.skill_based_routing_status ? 'Enabled' :'Disabled'}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Notifications
                                        </Table.Cell><Table.Cell>
                                        {widgetData?.notifications ? 'Enabled' :'Disabled'}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Auto CallBack
                                        </Table.Cell><Table.Cell>
                                        {widgetData?.auto_callback? 'Enabled':'Disabled'}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Check Spam
                                        </Table.Cell><Table.Cell>
                                        {widgetData?.check_spam? 'Enabled':'Disabled'}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Allow Business Only
                                        </Table.Cell><Table.Cell>
                                        {widgetData?.allow_business_only? 'Enabled':'Disabled'}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Allow Call From Worldwide 
                                        </Table.Cell><Table.Cell>
                                        {widgetData?.allow_call_from_worldwide? 'Enabled':'Disabled'}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Usage limit
                                        </Table.Cell><Table.Cell>
                                        {profile?.usage_limit}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Verified
                                        </Table.Cell><Table.Cell>
                                        {profile?.verified ? 'Enabled' :'Disabled'}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Credit
                                        </Table.Cell><Table.Cell>
                                    {subscription?.credits_in_dollars} $

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Outgoing countries
                                        </Table.Cell><Table.Cell>
                                    {subscription?.outgoing_white_list_countries?.join(',')}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Callback
                                </Table.Cell><Table.Cell>
                                    {`${subscription?.calls_used}(${subscription?.max_calls})`}

                                    {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Leads
                                </Table.Cell><Table.Cell>
                                    {`${subscription?.leads_used}(${subscription?.max_leads})`}

                                    {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                </Table.Cell>
                            </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                    <div className='model-status'>
                        <h3>Widget Status</h3>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{" "}</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>
                            <Table.Body>

                                  
                                    <Table.Row>
                                        <Table.Cell>
                                        Widget Status
                                        </Table.Cell><Table.Cell>
                                            {widgetData?.installed ? 'Installed' :'Not Installed'}
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Call now
                                        </Table.Cell><Table.Cell>
                                        {widgetData?.call_now ? 'Enabled' :'Disabled'}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Call later
                                        </Table.Cell><Table.Cell>
                                        {widgetData?.call_me_later ? 'Enabled' :'Disabled'}

                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Receive Offline Message
                                        </Table.Cell><Table.Cell>
                                            {profile?.voicemail_received_notify_sms? 'Enabled' :'Disabled'}
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Schedule Meeting
                                        </Table.Cell><Table.Cell>
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    
                            </Table.Body>
                        </Table>
                    </div>
                    <div className='model-status'> 
                        <h3>Telephony</h3>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{" "}</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>
                            <Table.Body>

                                 
                                   
                                    <Table.Row>
                                        <Table.Cell>
                                        Telephony
                                        </Table.Cell><Table.Cell>
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Call recording
                                        </Table.Cell><Table.Cell>
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Rate plan
                                        </Table.Cell><Table.Cell>
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Trial phone number
                                        </Table.Cell><Table.Cell>
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                        Free phone number
                                        </Table.Cell><Table.Cell>
                                            {/* {countries?.filter((obj) => obj?.code === option)?.[0]?.name} */}
                                        </Table.Cell>
                                    </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                    



          
            </Modal.Content>
            <Modal.Actions className="send-message__btn">

            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        subscription: state.home.subscription?.data,
        profile:state.settings.profile?.data,
        widgetData:state.home?.widget?.data?.[0]
    }
}

export default connect(mapStateToProps, { getWidgetCountries })(CountryModal)

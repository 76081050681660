import React, { Fragment, useState } from 'react'
import { Table, Modal } from 'semantic-ui-react'

import CommonButtons from './CommonButtons'
import CommonInput from './CommonInput'
import { CommonNotify } from './CommonNotify'

import axios from 'axios'
import CommonAddCardModal from './CommonAddCardModal'
import moment from 'moment'

const plan_name = localStorage.getItem('plan_name')

let value = '',
  numberPhone = ''

const CommonTableNo = props => {
  const { deleteHandle, style, handleBuy, noRecord } = props
  const [isCancelModalOpen, setCancelModalOpen] = useState(false)
  const [selecctedNo, setSelectedNo] = useState('')
  const [selecctedPrice, setSelectedPrice] = useState('monthly')
  const [selectedPlan, setSelectedPlan] = useState('monthly')
  const [cardNo, setCardNo] = useState('')
  const [friendlyName, setFriendlyName] = useState('')
  const [openCard, setOpenCard] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [addCreditCardLoader, setAddCreditCardLoader] = useState(false)
  const [dataModal, setDataModal] = useState({
    dataPlan: {
      planName: 'Pro',
      planPrice: '$119'
    },
    addCredits: '',
    promoCode: '',
    creditCardDetails: {
      cardName: '',
      cardNumber: '',
      validMonth: '',
      validYear: '',
      cvv: ''
    },
    creditCardDetailsErrors: {
      cardName: '',
      cardNumber: '',
      validMonth: '',
      validYear: '',
      cvv: ''
    }
  })

  const cancelModalOpen = (number, price, plan = 'monthly') => {
    value = price
    numberPhone = number
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/credit-cards`
    axios
      .get(url)
      .then(res => {
        if (res.data.data?.length) {
          res.data.data.map(row => {
            if (row.is_primary == 'yes') {
              setCardNo(row.card.last_four_digits)
              setCancelModalOpen(true)
              setSelectedNo(number)
              setSelectedPrice(price)
              setSelectedPlan(plan)
              openCard && setOpenCard(false)
            }
          })
        } else {
          // CommonNotify('Please Add a credit Card', 'warning')
          setOpenCard(true)
        }
      })
      .catch(error => {
        // CommonNotify(
        //   'Credit Card not available / Cant Fetch Credit Cards',
        //   'warning'
        // )//
      })
  }

  const handleThis = async data => {
    const plan_name = await localStorage.getItem('plan_name')
    data.plan = data.plan ? data.plan : plan_name
    setCancelModalOpen(false)
    setFriendlyName('')
    handleBuy(data)
  }

  const onAddCreditCard = () => {
    if (
      dataModal?.creditCardDetails?.cardNumber &&
      !dataModal?.creditCardDetails?.cardNumber.match(/^[-_ 0-9]+$/)
    ) {
      CommonNotify('Invalid Card No', 'warning')
      return
    }
    setAddCreditCardLoader(true)
    if (
      !dataModal?.creditCardDetails?.cardName ||
      !dataModal?.creditCardDetails?.cardNumber ||
      !dataModal?.creditCardDetails?.validMonth ||
      !dataModal?.creditCardDetails?.validYear ||
      !dataModal?.creditCardDetails?.cvv
    ) {
      CommonNotify('Please add all felids', 'warning')
      setAddCreditCardLoader(false)
      return
    }
    const date = new Date()
    const newYear = moment(date).format('yyyy')
    if (dataModal?.creditCardDetails?.validMonth > 12) {
      setAddCreditCardLoader(false)
      CommonNotify('Please enter valid expire date', 'warning')
      return
    } else {
      if (dataModal?.creditCardDetails?.validYear < newYear) {
        setAddCreditCardLoader(false)
        CommonNotify(
          'Expiry date should not be lesser then current date',
          'warning'
        )
        return
      }
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/create-customer-credit-card`
    const data = {
      name: dataModal?.creditCardDetails?.cardName,
      card_number: dataModal?.creditCardDetails?.cardNumber,
      expiry: `${dataModal?.creditCardDetails?.validMonth}/${dataModal?.creditCardDetails?.validYear}`,
      cvv: dataModal?.creditCardDetails?.cvv
    }
    axios
      .post(url, data)
      .then(res => {
        setAddCreditCardLoader(false)
        CommonNotify(`Card successfully created`, 'success')
        setOpenCard(false)
        cancelModalOpen(numberPhone, value)
      })
      .catch(err => {
        const errors = { ...err }
        setAddCreditCardLoader(false)
        this.setState({ errorMsg: '' })
        setErrorMsg('')
        if (errors?.response?.data?.errors?.length) {
          const errorMsg = errors?.response?.data?.errors?.[0]
          setErrorMsg(errorMsg)
        } else {
          setErrorMsg('Cant create payment methed.')
        }
      })
  }

  const handleCreditCardInfo = e => {
    const { name, value } = e.target
    let cloneDataModal = { ...dataModal }
    cloneDataModal.creditCardDetails[name] = value
    const ERROR_CODE = {
      cardName: 'Card Name',
      cardNumber: 'Card Number',
      validMonth: 'Month',
      validYear: 'Year',
      cvv: 'CVV'
    }

    if (!value) {
      cloneDataModal.creditCardDetailsErrors[
        name
      ] = `${ERROR_CODE[name]} is required`
      setDataModal(cloneDataModal)
      return
    } else {
      cloneDataModal.creditCardDetailsErrors[name] = ''
    }

    if (name === 'cardNumber') {
      if (value < 0) {
        cloneDataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
      } else {
        cloneDataModal.creditCardDetailsErrors[name] = ''
      }
    }

    if (name === 'validMonth') {
      if (value.toString()?.length > 2) {
        cloneDataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 2 digit long.`
      } else if (value < 0) {
        cloneDataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
      } else {
        cloneDataModal.creditCardDetailsErrors[name] = ''
      }
    }

    if (name === 'validYear') {
      if (value.toString()?.length > 4) {
        cloneDataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 4 digit long.`
      } else if (value < 0) {
        cloneDataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
      } else {
        cloneDataModal.creditCardDetailsErrors[name] = ''
      }
    }

    if (name === 'cvv') {
      if (value.toString()?.length > 4) {
        cloneDataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 4 digit long.`
      } else if (value < 0) {
        cloneDataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
      } else {
        cloneDataModal.creditCardDetailsErrors[name] = ''
      }
    }
    setDataModal(cloneDataModal)
  }

  return (
    <div className="common-table-wrapper">
      {openCard && (
        <CommonAddCardModal
          open={true}
          onCloseAddCardModal={() => setOpenCard(false)}
          handleCreditCardInfo={handleCreditCardInfo}
          creditCardDetailsErrors={dataModal?.creditCardDetailsErrors}
          errorMsg={errorMsg}
          addCreditCardLoader={addCreditCardLoader}
          onAddCreditCard={onAddCreditCard}
          updateCard={false}
        />
      )}

      <Table style={style} singleLine>
        <Table.Header className="top-table-header">
          <Table.Row>
            {props.dataTable.header.map((data, i) => {
              return (
                <Table.HeaderCell
                  key={i}
                  className="default-text header-modal-style"
                  style={{ fontSize: '16px', fontWeight: 600 }}
                >
                  {data.headerTitle}
                </Table.HeaderCell>
              )
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Fragment>
            {props.dataTable.tableContentData.map((data, i) => {
              return (
                <Table.Row
                  key={i}
                  className="table-content-row"
                  style={{ fontSize: '14px' }}
                >
                  <Table.Cell>{data.phone}</Table.Cell>
                  <Table.Cell>Yes</Table.Cell>
                  <Table.Cell>Yes</Table.Cell>
                  <Table.Cell>
                    {data?.kyc
                      ? 'Business ID and Local Address proof required'
                      : 'Not required'}
                  </Table.Cell>
                  <Table.Cell>{data.price} $ / Month</Table.Cell>
                  <Table.Cell>
                    {' '}
                    <CommonButtons
                      type="button"
                      content="+Add"
                      background="blue"
                      onClick={e => cancelModalOpen(data.phone, data.price)}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
            {noRecord && !props.dataTable.tableContentData?.length && (
              <Fragment>
                <Table.Row
                  className="table-content-row"
                  style={{ fontSize: '14px' }}
                >
                  <Table.Cell>
                    {' '}
                    Sorry, there are no numbers matching your search criteria
                  </Table.Cell>
                </Table.Row>
              </Fragment>
            )}
          </Fragment>
        </Table.Body>
      </Table>

      <Modal
        className="local-number-modal"
        open={isCancelModalOpen}
        onClose={e => setCancelModalOpen(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to add {selecctedNo} ? <br />
              Total cost $ {selecctedPrice} Per Month <br />
              You will be charged from Card {cardNo}
            </p>

            <div style={{ padding: '10px 25px 35px' }}>
              <CommonInput
                placeholder="Friendly Name for Number"
                name="call-me-now"
                type="text"
                style={{ minWidth: 200 }}
                value={friendlyName}
                required={true}
                onChange={e => setFriendlyName(e.target.value)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={e => setCancelModalOpen(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={e =>
                  handleThis({
                    number: selecctedNo,
                    price: selecctedPrice,
                    name: friendlyName,
                    plan: plan_name
                  })
                }
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
                disabled={friendlyName === ''}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default CommonTableNo

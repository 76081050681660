import Axios from 'axios'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Icon, Input, TextArea } from 'semantic-ui-react'
import { CommonNotify } from '../../common/CommonNotify'
import ShareIcon from '@mui/icons-material/Share';
import user from '../../assets/images/user-info.jpg'
import installWidget from '../../assets/images/install-widget.jpg'
import virtualNumber from '../../assets/images/virtual-number.jpg'
import moment from 'moment'
import {Avatar} from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import CountryModal from '../../common/CountryModal'
import CheckStatus from '../../common/CheckStatus'
import LinkIcon from '@mui/icons-material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import dummy from '../../assets/images/magnifier.svg'
import { Col, Row } from 'react-bootstrap'
import callForward from '../../assets/images/Call forwarding.svg'
import callManager from '../../assets/images/call manager.svg'
import callSettings from '../../assets/images/Call settings.svg'
import fromTracking from '../../assets/images/Form tracking.svg'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import qualificationSetting from '../../assets/images/Qualification settings.svg'
import referFriend from '../../assets/images/refer friend.svg'
import rewards from '../../assets/images/rewards.svg'
import ame from '../../assets/images/ame.svg'
import mobileAPP from '../../assets/images/Mobile app.svg'
import avatarImg from '../../assets/images/stock-man.jpeg'
import { getDialer } from '../../actions/dialer'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


function PlanInfo({
  subscription,
  openSubscriptionModal,
  accountInfo,
  accountButtonText,
  profileData,
  widgetData,
  getDialer,
  dialerStatus
}) {
  const navigate = useHistory()
  const [feedback, setFeedback] = useState({})
  const [sentFlag, setSentFlag] = useState(false)
  const [openCountry, setOpenCountry] = useState(false)
  const [statusModel, setStatusModel] = useState(false)
  let widgetLink = ''
  if (
    `${process.env.REACT_APP_BASE_APP_URL}` === 'https://app.limecall.com'
  ) {

    widgetLink = 'https://cdn.limecall.com/widget/prod/latest/widget.js'

  } else {

    widgetLink = 'https://cdn.limecall.com/widget/staging/latest/widget.js'

  }

  const history = useHistory()
  const handleChange = (name, value) => {
    setFeedback({
      ...feedback,
      [name]: value
    })
  }


  const onCopyText = async jsCode => {
    try {
      await navigator.clipboard.writeText(jsCode)
      CommonNotify('Copied!', 'success')
    } catch (err) {
      CommonNotify('Failed to copy!')
    }
  }

  const handleSendMessage = async () => {
    if (!feedback?.title) {
      CommonNotify('Type of issue title is required.')
      return
    }
    if (!feedback?.message) {
      CommonNotify('Message value is required.')
      return
    }
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/feedback`
    const postData = {
      subject: feedback?.title,
      message: feedback?.message
    }
    Axios.post(URL, postData)
      .then(res => {
        setSentFlag(true)
        CommonNotify('Feedback sent successfully.')
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Something went to wrong!'
        )
      })
  }

const shortCutsData=[
  {
    icon: callForward,
    redirectTo:'/settings/personal/call_forwarding',
  title:'Call Forwarding'

},
  {
    icon: callManager,
    redirectTo:'/settings/users',
    title: 'Team Members'


},
  {
    icon: callSettings,
    redirectTo:'/settings/platform/calls',
    title: 'Call Settings'


},
  {
    icon: fromTracking,
    // redirectTo:'/settings/installation',
    openWindow:'https://dialer.limecall.com/',
    title: 'Web Dialer'

},
  {
    icon: qualificationSetting,
    redirectTo:'/settings/lead_qualification'
    ,
    title: 'Lead Qualification'
},
  {
    icon: referFriend,
    openWindow:'http://docs.limecall.com/',
    title: 'Knowledgebase'
},
  {
    icon: rewards,
    redirectTo:'/settings/personal/notifications'
    ,
    title: 'Notification'
},
  {
    icon: mobileAPP,
  redirectTo:''
    ,
    title: 'Mobile App'
},
]


  const { data } = subscription || {}
  const status = JSON.parse(localStorage.getItem('phone_numbers'))
  const widgetStatus = JSON.parse(localStorage.getItem('widget_active'))
  const numbers = JSON.parse(localStorage.getItem('phone_numbers'))
  const account = JSON.parse(localStorage.getItem('account'))
  const howWorksData=[
    {
    title:'Watch a quick demo to LimeCall',
    subTitle:'Check out our product tour showing what you can achieve with LimeCall',
    linkTo:'https://docs.limecall.com/agents-usage-guide/video-tutorials/dashboard'
  },
  {
    title:'How to manage leads?',
    subTitle:'Increase your conversions by responding to inbound leads in real time',
    linkTo:'https://docs.limecall.com/agents-usage-guide/managing-leads'
  },
  {
    title:'Onboarding Guide',
    subTitle:'Check our detailed onboarding tutorials for all use cases',
    linkTo:'https://docs.limecall.com/'
    },
  ]

  return (
    <div className="deatil_box" style={{padding:'30px 50px'}}>
      <div className="row justify-content-center">
        <div className="col-md-12 p-3">


          {/* <div className="banner-description-box">
            <div className='d-flex justify-content-between mb-3'>
              <h5 className="mb-3">Welcome to Warmly</h5>



            </div>
            <div className="planinfo-box">
              <p>{`Warmly turns clicks into contacts, revealing the hidden leads and intent on your website. To see
          who's checking out your business, just add our code snippet to your site.`}</p>
            </div>
            
          </div> */}

        {widgetData?.installed ? null :   <div className="banner-description-box">
            <div className='d-flex justify-content-between mb-3'>
              <h5 className="mb-3">Snippet not installed</h5>



            </div>
            <div className="planinfo-box">
              <p>{`Copy the Limecall snippet & paste it in the <body> of your website to see leads in minutes`}</p>
            </div>
            <div className="planinfo-box" style={{ backgroundColor: '#e8f8ff', padding: '15px' }}>
              <p style={{ wordBreak: 'break-all' }}>{`<script data-api-url="${process.env.REACT_APP_BASE_APP_URL}" data-api-key="${widgetData?.script_id}" src="${widgetLink}"> </script>`}</p>
            </div>
            <div className="planinfo-box cursor-pointer" style={{justifyContent:'unset'}}>

              <Button className='mt-3' onClick={() => onCopyText(`<script data-api-url="${process.env.REACT_APP_BASE_APP_URL}" data-api-key="${widgetData?.script_id}" src="${widgetLink}"> </script>`)}>  <FileCopyIcon />Copy to clipboard </Button>
              <Button className='mt-3' onClick={() => window.open('https://help.limecall.com/en/articles/7983170-installing-the-widget')}>  <PlayArrowIcon />Watch how to guide </Button>
            </div>
          </div>}
   
        </div>
      
      </div>
        <div className="row banner-description-wrapper planinfo-box justify-content-center align-items-stretch">
          <div className="col-md-6 p-3" >
            <div className="banner-description-box h-100">
              <div className='d-flex justify-content-between mb-3'>
                <h5 className="mb-3">Account Info</h5>
                <Button basic onClick={() => setStatusModel(!statusModel)}>Check Status</Button>
              </div>
              <p style={{ color: data?.status === 'expired' || account === 4 || account === '4' ? 'red' : '' }}>
                {accountInfo
                  ? accountInfo
                  : account === 4 ? 'Your account is currently is currently paused.' : data?.status === 'expired' ? `Your active plan has expired` :
                    data?.status === 'trial_expired' ? `Your trial has expired.Please pick a plan` :
                      `Your currently active plan is ${data?.plan_name || 'N/A'}`}
                {(data?.status !== 'Active' || account === 4 || account === '4') && (
                <span className="span-text cursor-pointer" onClick={() => navigate.push('/settings/billing/subscription')}>
                    {' '}
                    {accountButtonText || 'Upgrade Plan'}
                  </span>
                )}
              </p>
              <div className="planinfo-box cursor-pointer">
                <p>Outbound calls/sms</p>
                <p>
                  {status && +status?.[0]?.outgoing_webhook?.status
                    ? 'Enabled'
                    : 'Disabled'}
                </p>
              </div>
              <div className="planinfo-box cursor-pointer">
                <p>Numbers</p>
                <p>
                  <Link to="/numbers">{profileData?.phone_numbers?.length || 0}</Link>{' '}
                </p>
              </div>
              {/* <div className="planinfo-box cursor-pointer">
                <p>Leads</p>
                <p>
                  <Link to="/manageleads">{data?.leads_used || 0}</Link>{' '}
                </p>
              </div> */}

              <div className="planinfo-box cursor-pointer">
                <p>Plan Status</p>
                <p>
                  <Link to="/settings/billing/subscription">{data?.status}</Link>{' '}
                </p>
              </div>
              <div className="planinfo-box cursor-pointer">
                <Button basic onClick={() => setOpenCountry(!openCountry)}>Whitelisted Countries</Button>
                <p>
                  {/* <Menu
              id="lock-menu"
              anchorEl={openCountry}
              open={!!openCountry}
              onClose={()=>setOpenCountry('')}
              MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
              }}
            >
              {data?.white_list_countries.map((option, index) => (
                <MenuItem
                  key={option}
                  // disabled={index === 0}
                  // selected={index === selectedIndex}
                  // onClick={(event) => handleMenuItemClick(event, index)}
                >
                
                </MenuItem>
              ))}
            </Menu> */}
                </p>
              </div>
            </div>
          </div>
          {data?.plan_name === 'Free' && (
            <div className="col-md-6 p-3" style={{ height: 'fit-content' }}>
              <div className="banner-description-box h-100">
                <h5 className="font-weight-bold">Free plan</h5>
                <p>
                  You are currently on the free plan. Please upgrade your
                  subscription to enable phone forwarding, phone call-back,
                  virtual numbers, outbound calling/sms and much more.
                </p>

                <div className="text-center">
                  <Button
                    className="btn-color-green"
                    onClick={() => {
                      openSubscriptionModal && openSubscriptionModal()
                    }}
                  >
                    Upgrade Subscription
                  </Button>
                </div>
              </div>
            </div>
          )}
          {/* {data?.status === 'in_trial' ?
            <div className="col-md-4 p-3">
              <div className="banner-description-box h-100">
                <h5 className="font-weight-bold mb-3">Free Trial</h5>
                <p>Trial Restrictions</p>
                {/* {data?.trial_end && <p>{`Your trial expires on ${moment(data?.trial_end)?.format('LT')}`}</p>} */}
                {/* <p>
                  You are currently on the {data?.plan_name} plan trial that
                  allow calls/text/forwarding to usa numbers or web app only.
                </p> */}

                {/* <div className="text-center">
                  <Button
                    className="btn-color-green"
                    onClick={() => {
                      openSubscriptionModal && openSubscriptionModal()
                    }}
                  >
                    Upgrade Subscription
                  </Button>
                </div>
              </div>
            </div>
            : (account === '4' || account === 4) ? (
              <div className="col-md-4 p-3">
                <div className="banner-description-box h-100">
                  <h5 className="font-weight-bold mb-3">Account is currently limited.</h5>
                  <p>Usage limit hit</p>
                  {/* {data?.trial_end && <p>{`Your trial expires on ${moment(data?.trial_end)?.format('LT')}`}</p>} 
                  <p>
                    You have exceeded the usage limit on leads and calls. Your account currently cannot receive any leads or make calls.  Please upgrade your plan or contact support
                  </p>

                  {/* <div className="text-center">
                  <Button
                    className="btn-color-green"
                    onClick={() => {
                      openSubscriptionModal && openSubscriptionModal()
                    }}
                  >
                    Upgrade Subscription
                  </Button>
                </div> 
                </div>
              </div>
            ) :
              <div className="col-md-4 p-3">


                <div className="banner-description-box h-100">
                  <div className='d-flex justify-content-between mb-3'>
                    <h5 className="mb-3">Snippet not installed</h5>



                  </div>
                  <div className="planinfo-box">
                    <p>{`Copy the Warmy snippet & paste it in the <body> of your website to see leads in minutes`}</p>
                  </div>
                  <div className="planinfo-box" style={{ backgroundColor: '#e8f8ff', padding: '15px' }}>
                    <p style={{ wordBreak: 'break-all' }}>{`<script data-api-url="${process.env.REACT_APP_BASE_APP_URL}" data-api-key="${widgetData?.script_id}" src="${widgetLink}"> </script>`}</p>
                  </div>
                  <div className="planinfo-box cursor-pointer">
                  
                    <Button className='mt-3' onClick={() => onCopyText(`<script data-api-url="${process.env.REACT_APP_BASE_APP_URL}" data-api-key="${widgetData?.script_id}" src="${widgetLink}"> </script>`)}>  <FileCopyIcon />Copy to clipboard </Button>
                  </div>
                </div>
                {/* <div className="banner-description-box h-100">
                <div className='d-flex justify-content-between mb-3'>
                  <h5 className="mb-3">Lead Source Integrations</h5>

                </div>
                <div className="planinfo-box cursor-pointer">
                  <p>Web Widget</p>
                  <p>
                    {widgetData?.installed ? 'Installed' : 'Not installed'}
                  </p>
                </div>
                <div className="planinfo-box cursor-pointer">
                  <p>Facebook</p>
                  <p>
                    Not connected
                  </p>
                </div>
                <div className="planinfo-box cursor-pointer">
                  <p>Google Forms</p>
                  <p>
                    Not connected
                  </p>
                </div>
              </div> 
              </div>
              
              } */}
          <div className="col-md-6 p-3" >
            <div className="banner-description-box h-100">
              <div className='d-flex justify-content-between mb-3'>
                <h5 className="mb-3">Quick Links</h5>

              </div>
              <Row>
                {shortCutsData?.map((obj, index) =>

                  <Col md={3} className='home-shortcuts'>
                    <div onClick={() => obj?.redirectTo==='/dialer' ?  getDialer(!dialerStatus)  : obj?.openWindow ? window.open(obj?.openWindow) : navigate.push(obj?.redirectTo)} className="shortcuts-icon">
                      <img src={obj?.icon} alt="dummy-icon" />
                    </div>
                    <p>{obj?.title}</p>
                  </Col>
                )}
              </Row>
            </div>
          </div>

          {/* <div className="col-md-4 p-3 ">
            <div className="banner-description-box">
        
                <>
                  <h5 className="font-weight-bold">Lead Notification Status</h5>
                  <div className="planinfo-box cursor-pointer">
                <p>Call Forwarded to:</p>
                <p>
                  <Link to="/settings/billing/subscription">{data?.status}</Link>{' '}
                </p>
              </div>
              <div className="planinfo-box cursor-pointer">
                <p>Slack:</p>
                <p>
                  <Link to="/settings/billing/subscription">{data?.status}</Link>{' '}
                </p>
              </div>
              <div className="planinfo-box cursor-pointer">
                <p>Web Dialer:</p>
                <p>
                  <Link to="/settings/billing/subscription">{data?.status}</Link>{' '}
                </p>
              </div>
              <div className="planinfo-box cursor-pointer">
                <p>SMS:</p>
                <p>
                  <Link to="/settings/billing/subscription">{data?.status}</Link>{' '}
                </p>
              </div>
              <div className="planinfo-box cursor-pointer">
                <p>Mobile App:</p>
                <p>
                  <Link to="/settings/billing/subscription">{data?.status}</Link>{' '}
                </p>
              </div>
                </>
            
            </div>
          </div> */}
          {/* <div className="col-md-4 p-3 ">
            <div className="banner-description-box">
              {sentFlag ? (
                <div className="feedback-tab">
                  <div className="feedback-boxs">
                    <h2>Feedback Sent</h2>
                    <p>Your feedback has been sent successfully</p>
                  </div>
                </div>
              ) : (
                <>
                  <h5 className="font-weight-bold mb-3">Help and Feedback</h5>
                  <div className="text-end">
                    {' '}
                    <Input
                      placeholder="Type of issue"
                      onChange={e => handleChange('title', e?.target?.value)}
                    />
                  </div>
                  <div className="text-end">
                    <TextArea
                      placeholder="Message"
                      onChange={e => handleChange('message', e?.target?.value)}
                    />
                  </div>

                  <div className="text-end">
                    <Button
                      className="btn-color-blue"
                      onClick={() => handleSendMessage()}
                    >
                      Send Message
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div> */}
          {/* <div className='col-12'>
            <h3 className='text-center fw-bolder'>Ways to use LimeCall</h3>
          </div>

            <div className="col-md-4 p-3 ">
              <div className="banner-description-box" style={{ height: '100%' }}>
              <div style={{ paddingBottom: '20px' }}>
                {/* <h5 className="font-weight-bold">On your website</h5> 
                <Link style={{ fontWeight: 'unset !important', fontSize: '17.5px' }} to='/settings/installation'>Website Widget</Link>
                {/* <div>
                  
                  <div

                    style={{
                      display: '-webkit-inline-flex'
                    }}>
                    {' '}
                  <a href={widgetData?.new_shareable_link}>Test Web Widget</a>
                </div>
                <br />
                </div> 

                {/* <div>
                  <span>Status:</span> {' '}
                  <span>{widgetData?.installed ? 'Installed' : 'Not Installed'}</span>


                  </div> 
                <br />

                <img src={installWidget} width={300} height={300} alt="personal" />
              </div>




            </div>
          </div>


          <div className="col-md-4 p-3 ">
            <div className="banner-description-box" style={{ height: '100%' }}>
              <div>
                <div className='d-flex justify-content-between'>
                  {/* <a href={`${process.env.REACT_APP_ROOT_URL}/numbers`} target="_blank">Virtual Number</a> 
                  <a href={`numbers`} style={{ fontWeight: 'unset !important', fontSize: '17.5px' }}>Virtual Number</a>
                  {/* <div

                  style={{
                    display: '-webkit-inline-flex'
                  }}>
                  {' '}

                  <a href={`${process.env.REACT_APP_ROOT_URL}/numbers`} target="_blank">Add a virtual number</a>



                </div> 

                </div>
                <div style={{ padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  {numbers?.map((obj, index) => index === 0 && <p>{obj?.phone_number}</p>)}
                </div>
                <img src={virtualNumber} width={300} height={300} alt="personal" />
              </div>
            </div>
          </div>

          <div className="col-md-4 p-3 ">
            <div className="banner-description-box" style={{ height: '100%' }}>
              <div>
                <div className='d-flex justify-content-between mb-3'>
                  <Link to='/settings/my_page' style={{ fontWeight: 'unset !important', fontSize: '17.5px' }}/* className="font-weight-bold" >Personal Booking Link</Link>

                </div>

                <div>

                  <div class="input-group input-group-sm mb-3" >
                    <input type="text" class="form-control" style={{ background: 'white' }} readOnly placeholder={profileData?.new_personal_link} aria-label="Recipient's username" aria-describedby="button-addon2" />
                    <div class="input-group-prepend" style={{ maxHeight: '28px', height: '100%' }}>
                      <button class="btn btn-outline-secondary" style={{ background: 'lightGray' }} type="button" onClick={() => onCopyText(profileData?.new_personal_link)} id="button-addon2"><FileCopyIcon /></button>
                      <button class="btn btn-outline-primary" style={{ background: 'lightGray' }} type="button" onClick={() => window.open(profileData?.new_personal_link)} id="button-addon2"><LinkIcon /></button>

                    </div>
                  </div>

                </div>
                <div class="input-group input-group-sm mb-3 mt-3" >

                  <input type="text" class="form-control" style={{ background: 'white' }} readOnly placeholder={widgetData?.new_shareable_link} aria-label="Recipient's username" aria-describedby="button-addon2" />
                  <div class="input-group-prepend" style={{ maxHeight: '28px', height: '100%' }}>
                    <button class="btn btn-outline-secondary" style={{ background: 'lightGray' }} type="button" onClick={() => onCopyText(widgetData?.new_shareable_link)} id="button-addon2"><FileCopyIcon /></button>
                    <button class="btn btn-outline-primary" style={{ background: 'lightGray' }} type="button" onClick={() => window.open(widgetData?.new_shareable_link)} id="button-addon2"><LinkIcon /></button>

                  </div>
                </div>
                <br />
                <img src={user} width={300} height={300} alt="personal" />
              </div>




            </div>
          </div> 
          {/* <div className="col-md-4 p-3 ">
            <div className="banner-description-box">
          <div style={{paddingBottom:'20px'}}>
                  <h5 className="font-weight-bold">Personal booking link</h5>
               
                  <div>    
                  <div    
                  
                  style={{
                  display: '-webkit-inline-flex'
                }}>
                    {' '}
                
              <a href={`${profileData?.personal_link}`} target="_blank">{profileData?.personal_link}</a> 
           
       
                  </div>
                  {' '}
                  <Icon
                      name="copy"
                      onClick={() =>onCopyText(profileData?.personal_link)}
                    />
                  </div>
                  </div>
                  <div style={{paddingBottom:'20px'}}>
                  <h5 className="font-weight-bold">Your personal landing page</h5>
               
                  <div>    
                  <div    
                  
                  style={{
                  display: '-webkit-inline-flex'
                }}>
                    {' '}
                
              <a href={`${profileData?.new_personal_link}`} target="_blank">{profileData?.new_personal_link}</a> 
           
       
                  </div>
                  {' '}
                  <Icon
                      name="copy"
                      onClick={() =>onCopyText(profileData?.new_personal_link)}
                    />
                  </div>
                  </div>
                  <div>
                  <h5 className="font-weight-bold">Direct Call Link</h5>
                  <div className="text-end">
                    {' '}
                    <a href={widgetData?.new_shareable_link}>{widgetData?.new_shareable_link}</a> 
                  {' '}
                 <Icon
                      name="copy"
                      onClick={() =>onCopyText(widgetData?.new_shareable_link)}
                    />
                  </div>
                  </div>
                
                  
                
               
            </div>
          </div> */}
          
          
        </div>
        <div className="row banner-description-wrapper planinfo-box justify-content-center align-items-stretch">
         <div className="col-md-6 p-3 ">
            <div className="banner-description-box" style={{ height: '100%' }}>

               <h3 className="font-bold">How it works?</h3>
                {howWorksData?.map((obj)=>
                  
                <div className='d-flex' style={{padding:'15px 0px'}}>
                  {/* <img style={{width:"100px"}} src={obj?.image} alt={"dfd"} /> */}
                  <div>
                  <Link onClick={()=>window.open(obj?.linkTo)}>{obj?.title}</Link>
                  <p>{obj?.subTitle}</p>
                  </div>
                </div>
                )}
          
              </div>
          </div>
         <div className="col-md-6 p-3 ">
            <div className="banner-description-box text-center" style={{ height: '100%' }}>

               <h3 className="font-bold ">We're here for you!</h3>
             <Avatar style={{width:'100px', height:'auto', margin:'auto'}} alt="Remy Sharp" src={avatarImg} />
          <p style={{fontSize:'17px', fontWeight:600}}>Vincent Hawley</p>
          <p>vincent@limecall.com</p>
            <Button onClick={() => window.Calendly.initPopupWidget({ url: 'https://calendly.com/vincent-limecall/discovery-call-clone' })} variant="contained">Book an appointment <ArrowForwardIcon/> </Button>
        
              </div>
          </div>
        </div>
      {openCountry &&
        <CountryModal open={openCountry} isModalClose={() => setOpenCountry(!openCountry)} />
      }
      {statusModel &&
        <CheckStatus open={statusModel} isModalClose={() => setStatusModel(!statusModel)} />
      }
    </div>
  )
}

const mapStateToProps = state => ({
  subscription: state.home.subscription,
  profileData: state.settings?.profile?.data,
  widgetData: state.home?.widget?.data?.[0],
  dialerStatus: state.dialer.currentStatus

})

export default connect(mapStateToProps, { getDialer })(PlanInfo)

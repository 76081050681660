/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'
import moment from 'moment'
import {
  Dropdown,
} from 'semantic-ui-react'
import { endpoints } from '../features/api/apiSlice'

import axios from 'axios'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

import { CommonNotify } from '../common/CommonNotify'

import leadLogo from '../assets/images/lead-logo.svg'
import {
  dayData,
  dayLabels,
  weekLabels,
  weekData,
  monthLabels,
  monthData
} from '../lib/LeadChartData'

import { getLeadOwner } from '../config/leadAPI'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { SET_FLAG } from '../actions/types'
import CommonCheckbox from '../common/CommonCheckbox'
import CommonButtons from '../common/CommonButtons'


const leadSubStatus = [
  // { key: 'new', text: 'Unassigned', value: 'new', var: 'totalUnassignedLeads' },
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
    var: 'totalpendingLeads',
    scheduleText: 'Upcoming'
  },
  {
    key: 'in-progress',
    text: ' Assigned',
    value: 'in-progress',
    var: 'totalinprogressleads',
    scheduleText: 'Past'
  },
  /* {
    key: 'qualified',
    text: 'Qualified',
    value: 'Qualified',
    var: 'totalQualifiedLeads'
  }, */
  {
    key: 'closed',
    text: 'Closed',
    value: 'Closed',
    var: 'totalClosedLeads',
    scheduleText: 'Cancelled'
  },
  {
    key: 'all',
    text: 'All',
    value: 'all',
    var: 'totalLeads',
    scheduleText: 'All'
  }
]

const leadStatusOutboundCalls = [
  // { key: 'new', text: 'Unassigned', value: 'new', var: 'totalUnassignedLeads' },
  {
    key: 'missed',
    text: 'Missed',
    value: 'failed_to_connect_agent',
    var: 'totalMissedOutgoingLeads'
  },
  {
    key: 'completed',
    text: 'Completed',
    value: 'completed',
    var: 'totalCompletedOutgoingLeads'
  },
  {
    key: 'voicemail',
    text: 'Voicemail',
    value: 'voicemail',
    var: 'totalVoicemailOutgoingLeads'
  },
  { key: 'all', text: 'All', value: 'all', var: 'totalOutgoingLeads' }
]

class CardFilters extends Component {
  state = {
    selectedRecordIds: [],
    settingModal: false,
    totalLeads: 0,
    tableFullData: null,
    totalUnassignedLeads: 0,
    totalpendingleads: 0,
    totalinprogressleads: 0,
    totalQualifiedLeads: 0,
    totalClosedLeads: 0,
    totalMissedOutgoingLeads: 0,
    totalCompletedOutgoingLeads: 0,
    totalOutgoingLeads: 0,
    totalVoicemailOutgoingLeads: 0,
    deleteModal: false,
    widget: '',
    allTags: [],
    updatedPane: 0,
    updatedtable: 0,
    updateList: false,
    listModal: false,
    segmentName: '',
    listName: '',
    segmentActive: false,
    selectedList: -2,
    //data for chart
    leadScoreFilter: [],
    allLeadSubStatus: leadSubStatus,
    currentLeadSubStatus: leadSubStatus[0].value,
    membrs: [],
    allOwners: [],
    allOwners2: [],
    allAgents: [],
    dataLoaded: false,
    sortMain: '-id',
    rightPane: false,
    nextLeadId: 0,
    prevLeadId: 0,
    leadData: [],
    leadScore: [],
    selectedPickerOption: 'All Time',
    sortPickerOption: 'Sort By',
    id: '',
    widget: '',
    params: {
      
    },
    filterTable: {
      startDateFilter: moment(),
      endDateFilter: moment()
    },
    activePage: 15,
    isLoading: false,
    activeIndex: 0,
    setOfficeHour2: {
      addOfficeHour2: '',
      link: 'Link',
      officeHourFrom: '',
      officeHourTo: ''
    },
    addOfficeHour2: [1],
    selectCheckboxData: [],
    callLog: {
      title: '',
      legend: false,
      stepSize: '10',
      yAxes: true,
      padding: {
        top: '20',
        right: '20',
        bottom: '0',
        left: '0'
      },
      fontSize: 14,
      fontStyle: '600',
      gridLines: true,
      labels: [
        '',
        '23/03/29',
        '23/04/2019',
        '23/05/2019',
        '23/06/2019',
        '23/07/2019',
        '23/08/2019',
        '23/10/2019'
      ],
      datasets: [
        {
          label: 'call logs',
          fill: false,
          backgroundColor: 'rgba(249,166,9,1)',
          borderColor: 'rgba(249,166,9,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(249,166,9,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(249,166,9,1)',
          pointHoverBorderColor: 'rgba(249,166,9,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 8,
          pointHitRadius: 10,
          data: [0, 10, 3, 9, 4, 5, 8, 2],
          options: {
            bezierCurve: true,
            title: {
              fontSize: 25
            },
            tooltips: {
              mode: 'point',
              enabled: true
            },
            layout: {
              padding: {
                left: 50,
                right: 0,
                top: 0,
                bottom: 0
              }
            }
          }
        }
      ],
      isDayActive: false,
      isWeekActive: true,
      isMonthActive: false
    },
    tableData: {
      messages: [],
      call: [],
      schedules: [],
      isCall: true,
      isMessages: false,
      isSchedules: false,
      isOutBound: false,
      isDigital: false
    },

    tableDataContent: [],
    leadStage: [],
    leadOwner: [],
    widget_id: null,

    //for tanble pagination
    tableDataTotalItem: 0,
    pageNumber: 1,
    totalPages: null,
    startItemCount: 0,
    endItemCount: 0,

    //edit fields checkbox state
    nodeCheckBoxFields: [],

    //filter fields state
    filterTable: {
      type: null,
      startDateFilter: [],
      endDateFilter: new Date(),
      status: null,
      search: null,
      score: null,
      agent: null
    },

    query: null,
    //check if all marked
    isMarkAllCheckbox: false,
    countItem: 0,
    showExport: false,
    isChecked: false,
    //modal state
    open: false,
    showDatePicker: false,
    animation: 'overlay',
    direction: 'left',
    directionMobile: 'top',
    visible: false,
    sortField: 'id',
    sortValue: 'DESC',
    exportData: [],
    dropDownData: [],
    filterCondition: 'is',
    filterDropDownOption: {
      score: null,
      type: null,
      owner: null,
      agent: null,
      final_status: null,
      tags: [],
      interseted2: [],
      source: [
        { key: 'no-referer', value: '', text: 'All Source' },
        { key: 'localhost', value: 'localhost', text: 'Localhost' },
        { key: 'qa.limecall', value: 'qa.limecall', text: 'QA Limecall' },
        { key: 'app.limecall', value: 'app.limecall', text: 'App Limecall' }
      ],
      status: [
        { key: 'Pending', value: 1, text: 'Pending' },
        { key: 'In Progress', value: 2, text: 'In Progress' },
        { key: 'Qualified', value: 4, text: 'Qualified' },
        { key: 'Closed', value: 3, text: 'Closed' }
      ],
      interseted: [
        { key: 0, value: '', text: 'All' },
        { key: 1, text: 'Good Fit', value: 1 },
        { key: 2, text: 'Pending', value: 2 },
        { key: 3, text: 'Bad Fit', value: 3 }
      ]
    },

    queryValue: {
      agent: { type: 'is', value: '' },
      phone_number: { type: 'begin', value: '' },
      final_status: { type: 'is', value: '' },
      type: { type: 'in', value: '' },
      status: { type: 'is', value: '' },
      owner_id: { type: 'is', value: '' },
      direction: { type: 'is', value: '' },
      // flags: { type: 'in', value: [] },
      stage: { type: 'is', value: '' },
      score: { type: 'is', value: '' },
      source: { type: 'contain', value: '' },
      tags: { type: 'in', value: '' },
      interseted: { type: 'is', value: '' }
    },
  }



 



  


  reloadLeads = () => {
    //this.fetchDataAfterDelete()
    this.fetchData()
  }

  fetchAllTags = widget => {
    this.setState({
      widget
    })

  }





  fetchWidget = async () => {
    const token = await localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets?limit=3&offset=0`

    const settings = {
      url: URL,
      method: 'GET',
      timeout: 0,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false
    }

    axios(settings)
      .then(res => {
        this.setState({ widget: res.data.data[0].id })
        this.fetchAllTags(res.data.data[0].id)
        localStorage.setItem('widget_active', res?.data?.data?.[0]?.active)
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }


  resetPage = () => {
    let { tableData, tableDataContent, pageNumber } = this.state

    tableData.messages = []
    tableData.schedules = []
    tableData.call = []

    tableDataContent = []

    this.setState({
      sortField: 'id',
      sortValue: 'DESC',
      tableDataContent,
      isMarkAllCheckbox: false,
      pageNumber,
      tableData,
      selectedRecordIds: []
    })
  }

 

  setUserId = res => {
    const data = []
    res.data.data.map(number => {
      data.push({
        key: number[0],
        value: number[0],
        text: `LimeCall Number (${number[0]})`
      })
    })

    this.setState({
      ...this.state,
      dropDownData: data
    })
  }

  removeFilter = async () => {
    let {
      query,
      filterDropDownOption,
      selectedValue,
      filterCondition,
      reloadNeed
    } = this.state
    await this.setState(
      {
        selectedValue: '',
        query: '',
        filterCondition: '',
        segmentActive: false
      },
      this.reloadData
    )
  }

  reloadData = () => {
    let { reloadNeed } = this.state
    if (!reloadNeed) return
    this.fetchData()
    this.setState({ reloadNeed: false })
  }

  fetchFilterData = async data => {
    await this.setState({ reloadNeed: true, segmentActive: true })
    this.fetchData()
  }

  
  handleCheckboxes=async(value)=>{
    if(value==='closed'){

      await this.setState({closed: !this.state.closed})
this.fetchData()
    }
    if(value==='archived'){

      await this.setState({ archived: !this.state.archived })
this.fetchData()
    }
  }

  fetchData = async data => {
    const {
      selectedValue,
      query,
      filterCondition,
      sortField,
      sortValue,
      pageNumber,
      closed,
      archived,
      sortMain
    } = this.state
    const leadType = this.getLeadTypeNew()
    var url

    var params = data ? data: { }
    
    if(closed){
      params['filter[additional_status][!is]'] = 3
    }
    if(archived){
      params['filter[flags][in]'] = 32
    }
    if (query) {
      if (selectedValue === 'Date') {
        var cond = 'like'
        filterCondition === '!is' ? (cond = '!like') : (cond = 'like')
        params = {
          [`filter[created_at][${cond}]`]: query,
          limit: 10,
          page: pageNumber,
          sort_field: sortField,
          sort_value: sortValue
        }
      } }

  
    if (leadType !== 'LIVE_CALL' && leadType !== 'OUTBOUND_CALL') {
      params['filter[type][is]'] = leadType

    }



    for (const key in this.state.queryValue) {
      if (this.state.queryValue[key].value) {
        params[
          `filter[${key}][${this.state.queryValue[key].type}]`
        ] = this.state.queryValue[key].value
      }
    }

    this.setState({ params })  
const ff= this.props.pipeline?.filter(obj => obj?.is_default)?.[0]
    url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/funnel/${this.props.funnel?.id || ff?.id}/leads`
    axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: params
      })
      .then(res => {
        
          const data = res.data.data
        const col = this.props.existingColumn?.map((obj, index) => ({ ...obj, cards: [] }))
        let coll = []
        const dd = data?.forEach(async obj => {
          const stageCol = col?.filter(val => val?.title === obj?.stage)?.[0]
          if (stageCol) {
            const updated = stageCol?.cards?.push(obj)
          }
          coll = col?.map((obj, index) =>
            obj?.title === stageCol?.title ? stageCol : obj
          )
          await  this.props.setColumns(coll)
        })
        this.props?.getConversation(coll)

        this.props.saveLeads(res.data.data)
        this.setState({
          isLoading: false,
          exportData: [],
          countItem: 0,
          tableFullData: res.data.data
        })
        this.setFilterDropDownOption(res.data.data)
        this.resetPage()
        this.storeTableData(res.data.data)
        this.setLeadNo(res.data.data)
        this.storeSegmentLead(res.data.data)
      })
      .catch(err => {
        this.setState({ isLoading: false })

        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  storeSegmentLead = data => {
    const leads = data.map(lead => {
      return lead.id
    })
    this.setState({ segmentLeads: leads })
  }

  setLeadNo = data => {
    localStorage?.setItem('pendingLeads', data.totalPendingLeads || 0)
    this.props.loadPendingList({ pendingLeads: data.totalPendingLeads || 0 })

    this.setState({
      totalLeads: data.totalLeads,
      totalinprogressleads: data.totalInProgressLeads,
      totalpendingLeads: data.totalPendingLeads,
      totalQualifiedLeads: data.totalQualifiedLeads,
      totalUnassignedLeads: data.totalUnassignedLeads,
      totalClosedLeads: data.totalClosedLeads,
      totalMissedOutgoingLeads: data?.totalMissedOutgoingLeads,
      totalCompletedOutgoingLeads: data?.totalCompletedOutgoingLeads,
      totalOutgoingLeads: data?.totalOutgoingLeads,
      totalVoicemailOutgoingLeads: data?.totalVoicemailOutgoingLeads
    })
  }

  fetchDataAfterDelete = async data => {
    //const apiToken = await localStorage.getItem('access_token')

    const {
      selectedValue,
      query,
      filterCondition,
      sortField,
      sortValue,
      pageNumber
    } = this.state

    /* const header = {
      Authorization: 'Bearer ' + apiToken
    } */

    const leadType = this.getLeadTypeNew()
    var url

    var params = {

    }

    // if(leadType !== "LIVE_CALL")
    // {
    // params['filter[type][is]'] = leadType
    // }
    // else {
    //   params['filter[type][in]'] = 'LIVE_CALL,DIGITAL_CALL,UNSUPPORTED_COUNTRY,USER_BLACKLISTED,LIVE_MESSAGE'
    // }

    if (leadType !== 'LIVE_CALL' && leadType !== 'OUTBOUND_CALL') {
      params['filter[type][is]'] = leadType
    } else {
      params['filter[type][!in]'] =
        'TEXT_MESSAGE'

      params['filter[direction][is]'] = 'Inbound'
      if (leadType == 'OUTBOUND_CALL') {
        params['filter[direction][is]'] = 'Outbound'
      }
    }

    const leadSubType = this.state.currentLeadSubStatus

    if (leadSubType !== 'all') {
      if (leadSubType === 'new') {
        params['filter[owner_id][!is]'] = ''
      } else if (leadSubType === 'pending') {
        params['filter[status][is]'] = '1'
      } else {
        params['filter[status][is]'] = '4'
      }
    }

    this.setState({ params })


   
  }

 

  setFilterDropDownOption = datas => {
    const fStatus = [
      { value: 'failed_to_connect_agent', text: 'Missed call' },
      { value: 'completed', text: 'Connected' },
      { value: 'suspended', text: 'Blocked' }
    ]

    const final_status = fStatus.map((st, index) => {
      return {
        key: index + 1,
        text: st.text,
        value: st.value
      }
    })

    final_status.unshift({
      key: 0,
      text: 'All',
      value: 0
    })

    const agent = this.state.allOwners

    const score = this.state.leadScore
    const owner = this.state.allOwners2
    const tag = this.state.allTags.map(item => {
      return {
        key: item.tag,
        value: item.tag,
        text: item.tag
      }
    })


    const type = [
      {
        key: 0,
        text: 'All Call Type',
        value: ''
      },
      {
        key: 1,
        text: 'SCHEDULE_CALL',
        value: 'SCHEDULE_CALL'
      },
      {
        key: 2,
        text: 'LEAVE_MESSAGE',
        value: 'LEAVE_MESSAGE'
      },
      {
        key: 3,
        text: 'LIVE_CALL',
        value: 'LIVE_CALL'
      },
      {
        key: 4,
        text: 'DIGITAL_CALL',
        value: 'DIGITAL_CALL'
      },
      {
        key: 5,
        text: 'UNSUPPORTED_COUNTRY',
        value: 'UNSUPPORTED_COUNTRY'
      },
      {
        key: 6,
        text: 'USER_BLACKLISTED',
        value: 'USER_BLACKLISTED'
      }
    ]

    const status = [
      { key: 'All', text: 'All Status', value: 0 },
      { key: 'Pending', text: 'Pending', value: 1 },
      { key: 'Reviewed', text: 'Reviewed', value: 2 },
      { key: 'Qualified', text: 'Qualified', value: 4 },
      { key: 'Closed', text: 'Closed', value: 3 }
    ]

    this.setState({
      ...this.state,
      filterDropDownOption: {
        ...this.state.filterDropDownOption,
        owner,
        score,
        type,
        agent,
        final_status,
        status,
        tags: tag
      }
    })
  }
  
  
    componentDidUpdate(prevProps, prevState){
      if (this.props?.funnel?.id !== prevProps?.funnel?.id) {
        this.fetchData();
      }
    
      
    }
  componentDidMount(){
    // this.props.getLeads(this.state.params)
    let permitRoles = ['Admin', 'MANAGER', 'Manager']
    let role_name = localStorage.getItem('role_name')
    const role = permitRoles.includes(role_name)
    this.setState({ userRole: role_name, deleteLead: role })
    document.title = 'Pipeline'
    this.fetchData()

    this.getOwner()
    this.fetchWidget()
  }
  


  getMainTabQuery = () => {
    const leadType = this.getLeadTypeNew()
    if (leadType !== 'LIVE_CALL') {
      return leadType
    } else {
      return 'LIVE_CALL,DIGITAL_CALL'
    }
  }


  getOwner = async () => {
    const owner = [
      {
        key: 'unassign owner',
        text: 'Unassign owner',
        value: null
      }
    ]
    const allMembers = []
    const allMembers2 = []

    await getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin
        await owner.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.id
        })

        allMembers.push({
          key: 0,
          text: 'All Agents',
          value: 0
        })

        await allMembers.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.first_name + ' ' + admin.last_name
        })

        allMembers2.push({
          key: 0,
          text: 'All Owners',
          value: 0
        })

        await res.data.data.members.map(team => {
          owner.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
          allMembers.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.first_name + ' ' + team.last_name
          })

          allMembers2.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
        })
        this.setState({
          ...this.state,
          leadOwner: owner,
          allOwners: allMembers,
          allOwners2: allMembers2,
          allAgents: allMembers
        })
      })
      .catch(err => { })
  }
  



  storeTableData = data => {
    const dataA = data.data
    let { pageNumber, totalPages } = this.state
    totalPages = data.last_page
    let newData = []
    let LiveNewData = []
    const lType = this.getLeadTypeNew()
    let s = ''

    if (lType === 'LIIVE_CALL') {
      dataA.map(val => {
        if (val.type !== 'LEAVE_MESSAGE' && val.type !== 'SCHEDULE_CALL') {
          LiveNewData.push(val)
        }
      })
    } else {
      LiveNewData = dataA
    }

    LiveNewData.map(val => {
      if (val.source) {
        if (
          val.source.indexOf('http://') == 0 ||
          val.source.indexOf('https://') == 0
        ) {
          const d = val.source
          s = d.split('//')

          if (s[1]) {
            const n = s[1].split('/')
            s = n[0] ? n[0] : n
          }
        } else {
          const d = val.source
          s = d.split('.')

          if (s[1]) {
            const n = s[1].split('/')
            s = n[0] ? n[0] : n
          }
        }
      }
      let item = {
        id: '#' + val.id,
        schedule_call_status: val.schedule_call_status,
        request_call_time: val.request_call_time,

        owner_id: val.owner_id,
        defaultStage: val.stage,
        stage: val.stage,

        time: val.created_at,
        contact: val.phone_number,
        phone_number: val.phone_number,
        email: val.email,
        source: val.source ? s : 'Unknown',
        interseted: val.interseted,
        call_status: val.final_status,
        final_status: val.final_status,
        status: val.status ? val.status : 1,
        recording_url: val.recording_url,
        duration: val.duration,
        tags: JSON.parse(val.tags) || [],
        agent: val.agent,
        score: val.score == null || val.score <= 1 ? 1 : val.score,
        cost: val.cost,
        failure_reason: val.failure_reason,
        direction: val.direction,
        customerName: val.customer_name,
        meetingLocation: val.meeting_location,
        contactName: val.contact,
        type: val.type,
        isHoverDisplay: false
      }

      if (
        item.customerName !== null &&
        item.customerName !== 'null' &&
        item.customerName !== 'undefined'
      ) {
        item.contact = item.customerName
        item.isHoverDisplay =
          item.email === null && val.phone_number === null ? true : false
      } else if (item.contact !== null) {
        item.contact = item.contact
      } else if (item.email !== null && item.email !== 'undefined') {
        item.contact = item.email
      } else if (
        item.contact === null &&
        item.contactName === null &&
        item.email === null &&
        item.customerName === null &&
        item.customerName === 'undefined' &&
        item.email === 'undefined'
      ) {
        item.contact = 'Unknown'
      }

      newData.push(item)
    })

    const leadType = this.getLeadType()
    let call = false
    let message = false
    let schedule = false
    let digital = false
    let outbound = false
    if (leadType == 'call') {
      call = true
    } else if (leadType == 'message') {
      message = true
    } else if (leadType == 'digital') {
      digital = true
    } else if (leadType == 'OUTBOUND_CALL') {
      outbound = true
    } else {
      schedule = true
    }
    this.manageIdState(newData)
    this.setState({
      isLoading: false,
      tableDataContent: newData,
      isMarkAllCheckbox: false,
      pageNumber,
      totalPages,
      tableData: {
        messages: newData,
        call: newData,
        schedules: newData,
        isCall: call,
        isMessages: message,
        isSchedules: schedule,
        isOutBound: outbound,
        isDigital: digital
      }
    })
  }





  componentWillUnmount() {
    if (this.interval) {
      clearTimeout(this.interval)
      this.interval = 0
    }
  }






  handleEndDate = date => {
    const { filterTable } = this.state

    filterTable.endDateFilter = date
    this.setState({ ...filterTable })
  }

  onClickIncrementPage = () => {
    let { pageNumber, tableDataTotalItem } = this.state
    const limit = 10
    let totalPages = Math.ceil(tableDataTotalItem / limit)

    if (pageNumber <= 1) {
      pageNumber = pageNumber + 1
      this.setState({ pageNumber }, () => {
        this.fetchData()
      })
    } else if (pageNumber > 1) {
      if (pageNumber > 1) {
        pageNumber = pageNumber - 1
        this.setState({ pageNumber }, () => {
          this.fetchData()
        })
      }
    }
  }

  onClickDecrementPage = () => {
    let { pageNumber } = this.state

    if (pageNumber > 1) {
      pageNumber = pageNumber - 1
      this.setState({ pageNumber }, () => {
        this.fetchData()
      })
    }
  }



  manageIdState = data => {
    this.setState({
      selectedRecordIds: data
        ?.filter(list => {
          if (list?.isChecked === true) {
            return list?.id?.substring(1)
          }
        })
        ?.map(value => {
          return value?.id?.substring(1)
        })
    })
  }

 





  onClickWeek = () => {
    let { callLog } = this.state

    const week = weekLabels
    const data = weekData

    callLog.labels = week
    callLog.datasets[0].data = data
    callLog.isDayActive = false
    callLog.isWeekActive = true
    callLog.isMonthActive = false

    this.setState({ callLog })
  }

  onClickMonth = () => {
    let { callLog } = this.state

    const month = monthLabels
    const data = monthData

    callLog.labels = month
    callLog.datasets[0].data = data
    callLog.isDayActive = false
    callLog.isWeekActive = false
    callLog.isMonthActive = true

    this.setState({ callLog })
  }

  getLeadType = () => {
    let { tableData } = this.state

    if (tableData.isDigital) return 'digital'
    if (tableData.isCall) return 'call'
    if (tableData.isMessages) return 'message'
    if (tableData.isSchedules) return 'SCHEDULE_CALL'
    if (tableData.isOutBound) return 'OUTBOUND_CALL'
  }

  getLeadTypeNew = () => {
    let { tableData } = this.state
    if (tableData.isDigital) return 'DIGITAL_CALL'
    if (tableData.isCall) return 'LIVE_CALL'
    if (tableData.isMessages) return 'LEAVE_MESSAGE'
    if (tableData.isSchedules) return 'SCHEDULE_CALL'
    if (tableData.isOutBound) return 'OUTBOUND_CALL'
  }

  getLeadCount = type => {
    localStorage?.setItem('pendingLeads', this.state.totalPendingLeads || 0)
    this.props.loadPendingList({ pendingLeads: this.state.totalPendingLeads || 0 })

    if (type == 'all') {
      return this.state.totalLeads
    } else if (type == 'pending') {
      return this.state.totalPendingLeads
    } else if (type == 'qualified') {
      return this.state.totalQualifiedLeads
    } else if (type == 'in-progress') {
      return this.state.totalinprogressLeads
    } else {
      return this.state.totalUnassignedLeads
    }
  }

  onClickTableCategory = e => {
    let {
      tableData,
      tableDataContent,
      pageNumber,
      allLeadSubStatus
    } = this.state
    var target = e.target.innerHTML
    this.setState({ mainTab: target })
    if (target !== 'Outbound Calls') {
      this.setState({
        allLeadSubStatus: leadSubStatus,
        currentLeadSubStatus: leadSubStatus[0].value
      })
    } else {
      this.setState({
        allLeadSubStatus: leadStatusOutboundCalls,
        currentLeadSubStatus: leadStatusOutboundCalls[3].value
      })
    }
    var status1st = allLeadSubStatus[3].value
    if (
      target === 'Calls' ||
      target === 'Leads' ||
      target === 'Scheduled Calls'
    ) {
      this.setState({
        allLeadSubStatus: leadSubStatus,
        currentLeadSubStatus: leadSubStatus[0].value
      })
    } else {
      this.setState({
        allLeadSubStatus: leadSubStatus,
        currentLeadSubStatus: status1st
      })
    }

   
    pageNumber = 1
    this.setState(
      {
        tableDataContent,
        isMarkAllCheckbox: false,
        pageNumber,
        sortField: 'id',
        sortValue: 'DESC'
      },
      () => {
        this.fetchData()
      }
    )
  }





  show = size => () => this.setState({ size, open: true })
  close = () => this.setState({ open: false })



  openExportFile = e => {
    const dropDown = document.querySelector('.holder-export-options')

    if (dropDown?.classList) {
      dropDown.classList.toggle('show')
    }
  }

  handleChange = (event, data) => {
    this.setState({ selectedValue: data.value })
  }

  getTagValue = data => {
    if (Array.isArray(data)) {
      return data
    }
    return data.split(',')
  }

  handleChangeNew = (e, data, param) => {
    const qValue = { ...this.state.queryValue }

    if (param == 'tags') {
      if (data.value[0] == '') {
        data.value.splice(0, 1)
      }
      data.value = data.value?.join(',')
    }

    qValue[param].value = data?.value
    this.setState({ queryValue: qValue }, () => {
      this.fetchFilterData()
    })

  }

  handleChangeNew2 = (data, param) => {
    const qValue = { ...this.state.queryValue }

    qValue[param].value = data
    this.setState({ queryValue: qValue })
    this.setState({ selectedValue: param })
    // this.fetchFilterData()
    // this.onChangeInputFilter(e, data)
  }

  handleChangeNew3 = e => {
    if (
      e.key === 'Enter' ||
      this.state.queryValue['phone_number'].value == ''
    ) {
      this.fetchFilterData()
    }
  }

  selectFilter = (e, data) => {
    const { query } = this.state
    this.setState(
      {
        ...this.state,
        query: data.value
      },
      () => this.fetchFilterData()
    )
  }



  //Used for to delete the selected LEAD
  
 

  listUpdate = async id => {
    let { listName, exportData } = this.state

    const apiToken = await localStorage.getItem('access_token')
    if (!exportData?.length) {
    }

    var leads = []
    exportData.map(data => {
      leads.push(data.id.replace('#', ''))
    })

    let data = { name: listName, lead_ids: leads }

    this.setState({ isLoading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists/${id}/leads`
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        this.setState({ isLoading: false })
        CommonNotify('Segment Saved', 'success')
      })
      .catch(err => {
        this.setState({ isLoading: false })
        CommonNotify('Segment Name Exists / not available')
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
          //logout
        }
      })
  }

  render() {
 
  








    return (
      <div className="holder-filter-leads">
        <div className="filter-lead" style={{ width: '100%' }}>
          <div className="filter-tab">
            <div
              className="filter-holder"
              style={{
                width: '100%'
              }}
            >
              <div
                className="filter-condition"
                style={{
                  width: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                  }}
                >
                  <Dropdown
                    className="condition-select"
                    style={{
                      margin: '0 20px 10px 0px',
                      flex: '0 1 10em',
                      height: 'inherit',
                      boxShadow: 'none'
                    }}
                    selection
                    options={
                      this.state.filterDropDownOption['owner']
                    }
                    placeholder="Lead Owner"
                    value={
                      this.state.queryValue['owner_id'].value
                    }
                    onChange={(e, data) =>
                      this.handleChangeNew(
                        e,
                        data,
                        'owner_id'
                      )
                    }
                  />

                  <Dropdown
                    className="condition-select"
                    style={{
                      margin: '0 20px 10px 0px',
                      flex: '0 1 10em',
                      height: 'inherit',
                      boxShadow: 'none'
                    }}
                    selection
                    options={
                      this.state.filterDropDownOption['agent']
                    }
                    placeholder="Agent"
                    value={
                      this.state.queryValue['agent'].value
                    }
                    onChange={(e, data) =>
                      this.handleChangeNew(e, data, 'agent')
                    }
                  />



                  <Dropdown
                    className="condition-select"
                    style={{
                      margin: '0 20px 10px 0px',
                      flex: '0 1 10em',
                      height: 'inherit',
                      boxShadow: 'none'
                    }}
                    selection
                    options={this.state.filterDropDownOption['type']}
                    placeholder="Call Type"
                    value={this.state.queryValue['type'].value}
                    onChange={(e, data) =>
                      this.handleChangeNew(e, data, 'type')
                    }
                  />



                  <Dropdown
                    className="condition-select"
                    style={{
                      margin: '0 20px 10px 0px',
                      flex: '0 1 10em',
                      maxWidth: '240px',
                      height: 'inherit',
                      boxShadow: 'none'
                    }}
                    selection
                    options={
                      this.state.filterDropDownOption[
                      'interseted'
                      ]
                    }
                    placeholder="Qualification"
                    value={
                      this.state.queryValue['interseted']
                        ?.value
                    }
                    onChange={(e, data) =>
                      this.handleChangeNew(
                        e,
                        data,
                        'interseted'
                      )
                    }
                  />

                  <Dropdown
                    className="condition-select"
                    style={{
                      margin: '0 20px 10px 0px',
                      flex: '0 1 10em',
                      maxWidth: '240px',
                      height: 'inherit',
                      boxShadow: 'none'
                    }}
                    selection
                    options={
                      this.state.filterDropDownOption[
                      'final_status'
                      ]
                    }
                    placeholder="Call Status"
                    value={
                      this.state.queryValue['final_status']
                        ?.value
                    }
                    onChange={(e, data) =>
                      this.handleChangeNew(
                        e,
                        data,
                        'final_status'
                      )
                    }
                  />

                  <div style={{ margin: ' 0px 20px 10px 0px' }}>

                    <CommonCheckbox text="Show closed leads" onClick={() => null} onChange={() => this.handleCheckboxes('closed')} />
                  </div>
                    {/* <div style={{ margin: ' 0px 20px 10px 0px' }}>

                      <CommonCheckbox text="Show archived leads" onClick={() => null} onChange={() => this.handleCheckboxes('archived')} />
                    </div> */}

                  <div>
                    <CommonButtons
                      content="Add Lead"
                      background="blue"
                      style={{ height: '35px' }}
                      onClick={() =>
                        this.props.setLeadModal(true)
                      }
                    /> </div>
                </div>   </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const mapState = (state, ownProps) => ({})

const mapDispatch = (dispatch) => ({
  getLeads: endpoints.getLeads.initiate,
  loadPendingList: (data) => dispatch({
    type: SET_FLAG,
    payload: data
  })
})


const connector = connect(mapState, mapDispatch)

export default withRouter(connector(CardFilters))

import React, { Component } from 'react'

import Title, { isAdmin } from '../common/Title'

import HomeTrial from '../components/home/HomeTrial'
import HomeContent from '../components/home/HomeContent'

import Checklist from '../components/home/HomeChecklist'

import ActivityChart from '../components/home/ActivityChart'
import HomeBottomSupport from '../components/home/HomeBottomSupport'
import CommonSubscriptionModal from '../common/CommonSubscriptionModal'
import AppsumoSubscriptionModal from '../common/AppsumoSubscriptionModal'
import ActivityBox from '../components/home/ActivityBox'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import CommonDateRangePicker from '../components/daterangepicker/CommonDateRangePicker'
import { Dimmer, Loader, Dropdown, Modal } from 'semantic-ui-react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { logOut } from '../common/ProfileModal'

//redux
import { connect } from 'react-redux'
import {
  getWidget,
  getData,
  getSubscription,
  getPersonalLink
} from '../actions/home'
import {
  GET_DATA_TODAY,
  GET_DATA_YESTERDAY,
  GET_DATA_THIS_WEEK,
  GET_DATA_LAST_WEEK,
  GET_DATA_THIS_MONTH,
  GET_DATA_LAST_MONTH
} from '../actions/types'
import LeadStatusBox from '../components/home/LeadStatusBox'
import PlanInfo from '../components/home/PlanInfo'
import Graph from '../components/home/Graph'
import LeadOverView from '../components/home/LeadOverView'
import MeetingOverView from '../components/home/MeetingOverView'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import PhoneInput from 'react-phone-input-2'
import AdminVerification from '../common/AdminVerification'
import { analytics } from '../Segmant'
import CallbackModal from '../common/CallbackModal'
import CommonButtons from '../common/CommonButtons'

const options = [
  { key: 1, text: 'Today', value: 'Today' },
  { key: 2, text: 'Yesterday', value: 'Yesterday' },
  { key: 3, text: 'This week', value: 'This week' },
  { key: 4, text: 'Last week', value: 'Last week' },
  { key: 5, text: 'This month', value: 'This month' },
  { key: 6, text: 'Last month', value: 'Last month' },
  { key: 7, text: 'Custom range', value: 'Select custom' }
]

const isAppsumo = localStorage.getItem('is_appsumo')

class Home extends Component {
  state = {
    subscriptionData: {
      tableContentData: [
        { ends: 0, max_calls: 0, calls_used: 0, max_sms: 0, sms_used: 0,status:'' }
      ]
    },
    verificationModel:false,
    leadParams: {
      // 'filter[type][!in]': 'TEXT_MESSAGE',
      // 'filter[direction][is]': 'Inbound',
      'filter[schedule_call_status][in]': 'scheduled,rescheduled'
},
    upComingData:[],
    showMore:false,
    number:'',
    code:'',
    askPhone:false,
    isTrial: false,
    trialDays: 0,
    isLoading: false,
    phoneNumbers: null,
    showSubscriptionModal: false,
    pleaseUpgrade: '',
    plan_name: null,
    subscriptionModalVisibility: false,
    titleLine: '',
    loadTitle: false,
    upgradeLine: '',
    chartData: {},
    personalLink: '',
    titleContent: {
      titleTwo: 'Welcome Back, ' + localStorage.getItem('first_name')
    },
    visitor: {
      title: 'calls',
      legend: false,
      stepSize: '5',
      yAxes: false,
      padding: {
        top: '20',
        right: '80',
        bottom: '20',
        left: '50'
      },
      gridLines: false,
      fontSize: 16,
      fontStyle: '400',
      labels: [
        '3rd April',
        '6th April',
        '7th April',
        '8th April',
        '9th April',
        '10th April',
        '11th April'
      ],
      datasets: [
        {
          label: 'All Calls',
          fill: false,
          backgroundColor: 'rgba(249,166,9,1)',
          borderColor: 'rgba(249,166,9,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(249,166,9,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(249,166,9,1)',
          pointHoverBorderColor: 'rgba(249,166,9,1)',
          pointHoverBorderWidth: 1,
          pointRadius: 0,
          pointHitRadius: 0,
          data: [3, 4, 5, 6, 7, 8, 15],
          options: {
            legend: {
              diplay: false
            },
            tooltips: {
              enabled: false
            }
          }
        }
      ]
    },

    data: {
      title: 'all activities',
      legend: true,
      stepSize: '10',
      yAxes: true,
      padding: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0'
      },
      fontSize: 14,
      fontStyle: '600',
      gridLines: true,
      labels: [
        '',
        '23/03/2019',
        '23/04/2019',
        '23/05/2019',
        '23/06/2019',
        '23/07/2019',
        '23/08/2019',
        '23/10/2019'
      ],
      datasets: [
        {
          label: 'All Calls',
          fill: false,
          backgroundColor: 'rgba(31,133,254,1)',
          borderColor: 'rgba(31,133,254,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(31,133,254,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(31,133,254,1)',
          pointHoverBorderColor: 'rgba(31,133,254,1)',
          pointHoverBorderWidth: 1,
          pointRadius: 0,
          pointHitRadius: 0,
          data: [0, 2, 4, 5, 2, 1],
          options: {
            tooltips: {
              mode: 'point'
            }
          }
        },
        {
          label: 'Successful calls',
          fill: false,
          backgroundColor: 'rgba(41,128,2,1)',
          borderColor: 'rgba(41,128,2,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(41,128,2,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(41,128,2,1)',
          pointHoverBorderColor: 'rgba(41,128,2,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 0,
          data: [0, 2, 5, 7, 5, 4, 8, 4]
        },
        {
          label: 'Unsuccessful call',
          fill: false,
          backgroundColor: 'rgba(102,102,102,1)',
          borderColor: 'rgba(102,102,102,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(102,102,102,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(102,102,102,1)',
          pointHoverBorderColor: 'rgba(102,102,102,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 0,
          data: [0, 4, 5, 6, 7, 8]
        },
        {
          label: 'Calls from website',
          fill: false,
          backgroundColor: 'rgba(59,89,153,1)',
          borderColor: 'rgba(59,89,153,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(59,89,153,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(59,89,153,1)',
          pointHoverBorderColor: 'rgba(59,89,153,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 0,
          data: [0, 1, 2, 3]
        },
        {
          label: 'Lorem Ipsum',
          fill: false,
          backgroundColor: 'rgba(249,166,9,1)',
          borderColor: 'rgba(249,166,9,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(249,166,9,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(249,166,9,1)',
          pointHoverBorderColor: 'rgba(249,166,9,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 0,
          data: [0, 10, 3, 9, 4, 5, 8, 2],
          options: {
            bezierCurve: true,
            title: {
              fontSize: 25
            },
            tooltips: {
              mode: 'point',
              enabled: true
            },
            layout: {
              padding: {
                left: 50,
                right: 0,
                top: 0,
                bottom: 0
              }
            }
          }
        }
      ]
    },
    toplead: {
      type: 'topLead',
      title: 'Top Lead Generating Pages'
    },
    cardboxData: [
      {
        type: 'flatRate',
        title: 'Avg. Lead Response Time',
        callLog: '00',
        callSup: 's'
      },
      {
        type: 'flatRate',
        title: 'AGENT ANSWERRATE',
        callLog: '00%'
      },
      {
        type: 'flatRate',
        title: 'CUSTOMER ANSWER RATE',
        callLog: '00%'
      },
      {
        type: 'flatRate',
        title: 'Avg Call Duration',
        callLog: '00',
        callSup: 's'
      }
    ],
    leadStatusData: [
      {
        type: 'flatRate',
        title: 'PENDING',
        callLog: '00',
        callSup: 's'
      },
      {
        type: 'flatRate',
        title: 'ASSIGNED',
        callLog: '00%'
      },
      {
        type: 'flatRate',
        title: 'QUALIFIED',
        callLog: '00%'
      },
      {
        type: 'flatRate',
        title: 'CLOSED',
        callLog: '00',
        callSup: 's'
      }
    ],
    usage: {
      type: 'usage',
      title: 'Usage',
      usageLog: '0/0'
    },
    currentCall: {
      type: 'currentCalls',
      title: 'Current Calls'
    },
    dataTable: {
      type: '3',
      header: [
        {
          headerTitle: 'ID'
        },
        {
          headerTitle: 'Customer'
        },
        {
          headerTitle: 'Scheduled time'
        }
      ],
      tableContentData: [
        {
          id: '#456765',
          name: 'Alpha Team',
          type: 'Primary'
        }
      ]
    },
    filterTable: {
      startDateFilter: moment(),
      endDateFilter: moment()
    },
    activityBoxData: null,
    selectedPickerOption: 'Today',
    role_name: 'Admin'
  }

  componentDidUpdate(prevProps, prevState) {
  
    if (this.props.todayData !== prevProps.todayData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.todayData.statData,
        chartData: this.props.todayData.dataChart
      })
    }

    if (this.props.personalLink !== prevProps.personalLink) {
      this.setState({
        personalLink: this.props.personalLink
      })
    }

    if (this.props.subscription !== prevProps.subscription) {
      this.GetSubscribeData(this.props.subscription)
    }

    if (this.props.yesterdayData !== prevProps.yesterdayData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.yesterdayData.statData,
        chartData: this.props.yesterdayData.dataChart
      })
    }

    if (this.props.thisWeekData !== prevProps.thisWeekData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.thisWeekData.statData,
        chartData: this.props.thisWeekData.dataChart
      })
    }

    if (this.props.lastWeekData !== prevProps.lastWeekData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.lastWeekData.statData,
        chartData: this.props.lastWeekData.dataChart
      })
    }

    if (this.props.thisMonthData !== prevProps.thisMonthData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.thisMonthData.statData,
        chartData: this.props.thisMonthData.dataChart
      })
    }

    if (this.props.lastMonthData !== prevProps.lastMonthData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.lastMonthData.statData,
        chartData: this.props.lastMonthData.dataChart
      })
    }
  }


  componentDidMount = async () => {
    // this.handleApply()
    const role_name = await localStorage.getItem('role_name')
    const adminStatus = await localStorage.getItem('admin_verification_status')
    this.fetchLeadData()
    if (role_name === 'Admin') {
      if (this.props.subscription) {
        this.GetSubscribeData(this.props.subscription)
      } else {
        this.props.getSubscription()
      }
    }
    if(!adminStatus){
      this.setState({verificationModel:true})
    }
    document.title = 'Home'
    /* const quick_setup = await localStorage.getItem('quick_setup') */

    /* const mobile_verification_status  = await localStorage.getItem('mobile_verification_status'); */
    // this.setState({
    //   isLoading: true
    // })
    let start_date = new Date(this.state.filterTable.startDateFilter)
    let end_date = new Date(this.state.filterTable.endDateFilter)
    start_date = moment(start_date)
    end_date = moment(end_date)
    start_date = start_date.format('DD/MM/YYYY')
    end_date = end_date.format('DD/MM/YYYY')

    start_date = this.formatData(start_date)
    end_date = this.formatData(end_date)

    if (this.props.todayData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.todayData.statData,
        chartData: this.props.todayData.dataChart
      })
    } else {
      this.props.getData(
        this.state.filterTable.startDateFilter,
        this.state.filterTable.endDateFilter,
        GET_DATA_TODAY
      )
    }

    if (this.props.personalLink) {
      this.setState({
        personalLink: this.props.personalLink
      })
    } else {
      this.props.getPersonalLink()
    }

    /*     if(role_name === "Admin" && parseInt(mobile_verification_status) !== 1)
    {
       if(parseInt(quick_setup) <=  4)
        {   
          
        }
    } */

    const n = await JSON.parse(localStorage.getItem('phone_numbers'))
    await this.setState({ phoneNumbers: n, role_name: role_name })

    if (!this.props.widget) {
      // this.props.getWidget()
    }
  }


  /*   fetchWidget =async () => {
    
    const apiToken = await localStorage.getItem('access_token')
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
    axios
      .get(url)
      .then(res => {
        this.setState({ isLoading: false })
        if (res.data.data[0]) {
          localStorage.setItem('widget_id', res.data.data[0].id)
          localStorage.setItem('preview_link', res.data.data[0].preview_link)
        }
      })
      .catch( error => {
        this.setState({ isLoading: false }) 
        if (error?.response?.status === 401) {
          logOut();
          return;
        }
        if(!error?.response){
         
        }     
      })
  } */

  handlePercentage = () => {
    this.setState({ percentage: this.state.percentage + 20 })
  }
   fetchLeadData = async () => {
    var url
    url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`
    axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: this.state.leadParams
      })
      .then(res => {



        this.setState((prev)=>({...prev,upComingData:res.data.data}))
      })
      .catch(err => {

      })
  }

  GetSubscribeData = async res => {
    var upgradeStatus = false
    var titleLine = '',
      freeplan = '',
      accountInfo = '',
      accountButtonText = ''
    var upgradeLine = ''
    const data = res.data
    if (data) {
      let trialEndDate = moment(data.trial_end)
      let planEndDate = moment(
        data.current_term_end ? data.current_term_end : null
      )
      let today = moment(new Date())
      var trialDays = trialEndDate.diff(today, 'days')
      var plan_expired = planEndDate.diff(today, 'days')

      var exp = data.current_term_end
      let trial = trialDays ? trialDays : 0
      let plan = plan_expired ? plan_expired : 0
      var max_calls = data.max_calls
      var used_calls = data.calls_used
      var pleaseUpgrade = ''

      // eslint-disable-next-line no-unused-expressions
      localStorage?.setItem('account_status',data?.status)

      var customPlan =
        data.plan_name.charAt(0).toUpperCase() +
        data.plan_name?.slice(1).toLowerCase()
      const tableData = {
        tableContentData: [
          {
            plan_name: data.plan_name,
            ends: 0,
            max_calls: data.max_calls,
            calls_used: data.calls_used,
            max_sms: data.max_sms,
            sms_used: data.sms_used,
            status:data?.status
          }
        ]
      }

      //------------------- Trial Plan -------------------//
      const account = localStorage?.getItem('account')


      if (+isAppsumo) {
        titleLine = ' '
      } else {
        if (data.status === 'in_trial') {
          upgradeStatus = true
          if (trialDays > 0) {
            accountInfo = `You are currently on a ${
              data?.plan_name
            } trial plan which will expire on ${moment(data?.trial_end).format(
              'LL'
            )}`
            accountButtonText = 'Upgrade now'
            pleaseUpgrade = 'Upgrade now'
            titleLine =
              'See what your trial is all about. Your trial plan will expire in ' +
              trialDays +
              ' days .'
            upgradeLine = '  for a seamless service.'
          } else {
            analytics.track('trial expired');
            accountInfo = `Your trial has expired`
            accountButtonText = 'Pick a plan'
            pleaseUpgrade = 'Please Upgrade '
            titleLine =
              'Your trial plan has expired on ' +
              moment(data.trial_end).format('LL') +
              '.'
            upgradeLine = ' .'
          }
        }  else if (data.status === 'Active') {
          if (plan_expired > 0 && max_calls > used_calls) {
            upgradeStatus = false
            titleLine = ''
          } else if (plan_expired > 0 && max_calls <= used_calls) {
            upgradeStatus = false
            titleLine = ''
          } else if (plan_expired <= 0) {
            upgradeStatus = true
            pleaseUpgrade = 'Please Upgrade '
            titleLine =
              'Your current plan ' +
              data.plan_name +
              ' has expired on ' +
              moment(data.current_term_end).format('LL') +
              '.'
            upgradeLine = ' .'
          } else {
            upgradeStatus = false
            titleLine = ''
          }
        }  
        else if (data?.status === 'trial_expired' || parseInt(account) === 3) {
               analytics.track('trial expired');
          accountInfo = `Your trial has expired`
          accountButtonText = 'Pick a plan'
          this.setState({
            showSubscriptionModal: true
          })
        } else if (data?.status?.toLowerCase() === 'cancelled') {
          accountInfo = `You currently have no active subscription`
          accountButtonText = 'Please pick a plan'
          upgradeStatus = false
          this.setState({
            showSubscriptionModal: true
          })
        } else {
          upgradeStatus = false
          titleLine = ''
          this.setState({
            showSubscriptionModal: true
          })
        }
      }
      let upgradeButton = '0'

      let adminVerify = await localStorage.getItem('admin_verification_status')

      //adminVerify = '0'
      if (adminVerify == '0') {
        this.setState({ verificationModel: true })
        upgradeStatus = true
        upgradeButton = '0'
        titleLine = 'Your account is currently pending verification'
      }

      if (data?.plan_name === 'Free') {
        upgradeStatus = false
        freeplan = 'Your account is currently on Free plan'
        accountInfo = `Your account is currently on Free plan`
        accountButtonText = 'Upgrade'
      }

      await localStorage.setItem('plan_name', res.data.plan_name)
      await localStorage.setItem('max_users', res.data.max_users)
      await localStorage.setItem('upgrade_status', upgradeStatus ? '1' : '0')
      await localStorage.setItem('upgrade_title', titleLine)
      await localStorage.setItem('upgrade_button', upgradeButton)
      await localStorage.setItem('freeplan', freeplan)

      this.setState(
        {
          plan_name:
            data.status === 'Cancelled' || data.status === 'in_trial'
              ? ''
              : res.data.plan_name,
          pleaseUpgrade: pleaseUpgrade,
          isTrial: upgradeStatus,
          trialDays: trialDays,
          titleLine: titleLine,
          subscriptionData: tableData,
          upgradeLine: upgradeLine,
          accountInfo: accountInfo,
          accountButtonText: accountButtonText
        },
        () => this.setState({ loadTitle: true })
      )
    }
  }

  handleStartDate = date => {
    const { filterTable } = this.state
    this.setState(
      {
        filterTable: {
          startDateFilter: date,
          endDateFilter: filterTable.endDateFilter
        }
      },
      () => {}
    )
  }

  handleEndDate = date => {
    const { filterTable } = this.state

    this.setState(
      {
        filterTable: {
          startDateFilter: filterTable.startDateFilter,
          endDateFilter: date
        }
      },
      () => {}
    )
  }

  changeLoadingState = state => {
    this.setState({ isLoading: false })
  }
  formatData = date => {
    var arr = date.split('/')
    return arr[2] + '-' + arr[1] + '-' + arr[0]
  }

  /*   fetchData = async() => {
    const apiToken = await localStorage.getItem('access_token')
    let start_date = new Date(this.state.filterTable.startDateFilter)
    let end_date = new Date(this.state.filterTable.endDateFilter)
    start_date = moment(start_date)
    end_date = moment(end_date)
    start_date = start_date.format('DD/MM/YYYY')
    end_date = end_date.format('DD/MM/YYYY')

    start_date = this.formatData(start_date)
    end_date = this.formatData(end_date)

    this.setState({
      isLoading: true
    })

    
    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/get-leads-in-custom-period/${start_date}/${end_date}`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          this.setState({
            isLoading: false,
            activityBoxData: res.data.data
          })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false }) 
        if (error?.response?.status === 401) {
          logOut();
          return;
        }
        if(!error?.response){
          
        }
      })
  } */

  /*   fetchChartData = async () => {
    const apiToken = await localStorage.getItem('access_token')
    let start_date = new Date(this.state.filterTable.startDateFilter)
    let end_date = new Date(this.state.filterTable.endDateFilter)
    var start_datee = moment(start_date)
    var end_datee = moment(end_date)
    start_date = start_datee.format('DD/MM/YYYY')
    end_date = end_datee.format('DD/MM/YYYY')

    var days_difference = end_datee.diff(start_datee, 'days')

    var apiEndPoint = days_difference > 30 ? 'month' : 'day'

    start_date = this.formatData(start_date)
    end_date = this.formatData(end_date)

    this.setState({
      isLoading: true
    })

    
    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/chart/data/${start_date}/${end_date}/${apiEndPoint}`
    

    axios
      .get(url)
      .then(async res => {
        if (res.data.data) {
          const d = res.data.data
          const month = [
            'null',
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'Decemer'
          ]
          const labelArray = ['']
          const allEvents = [0]
          const allCalls = [0]
          const allMessage = [0]
          const ScheduleCall = [0]
          const successfullCalls = [0]
          const failedCalls = [0]

          if (d?.length !== 0) {
            await d.map(function(item, index) {
              apiEndPoint === 'month'
                ? labelArray.push(month[item.label])
                : labelArray.push(moment(item.label).format('DD MMM'))
              allEvents.push(item.all_leads)
              allCalls.push(
                item.live_call + item.schedule_call + item.digital_calls
              )
              allMessage.push(item.leave_message)
              ScheduleCall.push(item.schedule_call)
              successfullCalls.push(item.success_call)
              failedCalls.push(item.failed_call)
            })
          }
          const data = {
            labelArray: labelArray,
            allEvents: allEvents,
            allCalls: allCalls,
            allMessage: allMessage,
            ScheduleCall: ScheduleCall,
            successfullCalls: successfullCalls,
            failedCalls: failedCalls
          }
          await this.setState({
            isLoading: false,
            chartData: data
            
          })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false }) 
        if (error?.response?.status === 401) {
          logOut();
          return;
        }
        if(!error?.response){
          
        }        
      })
  } */

  fetchChartDataCutom = async (start_date, end_date) => {
    const apiToken = await localStorage.getItem('access_token')

    var s_date = moment(start_date)
    var e_date = moment(end_date)

    var days_difference = e_date.diff(s_date, 'days')
    var apiEndPoint = days_difference > 30 ? 'month' : 'day'

    this.setState({
      isLoading: true
    })

    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/chart/data/${start_date}/${end_date}/${apiEndPoint}`

    axios
      .get(url)
      .then(async res => {
        if (res.data.data) {
          const d = res.data.data
          const month = [
            'null',
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'Decemer'
          ]
          const labelArray = ['']
          const allEvents = [0]
          const allCalls = [0]
          const allMessage = [0]
          const ScheduleCall = [0]
          const successfullCalls = [0]
          const failedCalls = [0]

          if (d?.length !== 0) {
            await d.map(function(item, index) {
              apiEndPoint === 'month'
                ? labelArray.push(month[item.label])
                : labelArray.push(item.label)
              allEvents.push(item.all_leads)
              allCalls.push(
                item.live_call + item.schedule_call + item.digital_calls
              )
              allMessage.push(item.leave_message)
              ScheduleCall.push(item.schedule_call)
              successfullCalls.push(item.success_call)
              failedCalls.push(item.failed_call)
            })
          }
          const data = {
            labelArray: labelArray,
            allEvents: allEvents,
            allCalls: allCalls,
            allMessage: allMessage,
            ScheduleCall: ScheduleCall,
            successfullCalls: successfullCalls,
            failedCalls: failedCalls
          }
          await this.setState({
            isLoading: false,
            chartData: data
          })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        if (error?.response?.status === 401) {
          logOut()
          return
        }
        if (!error?.response) {
        }
      })
  }

  fetchDataCustom = async (customStartDate, customEndDate) => {
    const apiToken = await localStorage.getItem('access_token')
    let start_date = customStartDate
    let end_date = customEndDate

    start_date = this.formatData(start_date)
    end_date = this.formatData(end_date)

    this.setState({
      isLoading: true
    })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/get-leads-in-custom-period/${start_date}/${end_date}`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          this.setState({
            isLoading: false,
            activityBoxData: res.data.data
          })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        if (error?.response?.status === 401) {
          logOut()
          return
        }
        if (!error?.response) {
        }
      })
  }

  handleApply = (
    event,
    picker = {
      startDate: new Date(),
      endDate: new Date()
    }
  ) => {
    this.fetchDataCustom(
      moment(picker.startDate).format('DD/MM/YYYY'),
      moment(picker.endDate).format('DD/MM/YYYY')
    )
    this.setState(
      {
        filterTable: {
          startDateFilter: moment(picker.startDate).format('DD/MM/YYYY'),
          endDateFilter: moment(picker.endDate).format('DD/MM/YYYY')
        }
      },
      () => {
        this.fetchChartDataCutom(
          moment(picker.startDate).format('YYYY-MM-DD'),
          moment(picker.endDate).format('YYYY-MM-DD')
        )
      }
    )
  }

  today = () => {
    this.setState({
      filterTable: {
        startDateFilter: moment().format('DD/MM/YYYY'),
        endDateFilter: moment().format('DD/MM/YYYY')
      },
      selectedPickerOption: 'Today'
    })

    if (this.props.todayData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.todayData.statData,
        chartData: this.props.todayData.dataChart
      })
      this.handleApply()
    } else {
      this.setState({
        isLoading: true
      })
      this.props.getData(
        this.state.filterTable.startDateFilter,
        this.state.filterTable.endDateFilter,
        GET_DATA_TODAY
      )
    }
  }

  yesterday = async () => {
    this.setState(
      {
        filterTable: {
          startDateFilter: moment()
            .subtract(1, 'days')
            .format(),
          endDateFilter: moment()
            .subtract(1, 'days')
            .format()
        }
      },
      () => {
        if (this.props.yesterdayData) {
          this.setState({
            isLoading: false,
            activityBoxData: this.props.yesterdayData.statData,
            chartData: this.props.yesterdayData.dataChart
          })
        } else {
          this.setState({
            isLoading: true
          })
          this.props.getData(
            this.state.filterTable.startDateFilter,
            this.state.filterTable.endDateFilter,
            GET_DATA_YESTERDAY
          )
        }
      }
    )
  }

  lastWeek = () => {
    this.setState(
      {
        filterTable: {
          startDateFilter: moment()
            .subtract(1, 'weeks')
            .startOf('isoWeek'),
          endDateFilter: moment()
            .subtract(1, 'weeks')
            .endOf('isoWeek')
        }
      },
      () => {
        if (this.props.lastWeekData) {
          this.setState({
            isLoading: false,
            activityBoxData: this.props.lastWeekData.statData,
            chartData: this.props.lastWeekData.dataChart
          })
        } else {
          this.setState({
            isLoading: true
          })
          this.props.getData(
            this.state.filterTable.startDateFilter,
            this.state.filterTable.endDateFilter,
            GET_DATA_LAST_WEEK
          )
        }
      }
    )
  }

  thisWeek = () => {
    const startOfWeek = moment().startOf('isoWeek')
    const endOfWeek = moment().endOf('isoWeek')
    this.setState(
      {
        filterTable: {
          startDateFilter: startOfWeek,
          endDateFilter: endOfWeek
        }
      },
      () => {
        if (this.props.thisWeekData) {
          this.setState({
            isLoading: false,
            activityBoxData: this.props.thisWeekData.statData,
            chartData: this.props.thisWeekData.dataChart
          })
        } else {
          this.setState({
            isLoading: true
          })
          this.props.getData(
            this.state.filterTable.startDateFilter,
            this.state.filterTable.endDateFilter,
            GET_DATA_THIS_WEEK
          )
        }
      }
    )
  }

  thisMonth = () => {
    const startOfMonth = moment()
      .clone()
      .startOf('month')
    const endOfMonth = moment()
      .clone()
      .endOf('month')
    this.setState(
      {
        filterTable: {
          startDateFilter: startOfMonth,
          endDateFilter: endOfMonth
        }
      },
      () => {
        if (this.props.thisMonthData) {
          this.setState({
            isLoading: false,
            activityBoxData: this.props.thisMonthData.statData,
            chartData: this.props.thisMonthData.dataChart
          })
        } else {
          this.setState({
            isLoading: true
          })
          this.props.getData(
            this.state.filterTable.startDateFilter,
            this.state.filterTable.endDateFilter,
            GET_DATA_THIS_MONTH
          )
        }
      }
    )
  }

  lastMonth = () => {
    const prevMonthFirstDay = moment()
      .subtract(1, 'months')
      .startOf('month')
    const prevMonthLastDay = moment()
      .subtract(1, 'months')
      .endOf('month')
      .clone()
      .endOf('month')
    this.setState(
      {
        filterTable: {
          startDateFilter: prevMonthFirstDay,
          endDateFilter: prevMonthLastDay
        }
      },
      () => {
        if (this.props.lastMonthData) {
          this.setState({
            isLoading: false,
            activityBoxData: this.props.lastMonthData.statData,
            chartData: this.props.lastMonthData.dataChart
          })
        } else {
          this.setState({
            isLoading: true
          })
          this.props.getData(
            this.state.filterTable.startDateFilter,
            this.state.filterTable.endDateFilter,
            GET_DATA_LAST_MONTH
          )
        }
      }
    )
  }

  handleChange = (e, { value }) => {
    if (value === 'Select custom') {
      this.setState({
        selectedPickerOption: value,
        filterTable: {
          startDateFilter: moment(),
          endDateFilter: moment()
        }
      })
      return
    }
    this.setState({ selectedPickerOption: value })
    switch (value) {
      case 'Today':
        this.today()
        break
      case 'Yesterday':
        this.yesterday()
        break
      case 'This week':
        this.thisWeek()
        break
      case 'Last week':
        this.lastWeek()
        break
      case 'This month':
        this.thisMonth()
        break
      case 'Last month':
        this.lastMonth()
        break
      default:
        this.today()
    }
  }

  handleCancel = () => {
    this.today()
  }

  closeSubscriptionModal = async () => {
    await this.setState({ showSubscriptionModal: false })
  }

  openSubscriptionModal = async () => {
    await this.setState({ showSubscriptionModal: true })
  }

  render() {
   const account=localStorage?.getItem('account')
    return (
      <>
        {/* <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer> */}
        <Grid container>
          <Grid item xs={12}>
            <div className="fullwidth-container home_page w-auto" style={{marginLeft:"187px"}}>
              {isAdmin() && <Checklist />}
              {/* <div className='right-sider'>
                <div>
                  
             
                </div>
           </div> */}
              {this.state.loadTitle && (
                <Title
                  upgradePlanData={!this.state.isTrial}
                  openSubscriptionModal={this.openSubscriptionModal}
                  closeSubscriptionModal={this.closeSubscriptionModal}
                  showSubscriptionModal={this.state.showSubscriptionModal}
                  plan_name={this.state.plan_name}
                  subscriptionData={this.state.subscriptionData}
                  data={this.state.titleContent}
                  openModal={() =>
                    this.setState({
                      showSubscriptionModal: true
                    })
                  }
                />
              )}
              {/* {( this.state.verificationModel)  && */}

              {/* <AdminVerification/> */}



              <AdminVerification onClose={() => this.setState({ verificationModel: false })} open={this.state.verificationModel} suspend={account === 0} closed={account === 5} />



              <div className="homecontent-wrapper">
                {
                  !localStorage?.getItem('admin_verification_status') && (
                    <div className="number-title massage_box">
                      Your account is currently being verified !
                    </div>
                  )}

                <HomeTrial
                  pleaseUpgrade={this.state.pleaseUpgrade}
                  upgradeLine={this.state.upgradeLine}
                  subscriptionData={this.state.subscriptionData}
                  titleLine={this.state.titleLine}
                  phoneNumbers={this.state.phoneNumbers}
                  isTrial={this.state.isTrial}
                  trialDays={this.state.trialDays}
                  personalLink={this.state.personalLink}
                />

                <PlanInfo
                  openSubscriptionModal={this.openSubscriptionModal}
                  accountInfo={this.state?.accountInfo}
                  accountButtonText={this.state?.accountButtonText}
                />
                {/* <Graph /> */}
                {/* <LeadOverView date={this.state.filterTable} /> */}

              </div>
              {/* <div>
            <p className="default-text help-title help-subtitle-margin ">
              {' '}
              Leads report{' '}
            </p>
            <div className="chart-wrapper all_activites">
              <ActivityChart
                loading={this.changeLoadingState}
                apiData={this.state.chartData}
                date={this.state.filterTable}
              />
            </div>
          </div> */}
              <div>
                {/* <p
              className="default-text help-title help-subtitle-margin"
              style={{ margin: '10px 0 -20px' }}
            >
              Performance Report
            </p> */}
                <div className="homecontent-wrapper our_services">
                  {/* <ActivityBox
                loading={this.changeLoadingState}
                date={this.state.activityBoxData}
                type="Performance"
              /> */}

                  {/* <HomeBottomSupport></HomeBottomSupport> */}
                  {account === 3 || account === '3' ?
                    <CommonSubscriptionModal
                      open={this.state.showSubscriptionModal}
                      onClose={this.closeSubscriptionModal}
                      currentPlan={this.state.plan_name}
                      fixed={true}
                      title="Your existing plan expired, select new plan"
                      dataTable={this.state.subscriptionData}
                    />

                    :
                    isAppsumo === 1 || isAppsumo === '1' ? (
                      <AppsumoSubscriptionModal
                        open={this.state.showSubscriptionModal}
                        onClose={this.closeSubscriptionModal}
                        currentPlan={this.state.plan_name}
                        title="Choose Your Plan"
                        dataTable={this.state.subscriptionData}
                      />
                    ) : (
                      <CommonSubscriptionModal
                        open={this.state.showSubscriptionModal}
                        onClose={this.closeSubscriptionModal}
                        currentPlan={this.state.plan_name}
                        title="Choose Your Plan"
                        dataTable={this.state.subscriptionData}
                      />
                    )}
                </div>
              </div>
              {/* <div>
            <p
              className="default-text help-title help-subtitle-margin"
              style={{ margin: '10px 0 -20px' }}
            >
              Lead Status
            </p>
            <div className="homecontent-wrapper our_services">
              <ActivityBox
                loading={this.changeLoadingState}
                date={this.state.activityBoxData}
                type="leadStatus"
                filterDate={this.state?.filterTable}
              />
      
            </div>
          </div> */}
            </div>
          </Grid>
          {/* <Grid item xs={2} sx={{marginTop: "87px"}}>
            <Box class="time-box">
              <h2 style={{fontSize:'20px',fontWeight: 500,}}>Meetings </h2>
            </Box>
         {this.state.upComingData?.leads?.data?.map((obj,index)=>
            index <4 ? 
            <Box onClick={()=> window?.location.replace( `/leads/${obj?.id}`)}  className='schedule-call-inbox' p={2} borderRadius={1.2} sx={{backgroundColor:"#fff", border:'1px solid #f2f2f2', borderRadius:'15px', margin:'5px auto'}}>
              <Stack sty>
                <div>
                 <p>{obj.request_call_time
                   ? moment.utc(obj.request_call_time)
                     // .utc(data.request_call_time)
                     .format('dddd, MMMM, DD, YYYY')
                   : ''}</p>
                 <h6> {obj.request_call_time
                 ? moment.utc(obj.request_call_time)
                   .format('HH:hh A') + " "
                 + moment.tz.guess()  
                 : ''}</h6>
                 <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
                  <p  style={{ontWeight: 500}}>{obj?.agent}</p>
                  <p  style={{ontWeight: 500}}>{obj?.meeting_location}</p>
                  </div>
                </div>
              </Stack>
             </Box> : ''
            )}
            {this.state.upComingData?.leads?.data?.length > 3 ?  <p style={{ marginTop:'10px', color: 'blue', cursor: 'pointer' }} onClick={()=>window.location.replace('/meetings')}>View more</p>: ''}
                
            <CommonButtons style={{margin:'10px auto',display:'flex'}} background="blue" onClick={()=>this.setState((prev)=>({...this.state,openCallback:!this.state.openCallback}))}>Schedule a callback</CommonButtons>
          </Grid> */}
        </Grid>
        {this.state.openCallback && (
          <CallbackModal
            isModalClose={() => {
              setTimeout(() => {
                this.setState((prev)=>({...this.state,openCallback:!this.state.openCallback}))
              }, 300)
            }}
            isModalOpen={() => console.log(`open`)}
            open={this.state.openCallback}
            selectDefault={'Call Later'}
          // dropDownData={dropDownData || []}
          // leadData={leadAbout}
          // phone_numbber={leadAbout?.phone_number}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  widget: state.home.widget,
  subscription: state.home.subscription,
  personalLink: state.home.personalLink,
  todayData: state.home.todayData,
  yesterdayData: state.home.yesterdayData,
  thisWeekData: state.home.thisWeekData,
  lastWeekData: state.home.lastWeekData,
  thisMonthData: state.home.thisMonthData,
  lastMonthData: state.home.lastMonthData
})

export default connect(mapStateToProps, {
  getWidget,
  getData,
  getSubscription,
  getPersonalLink
})(Home)

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import square from '../../assets/images/Icons-nav-widgets.svg'
import widgetNew from '../../assets/images/Widget.svg'
import helpNew from '../../assets/images/help.svg'
import profileNew from '../../assets/images/Account.svg'
import squareActive from '../../assets/images/Icons-nav-widgets.svg'
import AvailableIcon from '../../assets/images/Available-icon.png'

import { menus, Operatormenus } from './Menus'
import HoursModal from '../../common/HoursModal'
import ProfileModal from '../../common/ProfileModal'
import ReferModal from '../../common/ReferModal'
import { Button, Icon, Progress } from 'semantic-ui-react'
import NotificationModal from '../../common/NotificationModal'
import settings from '../../assets/images/Icons-nav-settings.svg'
import plusIcon from '../../assets/images/plus-icon-white.svg'
import { connect } from 'react-redux'
import { getDialer } from '../../actions/dialer'
import { truncate } from 'lodash'
import Axios from 'axios'
import { Slider, Tooltip } from '@mui/material'
import Clarity from '../../containers/Clarity'
import CommonSubscriptionModal from '../../common/CommonSubscriptionModal'

const activeList = {
  activeNumbers: false,
  activeForms:false,
  activeSettings: false,
  activeCirle: false,
  activePeople: false,
  activeSquare: false,
  activeContact: false,
  activePhone: false,
  activeMyPhone: false,
  activeHome: false,
  activePhoneCall: false,
  activeIntegration: false,
  activeAnalytics: false,
  activeCampaign: false,
  activeConversation: false,
  activeTasks: false,
  activeMeets: false,
  activeBilling: false,
  open: false,
  activeMeeting: false,
  activeForms:false,
  activeStart:false,
  activeAvailable:false,
  activeCallTracking:false

}

class Navbar extends Component {
  state = { ...activeList, usageData:{} ,activeComponent: 'MySettings', activeTab: 'account',leadCount:localStorage.getItem('pendingLeads'), showSubscriptionModal: false }
  
  
  
  componentDidMount() {

    let urlLink = window?.location?.pathname

    // urlLink = urlLink.split('/')
    // urlLink = '/' + urlLink[1]

    console.info({ urlLink })
    this.saveLeadDetail()
    switch (urlLink) {
      case '/':
        this.setState({ ...activeList, activeHome: true })
      case '/conversation':
        this.setState({ ...activeList, activeConversation: true })
        break
      case '/callLog':
        this.setState({ ...activeList, activePhoneCall: true })
        break
      case '/home':
        this.setState({ ...activeList, activeHome: true })
        break
      case '/leads':
        this.setState({ ...activeList, activePhone: true })
        break
      case '/my-leads':
        this.setState({ ...activeList, activeMyPhone: true })
        break
      case '/contacts':
        this.setState({ ...activeList, activeContact: true })
        break
      case '/meetings':
        this.setState({ ...activeList, activeMeeting: true })
        break
      case '/meets':
        this.setState({ ...activeList, activeMeets: true })
        break
      case '/billing':
        this.setState({ ...activeList, activeBilling: true })
        break
      case '/widgets':
        this.setState({ ...activeList, activeSquare: true })
        break
      case '/integration':
        this.setState({ ...activeList, activeIntegration: true })
        break
      case '/analytics':
        this.setState({ ...activeList, activeAnalytics: true })
        break

      case '/campaign':
        this.setState({ ...activeList, activeCampaign: true })
        break
      case '/call-tracking':
        this.setState({ ...activeList, activeCallTracking: true })
        break

      case '/settings':
        this.setState({ ...activeList, activeSettings: true })
        break
      case '/numbers':
        this.setState({ ...activeList, activeNumbers: true })
        break
      case '/forms':
        this.setState({ ...activeList, activeForms: true })
        break
      case '/conversation':
        this.setState({ ...activeList, activeConversation: true })
        break
      case '/tasks':
        this.setState({ ...activeList, activeTasks: true })
        break

      case '/settings/personal/working_hours':
        this.setState({ ...activeList, activeAvailable: true })
        break
      default:
        this.setState({ ...activeList })
        break
    }
  }
  saveLeadDetail = () => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/usage-stats`

    Axios
      .get(URL,
      )
      .then(res => {
        this.setState((state)=>({ ...state,usageData:res.data.data}))


      })


  }
  changeActiveMenu(key) {
    const activeListCopy = { ...activeList }
    if (key !== 'activeSettings') {
      activeListCopy.activeSettings = false
      activeListCopy[key] = true
    } else {
      activeListCopy.activeSettings = true
      activeListCopy[key] = true
    }
    if (key === 'activePeople') {
      this.setState(
        {
          ...activeListCopy,
          activeTab: 'Team',
          activeComponent: 'Users & Team'
        },
        // () => {
        //   return this.props.handleActive && this.props.handleActive(
        //     this.state.activeTab,
        //     this.state.activeComponent
        //   )
        // }
      )
    } else {
      this.setState(
        {
          ...activeListCopy,
          activeTab: 'Account',
          activeComponent: 'MySettings'
        },
        // () => {
        //   return this.props.handleActive && this.props.handleActive(
        //     this.state.activeTab,
        //     this.state.activeComponent
        //   )
        // }
      )
    }
  }
  openModal = () => {
    this.setState({ open: !this.state.open })
  }

  closeModal = () => {
    this.setState({ open: !this.state.open })
  }

  closeModal = () => {
    this.setState({ open: !this.state.open })
  }
  onCloseModal = () => {
    if (document.querySelector('.dimmer')) {
      document.querySelector('.dimmer').click()
    }
  }

  onRouteChanged() {
    console.info('ROUTE CHANGED')
  }

  activeHandle = component => {
    if (component === 'menuSettings') {
      this.changeActiveMenu('activeSettings')
    } else if (component === 'menuPeople') {
      this.changeActiveMenu('activePeople')
    } else if (component === 'menuAnalytics') {
      this.changeActiveMenu('activeAnalytics')
    } else if (component === 'menuCampaign') {
      this.changeActiveMenu('activeCampaign')
    } else if (component === 'menuCallTracking') {
      this.changeActiveMenu('activeCallTracking')
    } else if (component === 'menuNumbers') {
      this.changeActiveMenu('activeNumbers')
    } else if (component === 'menuForms') {
      this.changeActiveMenu('activeForms')
    } else if (component === 'menuSquare') {
      this.changeActiveMenu('activeSquare')
    } else if (component === 'menuAvailable') {
      this.changeActiveMenu('activeAvailable')
    } else if (component === 'menuContact') {
      this.changeActiveMenu('activeContact')
    } else if (component === 'menuPhone') {
      this.changeActiveMenu('activePhone')
    } else if (component === 'menuMyPhone') {
      this.changeActiveMenu('activeMyPhone')
    } else if (component === 'menuHome') {
      this.changeActiveMenu('activeHome')
    } else if (component === 'menuPhoneCall') {
      this.changeActiveMenu('activePhoneCall')
    } else if (component === 'menuConversation') {
      this.changeActiveMenu('activeConversation')
    } else if (component === 'menuTasks') {
      this.changeActiveMenu('activeTasks')
    } else if (component === 'menuMeets') {
      this.changeActiveMenu('activeMeets')
    } else if (component === 'menuContact') {
      this.changeActiveMenu('activeContact')
    } else if (component === 'menuMeeting') {
      this.changeActiveMenu('activeMeeting')
    } else if (component === 'menuBilling') {
      this.changeActiveMenu('activeBilling')
    } else if (component === 'menuPipeline') {
      this.changeActiveMenu('activePipeline')
    } else if (component === 'menuStart') {
      this.changeActiveMenu('activeStart')
    } else {
      this.changeActiveMenu('activeCirle')
    }
  }

  goToTeam = () => {
    window.location.href = '/settings/users_&_teams/users'
  }

  openDialer = () => {
    this.props.getDialer(!this.props.dialerStatus)
    
  }

  componentDidUpdate(prevState,prevProps){



  }

  render() {
    const role_name = localStorage.getItem('role_name')
    const unreadMessage = localStorage.getItem('unread_message')
    const accountStatus = localStorage?.getItem('account_status')
    const planName = localStorage?.getItem('plan_name')
    return (
      <div className="nav-container custome_nav_bar billing">
        <div className="top-nav">
          {role_name === 'Admin' ||
          role_name === 'Operation Manager' ||
          role_name === 'Widget Manager' ||
          role_name === 'Manager'
            ? menus.map((menu_item, index) => (
                <Link
                  key={index}
                  className={classNames('menu-link', {
                    'nav-active': this.state[menu_item.stateKey]
                  })}
                  onClick={() => {
                    this.activeHandle(menu_item.handle)
                  }}
                  to={menu_item.url}
                >
                  <div className="icon-wrapper">
                    {menu_item?.showCount && (menu_item?.hoverName==='Leads' ? this.props.pendingLeads >0 || localStorage?.getItem('pendingLeads') >0  :menu_item?.count>0) && <span className='count'>{menu_item?.hoverName==='Leads' ? this.props.pendingLeads ||  localStorage?.getItem('pendingLeads') :menu_item?.count}</span>}
                    <img style={{color:'#fff'}} src={menu_item.inactiveIcon} alt="icon" />
                  <span>{menu_item.hoverName}</span>
                  </div>
                  {/* <div className="hover-name-wrapper">
                    <i className="fas fa-caret-left"></i>
                    <p>{menu_item.hoverName}</p>
                  </div> */}
                </Link>
              ))
            : Operatormenus.map((menu_item, index) => (
              <Link
                key={index}
                className={classNames('menu-link', {
                  'nav-active': this.state[menu_item.stateKey]
                })}
                onClick={() => {
                  this.activeHandle(menu_item.handle)
                }}
                to={menu_item.url}
              >
                <div className="icon-wrapper">
                  {menu_item?.showCount && (menu_item?.hoverName === 'Leads' ? this.props.pendingLeads > 0 || localStorage?.getItem('pendingLeads') > 0 : menu_item?.count > 0) && <span className='count'>{menu_item?.hoverName === 'Leads' ? this.props.pendingLeads || localStorage?.getItem('pendingLeads') : menu_item?.count}</span>}
                  <img style={{ color: '#fff' }} src={menu_item.inactiveIcon} alt="icon" />
                  <span>{menu_item.hoverName}</span>
                </div>
                {/* <div className="hover-name-wrapper">
                    <i className="fas fa-caret-left"></i>
                    <p>{menu_item.hoverName}</p>
                  </div> */}
              </Link>
              ))}
        </div>

        <div className="bottom-nav">
          {/* <div className="menu-link">
            <div className="icon-wrapper" onClick={this.openDialer}>
              <Icon
                name="fas fa-tty"
                color="white"
                style={{ color: 'white' }}
              ></Icon>
            </div>
            <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Dialer</p>
            </div>
          </div> */}
          <Clarity  />

<div
            className={classNames('menu-link', {
              'nav-active': this.state['activeSquare']
            })}
            onClick={() => (window.location.href = '/widgets')}
          >
            <div className="icon-wrapper">
              <img
                src={this.state.activeSquare ? squareActive : square}
                alt="icon"
              />
               <span>Widget</span>
            </div>
            {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Widget</p>
            </div> */}
          </div>
          
<div
            className={classNames('menu-link', {
              'nav-active': this.state['activeAvailable']
            })}
            onClick={() => (window.location.href = '/settings/personal/working_hours')}
          >
            <div className="icon-wrapper">
              <img
                src={this.state.activeAvailable ? AvailableIcon : AvailableIcon}
                alt="icon"
                style={{height:'20px'}}
              />
              <span>Availability</span>
            </div>
            {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Widget</p>
            </div> */}
          </div>
<div
            className={classNames('menu-link')}
            onClick={() => (window.location.href = '/settings/teams')}
          >
            <div className="icon-wrapper">
              <img
                src={plusIcon}
                alt="icon"
              />
               <span>Invite team</span>
            </div>
            {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Widget</p>
            </div> */}
          </div>

        
              <HoursModal />
              
          <Tooltip placement="right" title={accountStatus === 'in_trial' ? '3 days trial left' : accountStatus === 'trial_expired' ? 'pick a plan' :'Lead usage count'}>
            <div className="main-box" style={{marginTop:10}}>
              <div className="profile-content" style={{textAlign:'center'}}>
                <p>{planName} Plan</p>
              </div>
              <div className="count-list-main">
                <div className="count-list">
                  {(this.state.usageData?.max_callbacks || this.state.usageData?.max_callbacks === 0) && (
                    <p style={{color: this.state.usageData?.used_callbacks === this.state.usageData?.max_callbacks ? "red" : undefined}}>
                      <b>{this.state.usageData?.used_callbacks}</b> of {this.state.usageData?.max_callbacks} callbacks
                    </p>
                  )}
                </div>
                <div className="count-list" style={{marginTop: 4}}>
                  {(this.state.usageData?.max_leads || this.state.usageData?.max_leads === 0) && (
                    <p style={{color: this.state.usageData?.used_leads === this.state.usageData?.max_leads ? "red" : undefined}}>
                      <b>{this.state.usageData?.used_leads}</b> of {this.state.usageData?.max_leads} Leads
                    </p>
                  )}
                </div>
              </div>
              <Progress value={this.state.usageData?.used_leads} error={this.state.usageData?.used_leads >= this.state.usageData?.max_leads} total={this.state.usageData?.max_leads} className='sidebar-progress' />
              <Button content="upgrade" className='sidebar-btn'  onClick={() => this.setState({showSubscriptionModal: true})}/>
            </div>
          </Tooltip>
          <div
            className={classNames('menu-link', {
              'nav-active': this.state['activeSquare']
            })}
          
          >
         
      
            {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Widget</p>
            </div> */}
          </div>
          
            {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>How can we help?</p>
            </div> */}

          {/* <Link

                  className={classNames('menu-link', {
                    'nav-active': this.state['activeSettings']
                  })}
                  onClick={() => {
                    this.activeHandle('menuSettings')
                  }}
                  to={'/settings/personal'}
                >
                  <div className="icon-wrapper">
                    
                    <img src={settings} alt="icon" />
                  <span>Settings</span>
                  </div>
                  {/* <div className="hover-name-wrapper">
                    <i className="fas fa-caret-left"></i>
                    <p>{menu_item.hoverName}</p>
                  </div> 
                </Link> */}
            <CommonSubscriptionModal
              open={this.state.showSubscriptionModal}
              onClose={() => this.setState({showSubscriptionModal: false})}
              title="Choose Your Plan"
            />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { dialerStatus: state.dialer.currentStatus ,pendingLeads:state.settings.pendingLeads}
}

export default connect(mapStateToProps, { getDialer })(Navbar)


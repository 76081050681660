import React, { Component } from 'react'

import Title from '../common/Title'

import InnerNavigation from '../common/InnerNavigation'

import icon from '../assets/images/settingIcon.png'

const titleContent = {
  type: 'image',
  titleOne: icon,
  titleTwo: 'Number'
}

const settingsData = [
  {
    mainSidebar: 'Manage Number',
    innerTabs: ['Manage Number']
  }
]

class Numbers extends Component {
  componentDidMount = () => {
    document.title = 'Numbers'
  }
  render() {
    return (
      <div className="fullwidth-container number-container">
        <div className="numbers-banner-container manage_number_main">
          <div className="numbers-banner">
            <div className="banner-icon">
              <img src={icon} alt="icon" />
            </div>
            <div className="banner-description-wrapper">
              <p className="banner-description">
                Manage your Virtual phone numbers.
              </p>
            </div>
          </div>

          <InnerNavigation
            dataComponent={settingsData}
            activeTab={this.props.activeTab}
            activeComponent={this.props.activeComponent}
            dataTitle={titleContent}
          />
        </div>
      </div>
    )
  }
}

export default Numbers
